import { Disclosure } from '@headlessui/react'
import React, {useEffect} from 'react'
import { AiFillCaretDown } from 'react-icons/ai'
import { BsSearch } from 'react-icons/bs'
import LayoutWeb from '../../../layouts/Web'
import {Link} from 'react-router-dom'
function Faq() {
  useEffect(()=>{
    window.scrollTo(0, 0);

  }, [])
  return (
    <LayoutWeb>
        <div className="mt-28 w-full mx-auto lg:max-w-7xl">
            <p className="text-sm px-0 lg:px-10 my-6 ml-8 lg:ml-6 text-[#033050] font-semibold mb-11"><Link to="/" className='text-[#033050]'>Home</Link> / FAQ</p>
            <section className="flex flex-col">
                <h5 className="text-4xl font-extrabold text-center text-[#033050]">Frequently Asked Question</h5>
            </section>
            {/* disclosure */}
            <section className="mt-11 mx-auto w-full px-6 lg:px-0 lg:w-[37rem] space-y-4">
                <Disclosure>
                    {({ open }) => (
                      <>
                        <Disclosure.Button className="flex w-full justify-between rounded-lg bg-white shadow-md px-4 py-2 text-left text-lg font-extrabold focus:outline-none">
                          <span>Can i cancel subscription?</span>
                          <AiFillCaretDown
                            className={`${
                              open ? 'rotate-180 transform' : ''
                            } h-4 w-4 text-black`}
                          />
                        </Disclosure.Button>
                        <Disclosure.Panel className="px-4 pt-4 pb-2 text-sm text-gray-500 leading-loose bg-white shadow-md">
                            Yes. You may cancel your subscription anytime on the My Account page by going to your My Account. Follow the steps to cancel and confirm your cancellation. You will continue to have access to Nifty Educate for the time that has already been paid.
                        </Disclosure.Panel>
                      </>
                    )}
                </Disclosure>
                <Disclosure>
                    {({ open }) => (
                      <>
                        <Disclosure.Button className="flex w-full justify-between rounded-lg bg-white shadow-md px-4 py-2 text-left text-lg font-extrabold focus:outline-none">
                          <span>How to start my subscription?</span>
                          <AiFillCaretDown
                            className={`${
                              open ? 'rotate-180 transform' : ''
                            } h-4 w-4 text-black`}
                          />
                        </Disclosure.Button>
                        <Disclosure.Panel className="px-4 pt-4 pb-2 text-sm text-gray-500 leading-loose bg-white shadow-md">
                        To start a subscription, you can click the subscription menu, choose whether monthly or annual, and then follow the payment step. Once you start the subscription, you can access all of the education materials available on the platform.
                        </Disclosure.Panel>
                      </>
                    )}
                </Disclosure>
                <Disclosure>
                    {({ open }) => (
                      <>
                        <Disclosure.Button className="flex w-full justify-between rounded-lg bg-white shadow-md px-4 py-2 text-left text-lg font-extrabold focus:outline-none">
                          <span className='w-[80%]'>How to Earn from the Nifty Educate Platform?</span>
                          <AiFillCaretDown
                            className={`${
                              open ? 'rotate-180 transform' : ''
                            } h-4 w-4 text-black`}
                          />
                        </Disclosure.Button>
                        <Disclosure.Panel className="px-4 pt-4 pb-2 text-sm text-gray-500 leading-loose bg-white shadow-md">
                        To earn, you must actively download and learn with your kids, and unlocks material regularly to earn points, after receiving a certain number of points, you will unlock some of the levels which determine your earning. Collectibles, NFT art, or tokens will be rewarded. 
                        </Disclosure.Panel>
                      </>
                    )}
                </Disclosure>
                <Disclosure>
                    {({ open }) => (
                      <>
                        <Disclosure.Button className="flex w-full justify-between rounded-lg bg-white shadow-md px-4 py-2 text-left text-lg font-extrabold focus:outline-none">
                          <span>How to pay with Credit Card?</span>
                          <AiFillCaretDown
                            className={`${
                              open ? 'rotate-180 transform' : ''
                            } h-4 w-4 text-black`}
                          />
                        </Disclosure.Button>
                        <Disclosure.Panel className="px-4 pt-4 pb-2 text-sm text-gray-500 leading-loose bg-white shadow-md">
                        You can choose the Credit Card payment options and then follow the step of the credit card payment process. Once it succeeds, you can access all the educational material.
                        </Disclosure.Panel>
                      </>
                    )}
                </Disclosure>
                <Disclosure>
                    {({ open }) => (
                      <>
                        <Disclosure.Button className="flex w-full justify-between rounded-lg bg-white shadow-md px-4 py-2 text-left text-lg font-extrabold focus:outline-none">
                          <span className='w-[80%]'>What Package Should I use to teach my students?</span>
                          <AiFillCaretDown
                            className={`${
                              open ? 'rotate-180 transform' : ''
                            } h-4 w-4 text-black`}
                          />
                        </Disclosure.Button>
                        <Disclosure.Panel className="px-4 pt-4 pb-2 text-sm text-gray-500 leading-loose bg-white shadow-md">
                        For now, you can use a personal package (monthly or annually) while we develop the teacher package. Hope we can help the teacher to educate their student better.
                        </Disclosure.Panel>
                      </>
                    )}
                </Disclosure>
                <Disclosure>
                    {({ open }) => (
                      <>
                        <Disclosure.Button className="flex w-full justify-between rounded-lg bg-white shadow-md px-4 py-2 text-left text-lg font-extrabold focus:outline-none">
                          <span className='w-[80%]'>What Products does Nifty Educate Provide?</span>
                          <AiFillCaretDown
                            className={`${
                              open ? 'rotate-180 transform' : ''
                            } h-4 w-4 text-black`}
                          />
                        </Disclosure.Button>
                        <Disclosure.Panel className="px-4 pt-4 pb-2 text-sm text-gray-500 leading-loose bg-white shadow-md">
                        Nifty Educate provides material in printable form (PDF file) that can be downloaded and stored on storage media to be printed whenever needed. Printable made by Nifty Educate have various forms, including alphabet lapbooks, workbooks, math lapbooks, mother's journals, creative questions, flashcards, and worksheets.
                        </Disclosure.Panel>
                      </>
                    )}
                </Disclosure>
                <Disclosure>
                    {({ open }) => (
                      <>
                        <Disclosure.Button className="flex w-full justify-between rounded-lg bg-white shadow-md px-4 py-2 text-left text-lg font-extrabold focus:outline-none">
                          <span>How Can I contact Nifty Educate?</span>
                          <AiFillCaretDown
                            className={`${
                              open ? 'rotate-180 transform' : ''
                            } h-4 w-4 text-black`}
                          />
                        </Disclosure.Button>
                        <Disclosure.Panel className="px-4 pt-4 pb-2 text-sm text-gray-500 leading-loose bg-white shadow-md">
                        You can contact Nifty Educate by going to contact us page, or directly sending an email to hallo@niftyeducate.com
                        </Disclosure.Panel>
                      </>
                    )}
                </Disclosure>
            </section>
        </div>
    </LayoutWeb>
  )
}

export default Faq
