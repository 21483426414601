
import React from 'react'
import { useHistory,useLocation,Link } from 'react-router-dom'
import Api from '../../api';
import toast from "react-hot-toast";
import Cookies from "js-cookie";

export default function SideBar() {
    const location = useLocation();
    const { pathname } = location;
    const splitLocation = pathname.split("/");
    const token = Cookies.get("token");
    const history = useHistory();
    const logoutHandler = async (e) => {
        await Api.post('/logout', null, {
            headers: {
                Authorization: `Bearer ${token}`,
            }
        })
        .then(() => {
            Cookies.remove('token');
            Cookies.remove('status');
            toast.success("Logout Successfully.", {
                duration: 4000,
                position: "top-right",
                style: {
                    borderRadius: '10px',
                    background: '#333',
                    color: '#fff',
                },
            });
            history.push('/');
        });
    }
    
  return (
      <>
        <div className='hidden md:flex flex-row md:items-start md:flex-col justify-around md:justify-start mb-8 md:mb-0 md:gap-y-4'>
            <Link to="/my-account" className={splitLocation[1] === "my-account" ? "text-lg text-[#033050] Nunito font-extrabold" : "hover:text-[#033050] text-[#B0D4ED] text-lg md:text-lg font-extrabold"}>My Account</Link>
            <Link to="/my-downloads" className={splitLocation[1] === "my-downloads" ?  "text-lg text-[#033050] Nunito font-extrabold" : "hover:text-[#033050] text-[#B0D4ED] text-lg md:text-lg font-extrabold"}>My Downloads</Link>
            <Link to="/my-subscription" className={splitLocation[1] === "my-subscription" ? "text-lg text-[#033050] Nunito font-extrabold" : "hover:text-[#033050] text-[#B0D4ED] text-lg md:text-lg font-extrabold"}>My Subscription</Link>
            <Link to="/my-bookmark" className={splitLocation[1] === "my-bookmark" ?  "text-lg text-[#033050] Nunito font-extrabold" : "hover:text-[#033050] text-[#B0D4ED] text-lg md:text-lg font-extrabold"}>My Bookmark</Link>            
        </div>
        <div className='flex md:hidden flex-row justify-between mb-8 gap-x-4 w-full p-4 bg-white rounded-md mx-auto drop-shadow-md'>
            <Link to="/my-account" className={splitLocation[1] === "my-account" ? "text-base text-[#033050] Nunito font-extrabold" : "hover:text-[#033050] text-[#B0D4ED] text-base md:text-lg font-extrabold"}>My Account</Link>
            <Link to="/my-downloads" className={splitLocation[1] === "my-downloads" ? "text-base text-[#033050] Nunito font-extrabold" : "hover:text-[#033050] text-[#B0D4ED] text-base md:text-lg font-extrabold"}>My Downloads</Link>
            <Link to="/my-subscription" className={splitLocation[1] === "my-subscription" ? "text-base text-[#033050] Nunito font-extrabold" : "hover:text-[#033050] text-[#B0D4ED] text-base md:text-lg font-extrabold"}>My Subscription</Link>
            <Link to="/my-bookmark" className={splitLocation[1] === "my-bookmark" ?  "text-base text-[#033050] Nunito font-extrabold" : "hover:text-[#033050] text-[#B0D4ED] text-base md:text-lg font-extrabold"}>My Bookmark</Link>
        </div>
      </>
  )
   
}
