//import cookie
import Cookies from "js-cookie";

//import react router dom

import { Route ,Redirect} from "react-router-dom";

function VerifRoutes({ children, ...rest }) {
    
    
    const token = Cookies.get('token');
    const status = Cookies.get('status');

    return (
        <Route {...rest}>
                {token && status === "2" ? children : <Redirect to="/" />}
        </Route>
    )
    
  }

export default VerifRoutes;