import React,{useState,useEffect} from 'react'
import LayoutWeb from '../../../layouts/Web'
import SideBar from '../../../components/particles/SideBar'
import Api from '../../../api'
import Cookies from "js-cookie";
// import { AiFillEdit } from 'react-icons/ai'
import { VscTrash } from 'react-icons/vsc'
import {  MdSort } from 'react-icons/md'
import {  SkeletonBookmark } from '../../../components/utilities/Skeleton'
// import Filter from '../../../components/utilities/Filter'
import toast from "react-hot-toast";
import PaginationComponent from '../../../components/utilities/Pagination'
import {Link} from 'react-router-dom'
import { GiCutDiamond } from 'react-icons/gi';



export default function Bookmark() {
    const [currentPage, setCurrentPage] = useState(1);
    const [isError,setIsError]= useState(false);
    const [perPage, setPerPage] = useState(0);
    const [total, setTotal] = useState(0);
    const [bookmarks, setBookmark] = useState([]);
    const [sortby, setSortBy] = useState("");
    const [loading, setLoading] = useState(true);
    const token = Cookies.get("token");
    
    const listCategory = (category)=>{
        var txt = "";
        for (var x in category) {
          if (category[x] != null) {
            txt += category[x].category.name + ", ";
          }
        }
        var nCategory = txt.lastIndexOf(", ");
        return txt.substring(0, nCategory);
    }
    const listGrade = (grades)=>{

      var txt = "";
      for (var x in grades) {
        if (grades[x] != null) {
          txt += grades[x].grade.name + ", ";
        }
      }
      var nGrades = txt.lastIndexOf(", ");
      return txt.substring(0, nGrades);
  }
  
    const getBookmark = async (pageNumber, sortBy) => {
        const page = pageNumber ? pageNumber : currentPage;
        const SearchBy = sortby ? sortby : "";
        setLoading(true);
        await Api.get(`/bookmark?page=${page}&title=${SearchBy}`,{
            headers: {
                //header Bearer + Token
                Authorization: `Bearer ${token}`,
            }
        })
          .then((response) => {
            if(response.data.status === 0){

              setBookmark([]);
              setCurrentPage(1);
              setPerPage(0);
              setTotal(0);
              setIsError(true)
            }else{
              setBookmark(response.data.data.data);
              setCurrentPage(response.data.data.current_page);
              setPerPage(response.data.data.per_page);
              setTotal(response.data.data.total);
              window.scrollTo({
                top: 0,
                behavior: "smooth"
              });
            }
    
          }).catch((error) => {
            setIsError(true)
            setLoading(false);
          }).finally(() => {
            setLoading(false);
            setIsError(false)
          });

      }
      const remove = async (e) => { 
        await Api.post("/un-bookmark",{
            id:e
        },{
            headers: {
                //header Bearer + Token
                Authorization: `Bearer ${token}`,
            }
        })
        .then((response) => {
          toast.success("Bookmark Deleted.", {
            duration: 4000,
            position: "top-right",
            style: {
                borderRadius: '10px',
                background: '#333',
                color: '#fff',
            },
        });
          getBookmark()
        });
      }
      function handleOrderByChange(event) {
        getBookmark(event.target.value);
        setSortBy(event.target.value);
      }
    
      useEffect(() => {
        window.scrollTo(0, 0);
        getBookmark()
        // getGrades()
      },[])
      
  return (
    <>
      <LayoutWeb>
      <section className="max-w-7xl mx-auto">
        <p className="ml-12 md:ml-12 text-sm mt-28 mb-8 font-semibold text-[#033050]"><Link href="/" className='text-[#033050]'>Home</Link> / My Bookmark</p>
        <div className="px-10 lg:px-12 container mx-auto flex flex-col md:flex-row md:justify-between">
          <SideBar/>
          <section className='w-full md:w-3/4'>
          {loading ? (<SkeletonBookmark/>) :
              (<>
                <div className="flex md:flex-row items-center justify-between px-8 lg:px-0 mb-6">
                  <div className="flex items-center gap-x-2">
                    {bookmarks.length > 1 ? (<p className="text-sm font-normal">{bookmarks.length} lessons</p>): (<p className="text-sm font-normal">{bookmarks.length} lesson</p>)}
                  </div>
                  <div className="flex items-center gap-x-1 lg:gap-x-4">
                    <p className="text-sm font-normal"><span className='hidden lg:block'>Sort By</span><MdSort className='block w-8 lg:hidden'/></p>
                    <section className="flex items-center gap-x-2">
                        <select
                          name="sorted"
                          id=""
                          className="text-sm w-42 md:w-52 py-2 text-[#033050] font-semibold px-2 border border-gray-400 rounded-lg"
                          value={sortby}
                          onChange={handleOrderByChange}
                        >
                          <option value="" className="text-sm text-[#033050]">
                            Latest
                          </option>
                          <option value="asc" className="text-sm text-[#033050]">
                            Title A - Z
                          </option>
                          <option value="desc" className="text-sm">
                            Title Z- A
                          </option>
                        </select>
                      </section>
                  </div>
              </div>
              <div className="grid gap-x-6 grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-y-4 relative mb-10">
                  { bookmarks.length > 0 ?
                    (bookmarks.map((item) => 
                          <div key={item.lesson.id} className="bg-white show-library w-72 md:w-52 lg:w-56 xl:w-64 mx-auto md:mr-auto rounded-lg transform duration-200 ease-out hover:drop-shadow-lg shadow-md">
                              {item.lesson.thumbnail === null ? <img className='rounded-tl-lg w-80 rounded-tr-lg' src='/img/coklat.png' alt='' /> : <img className='rounded-tl-lg w-80 rounded-tr-lg' src={process.env.REACT_APP_IMG+'/storage/thumbnail/'+item.lesson.thumbnail} alt="" />}
                              <div className="p-4">
                                  <a href={`/library/${item.lesson.slug}`}>
                                  <h5 className="text-lg font-bold mb-[14px] text-[#033050]">{`${item.lesson.title.substring(0, 25)}...`}</h5>
                                  </a>
                                  <div className="flex justify-between items-center">
                                      <a href={`/library/${item.lesson.slug}`}>
                                          <section className="gap-y-2">
                                              <p className="text-sm w-36 font-normal truncate text-[#033050]">
                                              {listCategory(item.lesson.lessoncategory)}
                                              </p>
                                              <p className="text-sm w-40 font-normal truncate text-[#033050]">
                                              {listGrade(item.lesson.lessongrades)}
                                              </p>
                                            </section>                          
                                      </a>
                                      {
                                        item.lesson.free_lesson === 0?
                                        <div className='bg-[#005a90] p-1 rounded-full -mt-1.5 translate-x-4 md:translate-x-0'> 
                                            <GiCutDiamond  className='text-white w-5 h-5'/>
                                        </div>
                                        : <></>
                                      }
                                      <button onClick={()=>remove(item.id)}>
                                          <VscTrash className='w-6 h-6 text-red-500' />
                                      </button>
                                  </div>
                              </div>
                              <div className='hide-library h-40 w-full z-0 absolute -mt-[7rem] bg-white p-4'>
                                  <a href={`/library/${item.lesson.slug}`} className='text-lg cursor-pointer font-bold text-[#033050] truncate'>{`${item.lesson.title.substring(0, 25)}...`}</a>
                                  <p className='text-ellipsis overflow-hidden text-black'>{item.lesson.description.length > 100
						                      ? `${item.lesson.description.substring(0, 100)}...`
						                      : item.lesson.description}</p>
                              </div>
                          </div>
                          )) : 
                          (
                          <div className='absolute lg:left-[20vw] lg:top-8 left-2 flex flex-col items-center gap-2'>
                            <img src="/img/Nifty Logo.png" alt="" />
                            <p className='font-bold text-xl text-[#033050]'>Your bookmark list is empty</p>
                          </div>)}
              </div>
              <PaginationComponent
                      currentPage={currentPage}
                      perPage={perPage}
                      total={total}
                      onChange={(pageNumber) => getBookmark(pageNumber,sortby)}
                      position="center"
                    /></>)
          }</section>
        </div>
      </section>
      </LayoutWeb>
    </>
  )
}
