import React,{useState,useEffect} from 'react'
import { BsCheck2Square } from 'react-icons/bs'
import Api from '../../api';
import Cookies from "js-cookie";
import { BiLoaderAlt } from 'react-icons/bi';
import ModalVerify from './ModalVerify';
import {Link} from 'react-router-dom';

function CardSubscribe({onClick, onClickVerif}) {

  const [isLoading, setLoading] = useState(false);
  const token = Cookies.get("token");
  const userStatus = Cookies.get("status")
  const [plan, setPlan] = useState([]);
  const [status, setSatus] = useState([]);
  const getSubscrabe = async () => {
    if(!token || userStatus === "2"){
        await Api.get("/payments")
        .then((response) => {
          setPlan(response.data.data)
        });
    }else{
        await Api.get("/payments-auth",{
            headers: {
                Authorization: `Bearer ${token}`,
            }
        })
        .then((response) => {
          setPlan(response.data.data)
          setSatus(response.data)
        });
    }

  }

  useEffect(() => {
    window.scrollTo(0, 0);
    getSubscrabe()
  },[])

  const numberFormat = (value) =>
  new Intl.NumberFormat("id-ID", {
      style: "decimal",
  }).format(value);


  return (
    <>
        <div className='py-8 px-6 container w-5/6 rounded-tl-xl shadow-sm rounded-tr-xl mx-auto bg-[#d5f3ff]'>
            <h5 className='text-lg font-extrabold text-[#005A98] mt-4 mb-[34px] text-center'>Memberships give you full access to our library, features, and so much more</h5>
            {/* spacer */}
            <div className="flex flex-col lg:flex-row justify-between px-4 md:px-20">
                <div className="flex flex-col space-y-2">
                <section className="flex justify-start w-full items-center gap-x-2">
                    <BsCheck2Square className='w-3 h-3 text-[#005A98]'/>
                    <p className="text-sm font-normal text-[#005A98] mb-0">Full Features</p>
                </section>
                <section className="flex justify-start w-full items-center gap-x-2">
                    <BsCheck2Square className='w-3 h-3 text-[#005A98]'/>
                    <p className="text-sm font-normal text-[#005A98] mb-0">Complete material</p>
                </section>
                <section className="flex justify-start w-full items-center gap-x-2">
                    <BsCheck2Square className='w-3 h-3 text-[#005A98]'/>
                    <p className="text-sm font-normal text-[#005A98] mb-0">Newsletter Update</p>
                </section>
                <section className="flex justify-start w-full items-center gap-x-2">
                    <BsCheck2Square className='w-3 h-3 text-[#005A98]'/>
                    <p className="text-sm font-normal text-[#005A98] mb-0">Gathering online / offline</p>
                </section>
                </div>
                <div className="flex flex-col mt-2 lg:mt-0 space-y-2">
                    <section className="flex justify-start w-full items-center gap-x-2">
                        <BsCheck2Square className='w-3 h-3 text-[#005A98]'/>
                        <p className="text-sm font-normal text-[#005A98] mb-0">Newsletter tips</p>
                    </section>
                    <section className="flex justify-start w-full items-center gap-x-2">
                        <BsCheck2Square className='w-3 h-3 text-[#005A98]'/>
                        <p className="text-sm font-normal text-[#005A98] mb-0">Get reviews and comments</p>
                    </section>
                    <section className="flex justify-start w-full items-center gap-x-2">
                        <BsCheck2Square className='w-3 h-3 text-[#005A98]'/>
                        <p className="text-sm font-normal text-[#005A98] mb-0">Access discussion groups</p>
                    </section>
                    <section className="flex justify-start w-full items-center gap-x-2">
                        <BsCheck2Square className='w-3 h-3 text-[#005A98]'/>
                        <p className="text-sm font-normal text-[#005A98] mb-0">Latest curriculum</p>
                    </section>
                </div>
                <div className="flex flex-col mt-2 lg:mt-0 space-y-2">
                    <section className="flex justify-start w-full items-center gap-x-2">
                        <BsCheck2Square className='w-3 h-3 text-[#005A98]'/>
                        <p className="text-sm font-normal text-[#005A98] mb-0">Quality up to 4K 120fps</p>
                    </section>
                    <section className="flex justify-start w-full items-center gap-x-2">
                        <BsCheck2Square className='w-3 h-3 text-[#005A98]'/>
                        <p className="text-sm font-normal text-[#005A98] mb-0">Favorite teacher</p>
                    </section>
                    <section className="flex justify-start w-full items-center gap-x-2">
                        <BsCheck2Square className='w-3 h-3 text-[#005A98]'/>
                        <p className="text-sm font-normal text-[#005A98] mb-0">Interesting promotions</p>
                    </section>
                </div>
            </div>
        </div>
        <div className='mb-10 md:mb-0 p-8 w-5/6 container rounded-bl-xl text-center shadow-md rounded-br-xl mx-auto bg-white-200'>
            {token && userStatus == 2 ? 
            (
                <div className="flex flex-col md:flex-row gap-4 justify-center">
                {
                 plan.map((item) => 
                     <div key={item.id} className="p-6 w-full border-2 border-sky-500 rounded-xl h-40">
                         <section className='cursor-pointer mt-2'>
                             <h5 className="text-base mt-4 font-extrabold text-[#033050]">{item.description}</h5>
                             <h5 className="text-lg font-normal mb-3">IDR {numberFormat(item.amount)}</h5>
                         </section>
                         <div>
                         {
                             token ? 
                                <button onClick={onClickVerif} className='w-full transition lg:px-44 px-16 rounded-full py-2 text-sm font-semibold text-white bg-[#005A98] hover:bg-[#EC452D]' disabled={isLoading}> {isLoading ? "Loading..." : "Subscribe"}</button>
                             : <button onClick={onClick} className='px-4 w-full transition rounded-full py-2 text-sm font-semibold text-white bg-[#005A98] hover:bg-[#EC452D]'>Subscribe</button>
                         }
                         </div>
                     </div>
                 )
                }
             </div>
            ) : (            
            <div className="flex flex-col md:flex-row gap-4 justify-center">
            {
             plan.map((item) => 
                 <div key={item.id} className="p-6 w-full border-2 border-sky-500 rounded-xl h-40">
                     <section className='cursor-pointer mt-2'>
                         <h5 className="text-base mt-4 font-extrabold text-[#033050]">{item.description}</h5>
                         <h5 className="text-lg font-normal mb-3">IDR {numberFormat(item.amount)}</h5>
                     </section>
                     <div>
                     {
                         token ? 
                            <Link to={`/payment/${item.id}`} className='w-full transition lg:px-44 px-16 rounded-full py-2 text-sm font-semibold text-white bg-[#005A98] hover:bg-[#EC452D]' disabled={isLoading}> {isLoading ? "Loading..." : "Subscribe"}</Link>
                         : <button onClick={onClick} className='px-4 w-full transition rounded-full py-2 text-sm font-semibold text-white bg-[#005A98] hover:bg-[#EC452D]'>Subscribe</button>
                     }
                     </div>
                 </div>
             )
            }
         </div>)    
        
        }
            

        </div>
    </>
  )
}

export default CardSubscribe
