import React, { Suspense, useState, useEffect} from "react";
import LayoutWeb from "../../../layouts/Web";
import { Skeleton, SkeletonDrawer, SkeletonLibrary, SkeletonLibraryListSingle } from "../../../components/utilities/Skeleton";
import Api from "../../../api";
import Cookies from "js-cookie";
import PaginationComponent from "../../../components/utilities/Pagination";
import toast from "react-hot-toast";
import { MdOutlineDone } from "react-icons/md";
import { Disclosure } from "@headlessui/react";
import { AiFillCaretDown } from "react-icons/ai";
import { BsFilter } from "react-icons/bs";
import Box from "@mui/material/Box";
import SwipeableDrawer from "@mui/material/SwipeableDrawer";
import List from "@mui/material/List";
import { useHistory, useLocation } from "react-router-dom";
import { Link } from 'react-router-dom'
const CardLibrary = React.lazy(()=> import("../../../components/utilities/CardLibrary"));


export default function Index() {
  const userStatus = Cookies.get("status")
  const token = Cookies.get("token");
  const [currentPage, setCurrentPage] = useState(1);
  const [perPage, setPerPage] = useState(0);
  const [total, setTotal] = useState(0);
  const [library, setLibrary] = useState([]);
  const [isLoading, setLoading] = useState(true);
  const [isError, setIsError] = useState(false);
  const [isEmpty, setEmty] = useState(false);

  const [categories, setCategories] = useState([]);
  const [category, setCategory] = useState([]);

  //grade checkbox
  const [grades, setGrades] = useState([]);
  const [grade, setGrade] = useState([]);

  //topic checkbox
  const [topics, setTopics] = useState([]);
  const [topic, setTopic] = useState([]);

  //topic checkbox
  const [subjects, setSubjects] = useState([]);
  const [subject, setSubject] = useState([]);

  //premium checkbox
  const [premium, setPremium] = useState([]);

  //Filter
  const [sortby, setSortBy] = useState("");
  const [left, setLeft] = useState(false);

  const [drawerLoad, setDrawerLoad] = useState(true)

  const history = useHistory();
  const query = new URLSearchParams(useLocation().search);

  let [premiums] = useState([
    {
      id: 1,
      name: 'Premium',
      slug: '0',
      code: '0'
    },
    {
      id: 2,
      name: 'Free',
      slug: '1',
      code: '1'
    }
  ])
  const toggleDrawer = (anchor, open) => (event) => {
    if (
      event &&
      event.type === "keydown" &&
      (event.key === "Tab" || event.key === "Shift")
    ) {
      return;
    }

    setLeft({ [anchor]: open });
  };

  const getCategories = async () => {
    setDrawerLoad(true);
    try {
      let res = await Api.get("/categories");
      if (res) {
        setCategories(res.data.data);
        setDrawerLoad(false);
      }
    } catch (err) {
      setDrawerLoad(false);
    }
  };

  const getSubjects = async () => {
    setDrawerLoad(true);
    try {
      let res = await Api.get("/subject");
      if (res) {
        setSubjects(res.data.data);
        setDrawerLoad(false);
      }
    } catch (err) {
      setDrawerLoad(false);
    }
  };

  const getGrades = async () => {
    setDrawerLoad(true);
    try {
      let res = await Api.get("/grades");
      if (res) {
        setGrades(res.data.data);
        setDrawerLoad(false);
      }
    } catch (err) {
      setDrawerLoad(false);
    }
  };

  const getTopics = async () => {
    setDrawerLoad(true);
    try {
      let res = await Api.get("/topics");
      if (res) {
        setTopics(res.data.data);
        setDrawerLoad(false);
      }
    } catch (err) {
      setDrawerLoad(false);
    }
  };


  const getLibrary = async (
    pageNumber,
    slugPramCategory,
    slugPramGrade,
    slugParamTopic,
    sortby,
    slugParamSubject,
    slugParamPremium
  ) => {
    setLoading(true);

    const page = pageNumber ? pageNumber : currentPage;
    const slugCategory = slugPramCategory ? slugPramCategory : category;
    const slugGrade = slugPramGrade ? slugPramGrade : grade;
    const slugTopic = slugParamTopic ? slugParamTopic : topic;
    const slugSubject = slugParamSubject ? slugParamSubject : subject;
    const slugPremium = slugParamPremium ? slugParamPremium : premium;


    if (slugCategory) {
      var txt = "";
      for (var x in slugCategory) {
        if (slugCategory[x] != null) {
          txt += slugCategory[x] + ",";
        }
      }
      var nCategory = txt.lastIndexOf(",");
      var resultCategory = txt.substring(0, nCategory);
    }



    if (slugGrade) {
      var txtGrade = "";
      for (var x in slugGrade) {
        if (slugGrade[x] != null) {
          txtGrade += slugGrade[x] + ",";

        }
      }

      var nGrade = txtGrade.lastIndexOf(",");
      var resultGrade = txtGrade.substring(0, nGrade);

    }

    if (slugTopic) {
      var txtTopic = "";
      for (var y in slugTopic) {
        if (slugTopic[y] != null) {
          txtTopic += slugTopic[y] + ",";
        }
      }
      var nTopic = txtTopic.lastIndexOf(",");
      var resultTopic = txtTopic.substring(0, nTopic);
    }


    if (slugSubject) {
      var txtSubject = "";
      for (var y in slugSubject) {
        if (slugSubject[y] != null) {
          txtSubject += slugSubject[y] + ",";
        }
      }
      var nSubject = txtSubject.lastIndexOf(",");
      var resultSubject = txtSubject.substring(0, nSubject);
    }

    if (slugPremium) {
      var txtPremium = "";
      for (var y in slugPremium) {
        if (slugPremium[y] != null) {
          txtPremium += slugPremium[y] + ",";
        }
      }
      var nPremium = txtPremium.lastIndexOf(",");
      var resultPremium = txtPremium.substring(0, nPremium)
    }

    const SearchGrade = resultGrade ? resultGrade : "";
    const SearchTopic = resultTopic ? resultTopic : "";
    const SearchCategory = resultCategory ? resultCategory : "";
    const SearchBy = sortby ? sortby : "";
    const SearchSubject = resultSubject ? resultSubject : "";
    const SearchPremium = resultPremium ? resultPremium : "";

    const url = token ? 'lesson-auth' : 'lesson';
    await Api.get(`/${url}?page=${page}&category=${SearchCategory}&grade=${SearchGrade}&topics=${SearchTopic}&sort=${SearchBy}&subject=${SearchSubject}&lesson=${SearchPremium}`,
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    ).then((response) => {
      if (response.data.status === 0) {

        setLibrary([]);
        setCurrentPage(1);
        setPerPage(0);
        setTotal(0);
        setIsError(true)
      } else {
        setLibrary(response.data.data.data);
        setCurrentPage(response.data.data.current_page);
        setPerPage(response.data.data.per_page);
        setTotal(response.data.data.total);
      }

    }).catch((error) => {
      setIsError(true)
      setLoading(false);
    }).finally(() => {
      setLoading(false);
      setIsError(false)
    });
  };

  const createBookmark = async (e) => {
    await Api.post("/bookmark",
      {
        lesson_id: e,
      },
      {
        headers: {
          //header Bearer + Token
          Authorization: `Bearer ${token}`,
        },
      }
    ).then((response) => {
      toast.success("Save Bookmark Successfully.", {
        duration: 1000,
        position: "top-right",
        style: {
          borderRadius: "10px",
          background: "#333",
          color: "#fff",
        },
      });
      getLibrary();
    });
  };

  const removeBookmark = async (e) => {
    await Api.post(
      "/remove-bookmark",
      {
        lesson_id: e,
      },
      {
        headers: {
          //header Bearer + Token
          Authorization: `Bearer ${token}`,
        },
      }
    ).then((response) => {
      toast.success("Remove Bookmark Successfully.", {
        duration: 1000,
        position: "top-right",
        style: {
          borderRadius: "10px",
          background: "#333",
          color: "#fff",
        },
      });
      getLibrary();
    });
  };


  const onAddingCategory = (index, item) => (event) => {
    const newCategory = [...category];
    if (category[index] === item.slug) {
      newCategory[index] = null;
      setCategory(newCategory);
    } else {
      newCategory[index] = item.slug;
      setCategory(newCategory);
    }
    getLibrary(1, newCategory, grade, topic, sortby, subject, premium);
  };


  const onAddingGrade = (index, item) => (event) => {
    const newGrade = [...grade];
    if (grade[index] === item.slug) {
      newGrade[index] = null;
      setGrade(newGrade);
    } else {
      newGrade[index] = item.slug;
      setGrade(newGrade);
    }

    getLibrary(1, category, newGrade, topic, sortby, subject, premium);
  };

  const onAddingTopic = (index, item) => (event) => {
    const newTopic = [...topic];
    if (topic[index] === item.slug) {
      newTopic[index] = null;
      setTopic(newTopic);
    } else {
      newTopic[index] = item.slug;
      setTopic(newTopic);
    }
    getLibrary(1, category, grade, newTopic, sortby, subject, premium);
  };

  const onAddingSubject = (index, item) => (event) => {
    const newSubject = [...subject];
    if (subject[index] === item.slug) {
      newSubject[index] = null;
      setSubject(newSubject);
    } else {
      newSubject[index] = item.slug;
      setSubject(newSubject);
    }
    getLibrary(1, category, grade, topic, sortby, newSubject, premium);
  };

  const onAddingPremium = (index, item) => (event) => {
    const newPremium = [...premium];
    if (premium[index] === item.slug) {
      newPremium[index] = null;
      setPremium(newPremium);
    } else {
      newPremium[index] = item.slug;
      setPremium(newPremium);
    }
    getLibrary(1, category, grade, topic, sortby, subject, newPremium);
  }

  function handleOrderByChange(event) {
    getLibrary(1, category, grade, topic, event.target.value, subject);
    setSortBy(event.target.value);
  }

  const list = (anchor) => (
    <Box
      className="w-56"
      role="presentation"
      onClick={toggleDrawer(anchor, true)}
      onKeyDown={toggleDrawer(anchor, false)}
    >
      <List className="flex flex-col items-start gap-y-2">
        {categories.map((item, i) => (
          <div className="flex w-52 flex-col pb-2 space-y-2 translate-y-2 ml-5">
            <section className="flex items-center gap-x-1">
              {category[i] === item.slug ? (
                <div className="bg-green-500 p-[.15rem] rounded-full mr-1">
                  <MdOutlineDone className="text-white w-3 h-3" />
                </div>
              ) : null}
              <label className="mb-0 cursor-pointer">
                <input
                  className="hidden"
                  key={item.id}
                  type="checkbox"
                  name="category"
                  value={item.name}
                  checked={category[i]}
                  onChange={onAddingCategory(i, item)}
                />
                <span className="text-lg text-[#033050] font-extrabold Nunito">
                  {item.name}
                </span>
              </label>
            </section>
          </div>
        ))}

        <section className="flex flex-col gap-y-4 translate-y-2">
          <Disclosure>
            {({ open }) => (
              <>
                <Disclosure.Button className="flex justify-between ml-5 items-center text-sm font-medium text-left">
                  <span className="text-lg font-bold text-[#033050] mr-28">
                    Grade
                  </span>
                  <AiFillCaretDown
                    className={`${open ? "transform rotate-180" : ""
                      } w-3 h-3 text-black`}
                  />
                </Disclosure.Button>
                <Disclosure.Panel className="text-sm space-y-1 text-black ml-5">
                  {grades.map((item, i) => (
                    <section className="flex items-center gap-x-2">
                      <input
                        type="checkbox"
                        value={item.name}
                        checked={grade[i]}
                        onChange={onAddingGrade(i, item)}
                      />
                      <label
                        htmlFor="workspace"
                        className="mb-0 text-sm font-normal"
                      >
                        {item.name}
                      </label>
                    </section>
                  ))}
                </Disclosure.Panel>
              </>
            )}
          </Disclosure>
          <Disclosure>
            {({ open }) => (
              <>
                <Disclosure.Button className="flex justify-between ml-5 items-center text-sm font-medium text-left">
                  <span className="text-lg font-bold text-[#033050] mr-28">
                    Subjects
                  </span>
                  <AiFillCaretDown
                    className={`${open ? "transform rotate-180" : ""
                      } w-3 h-3 text-black`}
                  />
                </Disclosure.Button>
                <Disclosure.Panel className="text-sm space-y-1 text-black ml-5">
                  {subjects.map((item, i) => (
                    <section className="flex items-center gap-x-2">
                      <input
                        type="checkbox"
                        value={item.name}
                        checked={subject[i]}
                        onChange={onAddingSubject(i, item)}
                      />
                      <label
                        htmlFor="workspace"
                        className="mb-0 text-sm font-normal"
                      >
                        {item.name}
                      </label>
                    </section>
                  ))}
                </Disclosure.Panel>
              </>
            )}
          </Disclosure>
          <Disclosure>
            {({ open }) => (
              <>
                <Disclosure.Button className="flex justify-between ml-5 items-center text-sm font-medium">
                  <span className="text-lg font-bold text-[#033050]">
                    Themes
                  </span>
                  <AiFillCaretDown
                    className={`${open ? "transform rotate-180" : ""
                      } w-3 h-3 text-black`}
                  />
                </Disclosure.Button>
                <Disclosure.Panel className="text-sm space-y-1 text-black ml-5">
                  {topics.map((item, i) => (
                    <section className="flex items-center gap-x-2">
                      <input
                        type="checkbox"
                        value={item.name}
                        checked={topic[i]}
                        onChange={onAddingTopic(i, item)}
                      />
                      <label
                        htmlFor="workspace"
                        className="mb-0 text-sm font-normal"
                      >
                        {item.name}
                      </label>
                    </section>
                  ))}
                </Disclosure.Panel>
              </>
            )}
          </Disclosure>
          <Disclosure>
            {({ open }) => (
              <>
                <Disclosure.Button className="flex justify-between ml-5 items-center text-sm font-medium">
                  <span className="text-lg font-bold text-[#033050]">
                    Types
                  </span>
                  <AiFillCaretDown
                    className={`${open ? "transform rotate-180" : ""
                      } w-3 h-3 text-black`}
                  />
                </Disclosure.Button>
                <Disclosure.Panel className="text-sm space-y-1 text-black ml-5">
                  {premiums.map((item, i) => (
                    <section className="flex items-center gap-x-2">
                      <input
                        type="checkbox"
                        value={item.name}
                        checked={premium[i]}
                        onChange={onAddingPremium(i, item)}
                      />
                      <label
                        htmlFor="workspace"
                        className="mb-0 text-sm font-normal"
                      >
                        {item.name}
                      </label>
                    </section>
                  ))}
                </Disclosure.Panel>
              </>
            )}
          </Disclosure>
        </section>
      </List>
    </Box>
  );

  const onChangePage = (pageNumber) => {
    getLibrary(pageNumber, category, grade, topic, sortby, subject, premium);
    window.scrollTo({
      top: 0,
      behavior: "smooth"
    });
  }


  useEffect(() => {
    getLibrary();
    getCategories();
    getGrades();
    getTopics();
    getSubjects();
  }, []);

  return (
    <div>
      <LayoutWeb>
        <section className="mt-28 w-full mx-auto lg:max-w-7xl px-10 md:px-20">
          <p className="text-sm text-[#033050] font-semibold">
            <Link to="/" className="text-[#033050]" >Home</Link> / Library
          </p>
          <section className="text-center mt-8 md:mt-6 mb-16">
            <h5 className="text-3xl md:text-4xl font-bold mb-3 text-[#033050]">
              Find Your Favorite Lesson
            </h5>
            <p className="text-base md:text-lg font-bold text-[#033050]">
              10.000+ worksheets & printables are available here!
            </p>
          </section>
          <div className="flex flex-col md:flex-row justify-between">
            {drawerLoad ? (<SkeletonDrawer />) :
              (
                <section className="hidden md:block">
                  {categories.map((item, i) => (

                    <div className="flex w-52 flex-col pb-2 space-y-2">
                      <section className="flex items-center gap-x-1">
                        {category[i] === item.slug ? (
                          <div className="bg-green-500 p-[.15rem] rounded-full mr-1">
                            <MdOutlineDone className="text-white w-3 h-3" />
                          </div>
                        ) : null}
                        <label className="mb-0 cursor-pointer">
                          <input
                            className="hidden"
                            key={item.id}
                            type="checkbox"
                            value={item.name}
                            checked={category[i]}
                            onChange={onAddingCategory(i, item)}
                          />
                          <span className="text-lg text-[#033050] font-extrabold Nunito">
                            {item.name}
                          </span>
                        </label>
                      </section>
                    </div>
                  ))}
                  <hr className="border-[#005a98] w-[12rem]" />
                  <div className="flex flex-col pt-2">
                    <div className="w-32 space-y-2">
                      <Disclosure>
                        {({ open }) => (
                          <>
                            <Disclosure.Button className="flex justify-between items-center w-20 md:w-[12rem] text-sm font-medium text-left">
                              <span className="text-lg font-bold text-[#033050]">
                                Grade
                              </span>
                              <AiFillCaretDown
                                className={`${open ? "transform rotate-180" : ""
                                  } w-3 h-3 text-black`}
                              />
                            </Disclosure.Button>
                            <Disclosure.Panel className="text-sm mt-2 space-y-1 text-black">
                              {grades.map((item, i) => (
                                <section className="flex items-center gap-x-2">
                                  <input
                                    type="checkbox"
                                    value={item.name}
                                    checked={grade[i]}
                                    onChange={onAddingGrade(i, item)}
                                  />
                                  <label
                                    htmlFor="workspace"
                                    className="mb-0 text-sm font-normal"
                                  >
                                    {item.name}
                                  </label>
                                </section>
                              ))}
                            </Disclosure.Panel>
                          </>
                        )}
                      </Disclosure>
                      <hr className="border-[#005a98] w-[12rem]" />
                      <Disclosure>
                        {({ open }) => (
                          <>
                            <Disclosure.Button className="flex justify-between items-center w-20 md:w-[12rem] text-sm font-medium text-left">
                              <span className="text-lg font-bold text-[#033050]">
                                Subjects
                              </span>
                              <AiFillCaretDown
                                className={`${open ? "transform rotate-180" : ""
                                  } w-3 h-3 text-black`}
                              />
                            </Disclosure.Button>
                            <Disclosure.Panel className="text-sm mt-2 space-y-1 text-black">
                              {subjects.map((item, i) => (
                                <section className="flex items-center gap-x-2">
                                  <input
                                    type="checkbox"
                                    value={item.name}
                                    checked={subject[i]}
                                    onChange={onAddingSubject(i, item)}
                                  />
                                  <label
                                    htmlFor="workspace"
                                    className="mb-0 text-sm font-normal"
                                  >
                                    {item.name}
                                  </label>
                                </section>
                              ))}
                            </Disclosure.Panel>
                          </>
                        )}
                      </Disclosure>
                      <hr className="border-[#005a98] w-[12rem]" />
                      <Disclosure>
                        {({ open }) => (
                          <>
                            <Disclosure.Button className="flex justify-between items-center w-20 md:w-[12rem] text-sm font-medium text-left">
                              <span className="text-lg font-bold text-[#033050]">
                                Themes
                              </span>
                              <AiFillCaretDown
                                className={`${open ? "transform rotate-180" : ""
                                  } w-3 h-3 text-black`}
                              />
                            </Disclosure.Button>
                            <Disclosure.Panel className="text-sm mt-2 space-y-1 text-black">
                              {topics.map((item, i) => (
                                <section className="flex items-center gap-x-2">
                                  <input
                                    type="checkbox"
                                    value={item.name}
                                    checked={topic[i]}
                                    onChange={onAddingTopic(i, item)}
                                  />
                                  <label
                                    htmlFor="workspace"
                                    className="mb-0 text-sm font-normal"
                                  >
                                    {item.name}
                                  </label>
                                </section>
                              ))}
                            </Disclosure.Panel>
                          </>
                        )}
                      </Disclosure>
                      <hr className="border-[#005a98] w-[12rem]" />
                      <Disclosure>
                        {({ open }) => (
                          <>
                            <Disclosure.Button className="flex justify-between items-center w-20 md:w-[12rem] text-sm font-medium text-left">
                              <span className="text-lg font-bold text-[#033050]">
                                Type
                              </span>
                              <AiFillCaretDown
                                className={`${open ? "transform rotate-180" : ""
                                  } w-3 h-3 text-black`}
                              />
                            </Disclosure.Button>
                            <Disclosure.Panel className="text-sm mt-2 space-y-1 text-black">
                              {premiums.map((item, i) => (
                                <section className="flex items-center gap-x-2">
                                  <input
                                    type="checkbox"
                                    value={item.name}
                                    checked={premium[i]}
                                    onChange={onAddingPremium(i, item)}
                                  />
                                  <label
                                    htmlFor="workspace"
                                    className="mb-0 text-sm font-normal"
                                  >
                                    {item.name}
                                  </label>
                                </section>))}
                            </Disclosure.Panel>

                          </>
                        )}
                      </Disclosure>
                    </div>
                  </div>
                </section>
              )
            }

            <section>
              <div className="flex flex-col">
                <section className="flex w-full px-2 items-center relative justify-between mb-6">
                <section className="block md:invisible">
              {["left"].map((anchor) => (
                <div key={anchor}>
                  <button
                    onClick={toggleDrawer(anchor, true)}
                    className="flex flex-row items-center p-2 text-[#033050] ml-2 border bg-gray-200 border-gray-400 rounded-lg gap-x-1"
                  >
                    <BsFilter className="w-4 h-4 text-[#033050]" />
                    <p>Filter</p>
                  </button>
                  <SwipeableDrawer
                    anchor={anchor}
                    open={left[anchor]}
                    onClose={toggleDrawer(anchor, false)}
                    onOpen={toggleDrawer(anchor, true)}
                  >
                    {list(anchor)}
                  </SwipeableDrawer>
                </div>
              ))}
            </section>
                  <section className="flex items-center gap-x-2">
                    <p className="text-sm text-[#033050]">Sort by</p>
                    <select
                      name="sorted"
                      id=""
                      className="text-sm w-42 md:w-52 py-2 text-[#033050] font-semibold px-2 border border-gray-400 rounded-lg"
                      value={sortby}
                      onChange={handleOrderByChange}
                    >
                      <option value="" className="text-sm text-[#033050]">
                        Newest
                      </option>
                      <option value="asc" className="text-sm text-[#033050]">
                        Title A - Z
                      </option>
                      <option value="desc" className="text-sm">
                        Title Z- A
                      </option>
                      {/* <option value="popular" className="text-sm">
                        Popular
                      </option> */}
                    </select>
                  </section>
                </section>
                { isLoading ? (<Skeleton/>) :
                 ( <>
                    {
                      library.length !== 0 ?
                        <>
                          <div className="relative grid grid-cols-1 sm:grid-cols-2 xl:grid-cols-3 gap-4" disabled={isLoading}>

                            {
                              library.map((item) => (
                                <Suspense fallback={<SkeletonLibraryListSingle/>}>
                                <CardLibrary
                                  id={item.id}
                                  slug={item.slug}
                                  thumbnail={item.thumbnail}
                                  title={item.title}
                                  category={item.lessoncategory}
                                  grades={item.lessongrades}
                                  mark={item.mark}
                                  token={token}
                                  createBookmark={createBookmark}
                                  removeBookmark={removeBookmark}
                                  free_lesson={item.free_lesson}
                                  userStatus={userStatus}
                                  description={item.description}
                                />
                                </Suspense>
                              ))
                            }
                          </div>
                          <PaginationComponent
                            currentPage={currentPage}
                            perPage={perPage}
                            total={total}
                            onChange={onChangePage}
                            position="center"
                          />
                        </>

                        : (<section hidden={isLoading} className="relative gap-y-4 my-20">
                          <div className='absolute lg:right-[30vw] w-full lg:top-8 flex flex-col items-center gap-2'>
                            <img src="/img/Nifty Logo.png" alt="" />
                            <p className='font-bold text-xl text-[#033050]'>Library list is empty</p>
                          </div>
                        </section>)
                    }
                  </>)
                }
              </div>
            </section>
          </div>
        </section>
      </LayoutWeb>
    </div>
  );
}
