import React, {useEffect, useState} from 'react';
import LayoutWeb from '../../../layouts/Web';
import Api from "../../../api";
import { SkeletonBlog } from '../../../components/utilities/Skeleton';
import CardBlog from "../../../components/utilities/CardBlog";
import {Link} from 'react-router-dom'
function Blog (){
  const [isLoading, setLoading] = useState(false);
  const [isError,setIsError]= useState(false);
  const [blogs, setBlogs] = useState([])
  const [currentPage, setCurrentPage] = useState(1);
  const [perPage, setPerPage] = useState(0);
  const [total, setTotal] = useState(0);

const getBlogs = async (
  pageNumber,
  sortby,

) => {
  setLoading(true);

  const page = pageNumber ? pageNumber : currentPage;



  try {
  let res = await Api.get(`/blog`);
  if (res){
    setBlogs(res.data.data.data)
    setLoading(false)
  }
}catch (err){
  alert('error')
  setLoading(false)
}}

useEffect(()=>{
  getBlogs()
},[])

  return(
    <LayoutWeb>
      { isLoading ? (<SkeletonBlog/>) :
      (<section className='w-full p-8 py-20'>
        <p className="text-sm px-0 lg:px-10 my-6 text-[#033050] font-semibold">
            <Link to="/" className='text-[#033050]'>Home</Link> / Blogs
        </p>

      <div className="relative grid grid-cols-1 sm:grid-cols-2 xl:grid-cols-3 gap-1" disabled={isLoading}>
      {
        blogs.map((item)=>(
          <CardBlog
          id={item.id}
          slug={item.slug}
          key={item.id}
          title={item.title}
          images={item.images}
          created_at={item.created_at}
          body={item.body}
          />
        ))
      }
      </div>
      </section>)}
    </LayoutWeb>
    )
}

export default Blog
