import React, {useState} from 'react'
import {Transition} from "@headlessui/react"
import { AiOutlineClose } from "react-icons/ai";
import { Link} from 'react-router-dom';
function ModalVerify({functionModalVerif, functionCodeVerif,show_verif, code_verif}) {
  return (
    <div>
        <Transition className="fixed h-screen bg-black/40 inset-x-0 top-0 z-50 mx-auto bg-scroll" show={show_verif} enter="transition-opacity duration-75" enterFrom="opacity-0" enterTo="opacity-100" leave="transition-opacity duration-150" leaveFrom="opacity-100" leaveTo="opacity-0">
          <div className="flex flex-col bg-white drop-shadow-lg lg:w-[32rem] mx-auto w-72 mt-28 p-6 rounded-xl shadow-md">
                <button onClick={()=> functionModalVerif(false)} className="rounded-lg ml-auto text-black text-center w-4 h-4"><AiOutlineClose/></button>
                <div className="flex flex-col lg:flex-row items-center">
                <img className="mx-auto w-60" src="/img/Worksheets.png" alt="" />
                <section>
                    <h5 className="text-lg font-semibold">Verification Needed</h5>
                    <p className="mt-1 text-sm">You have to be verified first to bookmark lessons.</p>
                    <Link to='/verify' className="float-right mt-5 rounded-full bg-[#005A98] hover:bg-orange-500 transform transition hover:shadow-orange-500 hover:shadow-sm text-white text-center px-3 py-2 w-full text-sm font-bold">Verify Now</Link>
                </section>
                </div>
            </div>
        </Transition>
      </div>
  )
}

export default ModalVerify