import React, { useState } from 'react'
import { FiInstagram } from 'react-icons/fi'
import { FaTiktok, FaDiscord, FaTelegramPlane } from 'react-icons/fa'
import { BsTwitter } from 'react-icons/bs'
import ModalAuth from '../utilities/ModalAuth'
import Cookies from 'js-cookie'
import { useLocation, Link } from 'react-router-dom'

function Footer() {
    const token = Cookies.get("token")
    const location = useLocation();
    const { pathname } = location;
    const splitLocation = pathname.split("/");
    const [show_login, setShowLogin] = useState(false);
    const [show_register, setShowRegister] = useState(false);
    const [show_forgot, setShowForgot] = useState(false);
    const [show_auth, setShowAuth] = useState(false);
    const [code_auth, setCodeAuth] = useState("1");
    const [isOpen, setIsOpen] = useState(false);

    const showModalLogin = async (status) => {
        setShowLogin(status)
    }
    const showModalRegister = async (status) => {
        setShowRegister(status)
    }

    const showModalForgot = async (status) => {
        setShowForgot(status)
    }

    const functionModalAuth = async (status) => {
        setShowAuth(status)
    }

    const functionCodeAuth = async (status) => {
        setCodeAuth(status)

    }



    return (
        <div>
            <ModalAuth functionModalAuth={functionModalAuth} functionCodeAuth={functionCodeAuth} show_auth={show_auth} code_auth={code_auth} />
            <div className='md:grid hidden md:grid-cols-4 lg:grid-cols-5 w-full gap-x-3 gap-y-4 lg:gap-y-0 relative px-20'>
                <section className="flex flex-col lg:w-56">
                    <img className='mb-6 w-[87px] h-[40px]' src="/img/Nifty Logo.png" alt="" />
                    <p className='text-sm font-semibold text-[#033050]'>Jl. Jangli Perbalan Tengah III No.21, Ngesrep, Kec. Banyumanik, Kota Semarang, Jawa Tengah 50261. Indonesia</p>
                    {/* <p className="text-base mt-4 text-left font-extrabold text-[#033050]">Follow us</p> */}
                    <ul className=' flex flex-row pl-0 gap-y-1 gap-x-2 mt-4'>
                        <li>
                            <a href="https://www.instagram.com/niftyeducate" target="_blank" className='text-sm font-semibold'>
                                <div className="p-2 rounded-lg bg-[#005A98] hover:bg-[#EC452D]"><FiInstagram className='text-xl text-white' /></div>
                            </a>
                        </li>
                        <li>
                            <a href="https://www.tiktok.com/@niftyeducate.com" target="_blank" className='text-sm font-semibold'>
                                <div className="p-2 rounded-lg bg-[#005A98] hover:bg-[#EC452D]"><FaTiktok className='text-xl text-white' /></div>
                            </a>
                        </li>
                        <li>
                            <a href="https://twitter.com/niftyeducatecom" target="_blank" className='text-sm font-semibold'>
                                <div className="p-2 rounded-lg bg-[#005A98] hover:bg-[#EC452D]"><BsTwitter className='text-xl text-white' /></div>
                            </a>
                        </li>
                        <li>
                            <a href="https://discord.gg/eTrwJyRa" target="_blank" className='text-sm font-semibold'>
                                <div className="p-2 rounded-lg bg-[#005A98] hover:bg-[#EC452D]"><FaDiscord className='text-xl text-white' /></div>
                            </a>
                        </li>
                        <li>
                            <a href="https://t.me/niftyeducate" target="_blank" className='text-sm font-semibold'>
                                <div className="p-2 rounded-lg bg-[#005A98] hover:bg-[#EC452D]"><FaTelegramPlane className='text-xl text-white' /></div>
                            </a>
                        </li>
                    </ul>
                </section>
                <section className='lg:mx-auto'>
                    <p className="text-base text-left font-extrabold text-[#033050]">Information</p>
                    <ul className=' flex flex-col pl-0 gap-y-1 mt-2'>
                        <li><Link style={{ color: splitLocation[1] === "@" ? "#EC452D" : "black" }} to='/' className={splitLocation[1] === "" ? "text-sm text-[#EC452D] Nunito font-medium" : "hover:text-[#EC452D] font-medium Nunito text-sm"}>Leaderboard</Link></li>
                        <li><Link style={{ color: splitLocation[1] === "contact-us" ? "#EC452D" : "black" }} to='/contact-us' className={splitLocation[1] === "" ? "text-sm text-[#EC452D] Nunito font-medium" : "hover:text-[#EC452D] font-medium Nunito text-sm"}>Contact us</Link></li>
                        <li><Link style={{ color: splitLocation[1] === "how-it-work" ? "#EC452D" : "black" }} to='/how-it-work' className={splitLocation[1] === "" ? "text-sm text-[#EC452D] Nunito font-medium" : "hover:text-[#EC452D] font-medium Nunito text-sm"}>How it works</Link></li>
                        <li><Link style={{ color: splitLocation[1] === "blogs" ? "#EC452D" : "black" }} to='/blogs' className={splitLocation[1] === "" ? "text-sm text-[#EC452D] Nunito font-medium" : "hover:text-[#EC452D] font-medium Nunito text-sm"}>Blogs</Link></li>
                        <li><Link style={{ color: splitLocation[1] === "faq" ? "#EC452D" : "black" }} to='/faq' className={splitLocation[1] === "" ? "text-sm text-[#EC452D] Nunito font-medium" : "hover:text-[#EC452D] font-medium Nunito text-sm"}>FAQ</Link></li>
                    </ul>
                </section>
                <section className='lg:mx-auto'>
                    <p className="text-base text-left font-extrabold text-[#033050]">About</p>
                    <ul className=' flex flex-col pl-0 gap-y-1 mt-2'>
                        <li><Link style={{ color: splitLocation[1] === "about" ? "#EC452D" : "black" }} to='/about' className={splitLocation[1] === "" ? "text-sm text-[#EC452D] Nunito font-medium" : "hover:text-[#EC452D] font-medium Nunito text-sm"}>About us</Link></li>
                        <li><Link style={{ color: splitLocation[1] === "terms-and-conditions" ? "#EC452D" : "black" }} to='/terms-and-conditions' className={splitLocation[1] === "" ? "text-sm text-[#EC452D] Nunito font-medium" : "hover:text-[#EC452D] font-medium Nunito text-sm"}>Terms of Use</Link></li>
                        <li><Link style={{ color: splitLocation[1] === "privacy-policy" ? "#EC452D" : "black" }} to='/privacy-policy' className={splitLocation[1] === "" ? "text-sm text-[#EC452D] Nunito font-medium" : "hover:text-[#EC452D] font-medium Nunito text-sm"}>Privacy Policy</Link></li>
                    </ul>
                </section>
                <section className='lg:mx-auto'>
                    <p className="text-base text-left font-extrabold text-[#033050]">Account</p>
                    <>
                        {token ?
                            (
                                <ul className=' flex flex-col pl-0 gap-y-1 mt-2'>
                                    <li><a style={{ color: splitLocation[1] === "my-account" ? "#EC452D" : "black" }} href='/my-account' className={splitLocation[1] === "" ? "text-sm text-[#EC452D] Nunito font-medium" : "hover:text-[#EC452D] font-medium Nunito text-sm"}>My Account</a></li>
                                    <li><a style={{ color: splitLocation[1] === "my-bookmark" ? "#EC452D" : "black" }} href='/my-bookmark' className={splitLocation[1] === "" ? "text-sm text-[#EC452D] Nunito font-medium" : "hover:text-[#EC452D] font-medium Nunito text-sm"}>My Bookmark</a></li>
                                    <li><a style={{ color: splitLocation[1] === "my-subscription" ? "#EC452D" : "black" }} href='/my-subscription' className={splitLocation[1] === "" ? "text-sm text-[#EC452D] Nunito font-medium" : "hover:text-[#EC452D] font-medium Nunito text-sm"}>My Subscription</a></li>
                                </ul>
                            )
                            :
                            (
                                <ul className=' flex flex-col pl-0 gap-y-1 mt-2'>
                                    <li><button onClick={() => functionModalAuth(true) && setCodeAuth("1")} style={{ color: splitLocation[1] === "my-account" ? "#EC452D" : "black" }} href='/my-account' className={splitLocation[1] === "" ? "text-sm text-[#EC452D] Nunito font-medium" : "hover:text-[#EC452D] font-medium Nunito text-sm"}>My Account</button></li>
                                    <li><button onClick={() => functionModalAuth(true) && setCodeAuth("1")} style={{ color: splitLocation[1] === "my-bookmark" ? "#EC452D" : "black" }} href='/my-bookmark' className={splitLocation[1] === "" ? "text-sm text-[#EC452D] Nunito font-medium" : "hover:text-[#EC452D] font-medium Nunito text-sm"}>My Bookmark</button></li>
                                    <li><button onClick={() => functionModalAuth(true) && setCodeAuth("1")} style={{ color: splitLocation[1] === "my-subscription" ? "#EC452D" : "black" }} href='/my-subscription' className={splitLocation[1] === "" ? "text-sm text-[#EC452D] Nunito font-medium" : "hover:text-[#EC452D] font-medium Nunito text-sm"}>My Subscription</button></li>
                                </ul>
                            )
                        }
                    </>
                </section>

                <section className='lg:mx-auto md:hidden lg:block'>
                    <p className="text-base text-left font-extrabold text-[#033050]">Payments</p>
                    <div className='mt-2 grid lg:grid-cols-6 gap-3 items-center'>
                        <img src='/img/paymenticon/QRIS.png' height={75} width={100} alt='' />
                        <img src='/img/paymenticon/ShopeePay.png' height={75} width={100} alt='' />
                        <img src='/img/paymenticon/visa.png' height={75} width={100} alt='' />
                        <img src='/img/paymenticon/permatabank.png' height={75} width={100} alt='' />
                        <img src='/img/paymenticon/OVO.png' height={75} width={100} alt='' />
                        <img src='/img/paymenticon/MasterCard.png' height={75} width={100} alt='' />
                        <img src='/img/paymenticon/mandiri.png' height={75} width={100} alt='' />
                        <img src='/img/paymenticon/linkaja.png' height={75} width={100} alt='' />
                        <img src='/img/paymenticon/JCB.png' height={75} width={100} alt='' />
                        <img src='/img/paymenticon/dana.png' height={75} width={100} alt='' />
                        <img src='/img/paymenticon/BSI.PNG' height={75} width={100} alt='' />
                        <img src='/img/paymenticon/BRI.png' height={75} width={100} alt='' />
                        <img src='/img/paymenticon/BNI.png' height={75} width={100} alt='' />
                        <img src='/img/paymenticon/BJB.png' height={75} width={100} alt='' />
                        <img src='/img/paymenticon/BCA.png' height={75} width={100} alt='' />
                        <img src='/img/paymenticon/akulaku.png' height={75} width={100} alt='' />
                    </div>
                </section>
            </div>
            <section className='lg:hidden hidden md:block w-full px-20 mt-4'>
                    <p className="text-base text-left font-extrabold text-[#033050]">Payments</p>
                    <div className='grid grid-cols-8 gap-1 items-center w-3/4'>
                        <img src='/img/paymenticon/QRIS.png' height={25} width={50} alt='' />
                        <img src='/img/paymenticon/ShopeePay.png' height={25} width={50} alt='' />
                        <img src='/img/paymenticon/visa.png' height={25} width={50} alt='' />
                        <img src='/img/paymenticon/permatabank.png' height={25} width={50} alt='' />
                        <img src='/img/paymenticon/OVO.png' height={25} width={50} alt='' />
                        <img src='/img/paymenticon/MasterCard.png' height={25} width={50} alt='' />
                        <img src='/img/paymenticon/mandiri.png' height={25} width={50} alt='' />
                        <img src='/img/paymenticon/linkaja.png' height={25} width={50} alt='' />
                        <img src='/img/paymenticon/JCB.png' height={25} width={50} alt='' />
                        <img src='/img/paymenticon/dana.png' height={25} width={50} alt='' />
                        <img src='/img/paymenticon/BSI.PNG' height={25} width={50} alt='' />
                        <img src='/img/paymenticon/BRI.png' height={25} width={50} alt='' />
                        <img src='/img/paymenticon/BNI.png' height={25} width={50} alt='' />
                        <img src='/img/paymenticon/BJB.png' height={25} width={50} alt='' />
                        <img src='/img/paymenticon/BCA.png' height={25} width={50} alt='' />
                        <img src='/img/paymenticon/akulaku.png' height={25} width={50} alt='' />
                    </div>
                </section>
            <div className='flex flex-col space-y-11 px-10 md:hidden'>
                <section className="flex flex-col w-64">
                    <img className='mb-6 w-[87px] h-[40px]' src="/img/Nifty Logo.png" alt="" />
                    <p className='text-sm font-semibold text-[#033050]'>Jl. Jangli Perbalan Tengah III No.21, Ngesrep, Kec. Banyumanik, Kota Semarang, Jawa Tengah 50261.</p>
                    {/* <p className="mt-4 text-base text-left font-extrabold text-[#033050]">Follow us</p> */}
                    <ul className=' flex flex-row pl-0 gap-y-1 gap-x-2 mt-2'>
                        <li>
                            <a href="https://www.instagram.com/niftyeducate" target="_blank" className='text-sm font-semibold'>
                                <div className="p-2 rounded-lg bg-[#005A98]"><FiInstagram className='text-xl text-white' /></div>
                            </a>
                        </li>
                        <li>
                            <a href="https://www.tiktok.com/@niftyeducate.com" target="_blank" className='text-sm font-semibold'>
                                <div className="p-2 rounded-lg bg-[#005A98]"><FaTiktok className='text-xl text-white' /></div>
                            </a>
                        </li>
                        <li>
                            <a href='https://twitter.com/niftyeducatecom' target="_blank" className='text-sm font-semibold'>
                                <div className="p-2 rounded-lg bg-[#005A98]"><BsTwitter className='text-xl text-white' /></div>
                            </a>
                        </li>
                        <li>
                            <a href="https://discord.gg/eTrwJyRa" target="_blank" className='text-sm font-semibold'>
                                <div className="p-2 rounded-lg bg-[#005A98]"><FaDiscord className='text-xl text-white' /></div>
                            </a>
                        </li>
                        <li>
                            <a href="https://t.me/niftyeducate" target="_blank" className='text-sm font-semibold'>
                                <div className="p-2 rounded-lg bg-[#005A98]"><FaTelegramPlane className='text-xl text-white' /></div>
                            </a>
                        </li>
                    </ul>
                </section>
                <section className='md:hidden'>
                        <p className="text-base text-left font-extrabold text-[#033050]">Information</p>
                        <ul className=' flex flex-col pl-0 gap-y-1 mt-2'>
                            <li><Link style={{ color: splitLocation[1] === "@" ? "#EC452D" : "black" }} to='/' className={splitLocation[1] === "" ? "text-sm text-[#EC452D] Nunito font-medium" : "hover:text-[#EC452D] font-medium Nunito text-sm"}>Leaderboard</Link></li>
                            <li><Link style={{ color: splitLocation[1] === "contact-us" ? "#EC452D" : "black" }} to='/contact-us' className={splitLocation[1] === "" ? "text-sm text-[#EC452D] Nunito font-medium" : "hover:text-[#EC452D] font-medium Nunito text-sm"}>Contact us</Link></li>
                            <li><Link style={{ color: splitLocation[1] === "how-it-work" ? "#EC452D" : "black" }} to='/how-it-work' className={splitLocation[1] === "" ? "text-sm text-[#EC452D] Nunito font-medium" : "hover:text-[#EC452D] font-medium Nunito text-sm"}>How it works</Link></li>
                            <li><Link style={{ color: splitLocation[1] === "blogs" ? "#EC452D" : "black" }} to='/blogs' className={splitLocation[1] === "" ? "text-sm text-[#EC452D] Nunito font-medium" : "hover:text-[#EC452D] font-medium Nunito text-sm"}>Blogs</Link></li>
                            <li><Link style={{ color: splitLocation[1] === "faq" ? "#EC452D" : "black" }} to='/faq' className={splitLocation[1] === "" ? "text-sm text-[#EC452D] Nunito font-medium" : "hover:text-[#EC452D] font-medium Nunito text-sm"}>FAQ</Link></li>
                        </ul>
                    </section>
                <div className="flex flex-row justify-between">
                    <section className='lg:mx-auto'>
                        <p className="text-base text-left font-extrabold text-[#033050]">Account</p>
                        <>
                            {token ?
                                (
                                    <ul className=' flex flex-col pl-0 gap-y-1 mt-2'>
                                        <li><Link style={{ color: splitLocation[1] === "my-account" ? "#EC452D" : "black" }} to='/my-account' className={splitLocation[1] === "" ? "text-sm text-[#EC452D] Nunito font-medium" : "hover:text-[#EC452D] font-medium Nunito text-sm"}>My Account</Link></li>
                                        <li><Link style={{ color: splitLocation[1] === "my-bookmark" ? "#EC452D" : "black" }} to='/my-bookmark' className={splitLocation[1] === "" ? "text-sm text-[#EC452D] Nunito font-medium" : "hover:text-[#EC452D] font-medium Nunito text-sm"}>My Bookmark</Link></li>
                                        <li><Link style={{ color: splitLocation[1] === "my-subscription" ? "#EC452D" : "black" }} to='/my-subscription' className={splitLocation[1] === "" ? "text-sm text-[#EC452D] Nunito font-medium" : "hover:text-[#EC452D] font-medium Nunito text-sm"}>My Subscription</Link></li>
                                    </ul>
                                )
                                :
                                (
                                    <ul className=' flex flex-col pl-0 gap-y-1 mt-2'>
                                        <li><button onClick={() => functionModalAuth(true) && setCodeAuth("1")} style={{ color: splitLocation[1] === "my-account" ? "#EC452D" : "black" }} className={splitLocation[1] === "" ? "text-sm text-[#EC452D] Nunito font-medium" : "hover:text-[#EC452D] font-medium Nunito text-sm"}>My Account</button></li>
                                        <li><button onClick={() => functionModalAuth(true) && setCodeAuth("1")} style={{ color: splitLocation[1] === "my-bookmark" ? "#EC452D" : "black" }} className={splitLocation[1] === "" ? "text-sm text-[#EC452D] Nunito font-medium" : "hover:text-[#EC452D] font-medium Nunito text-sm"}>My Bookmark</button></li>
                                        <li><button onClick={() => functionModalAuth(true) && setCodeAuth("1")} style={{ color: splitLocation[1] === "my-subscription" ? "#EC452D" : "black" }} className={splitLocation[1] === "" ? "text-sm text-[#EC452D] Nunito font-medium" : "hover:text-[#EC452D] font-medium Nunito text-sm"}>My Subscription</button></li>
                                    </ul>
                                )

                            }
                        </>
                    </section>
                    <section>
                    <p className="text-base text-left font-extrabold text-[#033050]">About</p>
                    <ul className=' flex flex-col pl-0 gap-y-1 mt-2'>
                        <li><Link style={{ color: splitLocation[1] === "about" ? "#EC452D" : "black" }} to='/about' className={splitLocation[1] === "" ? "text-sm text-[#EC452D] Nunito font-medium" : "hover:text-[#EC452D] font-medium Nunito text-sm"}>About us</Link></li>
                        <li><Link style={{ color: splitLocation[1] === "terms-and-conditions" ? "#EC452D" : "black" }} to='/terms-and-conditions' className={splitLocation[1] === "" ? "text-sm text-[#EC452D] Nunito font-medium" : "hover:text-[#EC452D] font-medium Nunito text-sm"}>Terms of Use</Link></li>
                        <li><Link style={{ color: splitLocation[1] === "privacy-policy" ? "#EC452D" : "black" }} to='/privacy-policy' className={splitLocation[1] === "" ? "text-sm text-[#EC452D] Nunito font-medium" : "hover:text-[#EC452D] font-medium Nunito text-sm"}>Privacy Policy</Link></li>
                    </ul>
                </section>
                </div>
                <section className='lg:mx-auto'>
                    <p className="text-base text-left font-extrabold text-[#033050]">Payments</p>
                    <div className='mt-2 grid grid-cols-8 gap-3 items-center'>
                        <img src='/img/paymenticon/QRIS.png' height={75} width={100} alt='' />
                        <img src='/img/paymenticon/ShopeePay.png' height={75} width={100} alt='' />
                        <img src='/img/paymenticon/visa.png' height={75} width={100} alt='' />
                        <img src='/img/paymenticon/permatabank.png' height={75} width={100} alt='' />
                        <img src='/img/paymenticon/OVO.png' height={75} width={100} alt='' />
                        <img src='/img/paymenticon/MasterCard.png' height={75} width={100} alt='' />
                        <img src='/img/paymenticon/mandiri.png' height={75} width={100} alt='' />
                        <img src='/img/paymenticon/linkaja.png' height={75} width={100} alt='' />
                        <img src='/img/paymenticon/JCB.png' height={75} width={100} alt='' />
                        <img src='/img/paymenticon/dana.png' height={75} width={100} alt='' />
                        <img src='/img/paymenticon/BSI.PNG' height={75} width={100} alt='' />
                        <img src='/img/paymenticon/BRI.png' height={75} width={100} alt='' />
                        <img src='/img/paymenticon/BNI.png' height={75} width={100} alt='' />
                        <img src='/img/paymenticon/BJB.png' height={75} width={100} alt='' />
                        <img src='/img/paymenticon/BCA.png' height={75} width={100} alt='' />
                        <img src='/img/paymenticon/akulaku.png' height={75} width={100} alt='' />
                    </div>
                </section>
            </div>
            <p className="text-xs -z-10 relative text-[#005A98] text-center mb-5 font-normal mt-12">Nifty Educate @ 2022 All rights reserved.</p>
        </div>
    )
}

export default Footer
