import React from 'react'

function Skeleton() {
  return (
    <div className='ml-auto mx-auto bg-white z-20 relative lg:-translate-y-20'>
        <div className="flex justify-between mb-4">
            <div className='rounded-tl-lg w-20 md:w-64 h-4 mt-2 rounded-lg bg-gray-300'></div>
            <div className='rounded-tl-lg w-20 md:w-64 h-4 mt-2 rounded-lg bg-gray-300'></div>
        </div>
        {/* spacer */}
        <div className="grid grid-cols-1 gap-4 md:grid-cols-2 lg:grid-cols-3 animate-pulse">
            <div className="bg-white w-72 md:w-52 lg:w-56 xl:w-64 rounded-lg shadow-md">
                <div className='rounded-tl-lg w-64 h-64 mt-2 rounded-tr-lg bg-gray-300'></div>
                <div className="p-4 space-y-2">
                    <div className="w-40 h-4 bg-gray-300"></div>
                    <div className="flex justify-between items-center">
                        <section className="gap-y-2">
                            <div className="w-28 h-4 bg-gray-300"></div>
                            <div className="w-28 h-2 bg-gray-300"></div>
                        </section>
                    </div>
                </div>
            </div>
            <div className="bg-white w-72 md:w-52 lg:w-56 xl:w-64 rounded-lg shadow-md">
                <div className='rounded-tl-lg w-64 h-64 mt-2 rounded-tr-lg bg-gray-300'></div>
                <div className="p-4 space-y-2">
                    <div className="w-40 h-4 bg-gray-300"></div>
                    <div className="flex justify-between items-center">
                        <section className="gap-y-2">
                            <div className="w-28 h-4 bg-gray-300"></div>
                            <div className="w-28 h-2 bg-gray-300"></div>
                        </section>
                    </div>
                </div>
            </div>
            <div className="bg-white w-72 md:w-52 lg:w-56 xl:w-64 rounded-lg shadow-md">
                <div className='rounded-tl-lg w-64 h-64 mt-2 rounded-tr-lg bg-gray-300'></div>
                <div className="p-4 space-y-2">
                    <div className="w-40 h-4 bg-gray-300"></div>
                    <div className="flex justify-between items-center">
                        <section className="gap-y-2">
                            <div className="w-28 h-4 bg-gray-300"></div>
                            <div className="w-28 h-2 bg-gray-300"></div>
                        </section>
                    </div>
                </div>
            </div>
            <div className="bg-white w-72 md:w-52 lg:w-56 xl:w-64 rounded-lg shadow-md">
                <div className='rounded-tl-lg w-64 h-64 mt-2 rounded-tr-lg bg-gray-300'></div>
                <div className="p-4 space-y-2">
                    <div className="w-40 h-4 bg-gray-300"></div>
                    <div className="flex justify-between items-center">
                        <section className="gap-y-2">
                            <div className="w-28 h-4 bg-gray-300"></div>
                            <div className="w-28 h-2 bg-gray-300"></div>
                        </section>
                    </div>
                </div>
            </div>
            <div className="bg-white w-72 md:w-52 lg:w-56 xl:w-64 rounded-lg shadow-md">
                <div className='rounded-tl-lg w-64 h-64 mt-2 rounded-tr-lg bg-gray-300'></div>
                <div className="p-4 space-y-2">
                    <div className="w-40 h-4 bg-gray-300"></div>
                    <div className="flex justify-between items-center">
                        <section className="gap-y-2">
                            <div className="w-28 h-4 bg-gray-300"></div>
                            <div className="w-28 h-2 bg-gray-300"></div>
                        </section>
                    </div>
                </div>
            </div>
            <div className="bg-white w-72 md:w-52 lg:w-56 xl:w-64 rounded-lg shadow-md">
                <div className='rounded-tl-lg w-64 h-64 mt-2 rounded-tr-lg bg-gray-300'></div>
                <div className="p-4 space-y-2">
                    <div className="w-40 h-4 bg-gray-300"></div>
                    <div className="flex justify-between items-center">
                        <section className="gap-y-2">
                            <div className="w-28 h-4 bg-gray-300"></div>
                            <div className="w-28 h-2 bg-gray-300"></div>
                        </section>
                    </div>
                </div>
            </div>
            <div className="bg-white w-72 md:w-52 lg:w-56 xl:w-64 rounded-lg shadow-md">
                <div className='rounded-tl-lg w-64 h-64 mt-2 rounded-tr-lg bg-gray-300'></div>
                <div className="p-4 space-y-2">
                    <div className="w-40 h-4 bg-gray-300"></div>
                    <div className="flex justify-between items-center">
                        <section className="gap-y-2">
                            <div className="w-28 h-4 bg-gray-300"></div>
                            <div className="w-28 h-2 bg-gray-300"></div>
                        </section>
                    </div>
                </div>
            </div>
            <div className="bg-white w-72 md:w-52 lg:w-56 xl:w-64 rounded-lg shadow-md">
                <div className='rounded-tl-lg w-64 h-64 mt-2 rounded-tr-lg bg-gray-300'></div>
                <div className="p-4 space-y-2">
                    <div className="w-40 h-4 bg-gray-300"></div>
                    <div className="flex justify-between items-center">
                        <section className="gap-y-2">
                            <div className="w-28 h-4 bg-gray-300"></div>
                            <div className="w-28 h-2 bg-gray-300"></div>
                        </section>
                    </div>
                </div>
            </div>
            <div className="bg-white w-72 md:w-52 lg:w-56 xl:w-64 rounded-lg shadow-md">
                <div className='rounded-tl-lg w-64 h-64 mt-2 rounded-tr-lg bg-gray-300'></div>
                <div className="p-4 space-y-2">
                    <div className="w-40 h-4 bg-gray-300"></div>
                    <div className="flex justify-between items-center">
                        <section className="gap-y-2">
                            <div className="w-28 h-4 bg-gray-300"></div>
                            <div className="w-28 h-2 bg-gray-300"></div>
                        </section>
                    </div>
                </div>
            </div>
        </div>
    </div>
  )
}

function SkeletonLibraryListSingle(){
    return (
        <div className="bg-white w-72 md:w-52 lg:w-56 xl:w-64 rounded-lg shadow-md">
        <div className='rounded-tl-lg w-64 h-64 mt-2 rounded-tr-lg bg-gray-300'></div>
        <div className="p-4 space-y-2">
            <div className="w-40 h-4 bg-gray-300"></div>
            <div className="flex justify-between items-center">
                <section className="gap-y-2">
                    <div className="w-28 h-4 bg-gray-300"></div>
                    <div className="w-28 h-2 bg-gray-300"></div>
                </section>
            </div>
        </div>
    </div>
    )
}
function SkeletonLibrary() {
    return (
        <div>
            <section className="my-28 w-full lg:max-w-7xl px-10 animate-pulse md:px-20">
                <div className="bg-gray-300 w-40 h-4 rounded-full"></div>
                <section className="mx-auto mt-8 md:mt-6 mb-16">
                    <div className="bg-gray-300 w-64 h-4 rounded-full"></div>
                    <div className="bg-gray-300 w-52 h-4 rounded-full"></div>
                </section>
                <div className="flex flex-col md:flex-row justify-between">
                    <section className='hidden md:block'>
                        <div className="bg-gray-300 w-44 h-14 rounded-full mb-4"></div>
                        <div className="bg-gray-300 w-44 h-14 rounded-full mb-4"></div>
                    </section>
                    <section className='block md:hidden z-50 relative'>
                        <div className="bg-gray-300 w-8 h-8 rounded-full"></div>
                    </section>
                    <section className='gap-x-8'>
                        <section className="flex px-2 items-center relative justify-between mb-6">
                            <div className="bg-gray-300 w-44 h-4 rounded-full"></div>
                            <section className='flex items-center gap-x-2'>
                                <div className="bg-gray-300 w-48 h-4 rounded-full"></div>
                                <div className="bg-gray-300 w-44 h-4 rounded-full"></div>
                            </section>
                        </section>
                        <div className="flex items-end float-right ml-[5.5rem] gap-x-4 justify-between md:flex-row flex-col animate-pulse">
                            <div className="bg-white w-72 md:w-52 lg:w-56 xl:w-64 rounded-lg shadow-md">
                                <div className='rounded-tl-lg w-64 h-64 mt-2 rounded-tr-lg bg-gray-300'></div>
                                <div className="p-4 space-y-2">
                                    <div className="w-40 h-4 bg-gray-300"></div>
                                    <div className="flex justify-between items-center">
                                        <section className="gap-y-2">
                                            <div className="w-28 h-4 bg-gray-300"></div>
                                            <div className="w-28 h-2 bg-gray-300"></div>
                                        </section>
                                    </div>
                                </div>
                            </div>
                            <div className="bg-white w-72 md:w-52 lg:w-56 xl:w-64 rounded-lg shadow-md">
                                <div className='rounded-tl-lg w-64 h-64 mt-2 rounded-tr-lg bg-gray-300'></div>
                                <div className="p-4 space-y-2">
                                    <div className="w-40 h-4 bg-gray-300"></div>
                                    <div className="flex justify-between items-center">
                                        <section className="gap-y-2">
                                            <div className="w-28 h-4 bg-gray-300"></div>
                                            <div className="w-28 h-2 bg-gray-300"></div>
                                        </section>
                                    </div>
                                </div>
                            </div>
                            <div className="bg-white w-72 md:w-52 lg:w-56 xl:w-64 rounded-lg shadow-md">
                                <div className='rounded-tl-lg w-64 h-64 mt-2 rounded-tr-lg bg-gray-300'></div>
                                <div className="p-4 space-y-2">
                                    <div className="w-40 h-4 bg-gray-300"></div>
                                    <div className="flex justify-between items-center">
                                        <section className="gap-y-2">
                                            <div className="w-28 h-4 bg-gray-300"></div>
                                            <div className="w-28 h-2 bg-gray-300"></div>
                                        </section>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </section>
                </div>
            </section>
        </div>
    )
}
function SkeletonDetail() {
    return (
        <div>
            <div className="w-full container bg-white">
                <div className='rounded-xl w-32 mb-6 h-4 md:mr-auto animate-pulse bg-gray-300'></div>
                <div className="flex lg:flex-row flex-col gap-x-0 bg-white md:gap-x-10 justify-between">
                    <section className='bg-white animate-pulse'>
                        <div className='rounded-xl w-[400px] lg:mb-6 h-[400px] md:mr-auto animate-pulse bg-gray-300'></div>
                    </section>
                    <section className='space-y-4 my-4 md:my-0 bg-white'>
                        <div className='mx-auto lg:ml-auto rounded-xl w-96 h-40 bg-gray-300 animate-pulse'></div>
                        <div className="flex flex-col items-start gap-y-4">
                            <div className="flex flex-col space-y-2">
                                <div className='rounded-xl w-14 h-4 md:mr-auto animate-pulse bg-gray-300'></div>
                                <div className='rounded-xl w-96 h-8 md:mr-auto animate-pulse bg-gray-300'></div>
                            </div>
                            <div className="flex flex-col space-y-2">
                                <div className='rounded-xl w-14 h-4 md:mr-auto animate-pulse bg-gray-300'></div>
                                <div className='rounded-xl w-96 h-8 md:mr-auto animate-pulse bg-gray-300'></div>
                            </div>
                            <div className="flex flex-col space-y-2">
                                <div className='rounded-xl w-14 h-4 md:mr-auto animate-pulse bg-gray-300'></div>
                                <div className='rounded-xl w-96 h-8 md:mr-auto animate-pulse bg-gray-300'></div>
                            </div>
                        </div>
                    </section>
                    <section className='w-96 lg:w-64 mr-40 h-80 bg-white'>
                        <div className="p-4 rounded-xl shadow-md flex flex-col space-y-4">
                            <div className='w-full lg:w-56 animate-pulse px-4 py-3 rounded-xl bg-gray-300'></div>
                            <div className='w-full lg:w-56 animate-pulse px-4 py-3 rounded-xl bg-gray-300'></div>
                            <div className='w-full lg:w-56 animate-pulse px-4 py-3 rounded-xl bg-gray-300'></div>
                        </div>
                    </section>
                </div>
            </div>
        </div>
    )
}
function SkeletonRecommendation() {
    return(
        <div className='translate-y-8'>
            <div className='rounded-xl mx-auto w-96 h-8 bg-gray-300 animate-pulse'></div>
            <div className='mb-8 mt-10'>
                <div className="flex space-x-8 px-20 pb-4 justify-between bg-[#f8f4f4] md:flex-row flex-col">
                    <div className="bg-white animate-pulse w-72 h-max sm:w-64 md:w-56 xl:w-64 rounded-lg transform duration-200 ease-out hover:drop-shadow-lg shadow-md">
                        <div className="bg-gray-300 w-auto rounded-tl-lg rounded-tr-lg h-64"></div>
                        <div className="p-4 mt-2 space-y-2">
                            <div className="bg-gray-300 w-32 h-5"></div>
                            <div className="flex justify-between items-center">
                                <div className="bg-gray-300 w-24 h-5"></div>
                            </div>
                        </div>
                    </div>
                    <div className="bg-white animate-pulse w-72 h-max sm:w-64 md:w-56 xl:w-64 rounded-lg transform duration-200 ease-out hover:drop-shadow-lg shadow-md">
                        <div className="bg-gray-300 w-auto rounded-tl-lg rounded-tr-lg h-64"></div>
                        <div className="p-4 mt-2 space-y-2">
                            <div className="bg-gray-300 w-32 h-5"></div>
                            <div className="flex justify-between items-center">
                                <div className="bg-gray-300 w-24 h-5"></div>
                            </div>
                        </div>
                    </div>
                    <div className="bg-white animate-pulse w-72 h-max sm:w-64 md:w-56 xl:w-64 rounded-lg transform duration-200 ease-out hover:drop-shadow-lg shadow-md">
                        <div className="bg-gray-300 w-auto rounded-tl-lg rounded-tr-lg h-64"></div>
                        <div className="p-4 mt-2 space-y-2">
                            <div className="bg-gray-300 w-32 h-5"></div>
                            <div className="flex justify-between items-center">
                                <div className="bg-gray-300 w-24 h-5"></div>
                            </div>
                        </div>
                    </div>
                    <div className="bg-white animate-pulse w-72 h-max sm:w-64 md:w-56 xl:w-64 rounded-lg transform duration-200 ease-out hover:drop-shadow-lg shadow-md">
                        <div className="bg-gray-300 w-auto rounded-tl-lg rounded-tr-lg h-64"></div>
                        <div className="p-4 mt-2 space-y-2">
                            <div className="bg-gray-300 w-32 h-5"></div>
                            <div className="flex justify-between items-center">
                                <div className="bg-gray-300 w-24 h-5"></div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}
function SkeletonBookmark() {
    return (
      <div className='ml-auto mx-auto bg-white z-20 relative'>
          <div className="flex justify-between mb-4">
              <div className='rounded-tl-lg w-20 md:w-64 h-4 mt-2 rounded-lg bg-gray-300'></div>
              <div className='rounded-tl-lg w-20 md:w-64 h-4 mt-2 rounded-lg bg-gray-300'></div>
          </div>
          {/* spacer */}
          <div className="grid grid-cols-1 gap-4 md:grid-cols-2 lg:grid-cols-3 animate-pulse">
              <div className="bg-white w-72 md:w-52 lg:w-56 xl:w-64 rounded-lg shadow-md">
                  <div className='rounded-tl-lg w-64 h-64 mt-2 rounded-tr-lg bg-gray-300'></div>
                  <div className="p-4 space-y-2">
                      <div className="w-40 h-4 bg-gray-300"></div>
                      <div className="flex justify-between items-center">
                          <section className="gap-y-2">
                              <div className="w-28 h-4 bg-gray-300"></div>
                              <div className="w-28 h-2 bg-gray-300"></div>
                          </section>
                      </div>
                  </div>
              </div>
              <div className="bg-white w-72 md:w-52 lg:w-56 xl:w-64 rounded-lg shadow-md">
                  <div className='rounded-tl-lg w-64 h-64 mt-2 rounded-tr-lg bg-gray-300'></div>
                  <div className="p-4 space-y-2">
                      <div className="w-40 h-4 bg-gray-300"></div>
                      <div className="flex justify-between items-center">
                          <section className="gap-y-2">
                              <div className="w-28 h-4 bg-gray-300"></div>
                              <div className="w-28 h-2 bg-gray-300"></div>
                          </section>
                      </div>
                  </div>
              </div>
              <div className="bg-white w-72 md:w-52 lg:w-56 xl:w-64 rounded-lg shadow-md">
                  <div className='rounded-tl-lg w-64 h-64 mt-2 rounded-tr-lg bg-gray-300'></div>
                  <div className="p-4 space-y-2">
                      <div className="w-40 h-4 bg-gray-300"></div>
                      <div className="flex justify-between items-center">
                          <section className="gap-y-2">
                              <div className="w-28 h-4 bg-gray-300"></div>
                              <div className="w-28 h-2 bg-gray-300"></div>
                          </section>
                      </div>
                  </div>
              </div>
              <div className="bg-white w-72 md:w-52 lg:w-56 xl:w-64 rounded-lg shadow-md">
                  <div className='rounded-tl-lg w-64 h-64 mt-2 rounded-tr-lg bg-gray-300'></div>
                  <div className="p-4 space-y-2">
                      <div className="w-40 h-4 bg-gray-300"></div>
                      <div className="flex justify-between items-center">
                          <section className="gap-y-2">
                              <div className="w-28 h-4 bg-gray-300"></div>
                              <div className="w-28 h-2 bg-gray-300"></div>
                          </section>
                      </div>
                  </div>
              </div>
              <div className="bg-white w-72 md:w-52 lg:w-56 xl:w-64 rounded-lg shadow-md">
                  <div className='rounded-tl-lg w-64 h-64 mt-2 rounded-tr-lg bg-gray-300'></div>
                  <div className="p-4 space-y-2">
                      <div className="w-40 h-4 bg-gray-300"></div>
                      <div className="flex justify-between items-center">
                          <section className="gap-y-2">
                              <div className="w-28 h-4 bg-gray-300"></div>
                              <div className="w-28 h-2 bg-gray-300"></div>
                          </section>
                      </div>
                  </div>
              </div>
              <div className="bg-white w-72 md:w-52 lg:w-56 xl:w-64 rounded-lg shadow-md">
                  <div className='rounded-tl-lg w-64 h-64 mt-2 rounded-tr-lg bg-gray-300'></div>
                  <div className="p-4 space-y-2">
                      <div className="w-40 h-4 bg-gray-300"></div>
                      <div className="flex justify-between items-center">
                          <section className="gap-y-2">
                              <div className="w-28 h-4 bg-gray-300"></div>
                              <div className="w-28 h-2 bg-gray-300"></div>
                          </section>
                      </div>
                  </div>
              </div>
              <div className="bg-white w-72 md:w-52 lg:w-56 xl:w-64 rounded-lg shadow-md">
                  <div className='rounded-tl-lg w-64 h-64 mt-2 rounded-tr-lg bg-gray-300'></div>
                  <div className="p-4 space-y-2">
                      <div className="w-40 h-4 bg-gray-300"></div>
                      <div className="flex justify-between items-center">
                          <section className="gap-y-2">
                              <div className="w-28 h-4 bg-gray-300"></div>
                              <div className="w-28 h-2 bg-gray-300"></div>
                          </section>
                      </div>
                  </div>
              </div>
              <div className="bg-white w-72 md:w-52 lg:w-56 xl:w-64 rounded-lg shadow-md">
                  <div className='rounded-tl-lg w-64 h-64 mt-2 rounded-tr-lg bg-gray-300'></div>
                  <div className="p-4 space-y-2">
                      <div className="w-40 h-4 bg-gray-300"></div>
                      <div className="flex justify-between items-center">
                          <section className="gap-y-2">
                              <div className="w-28 h-4 bg-gray-300"></div>
                              <div className="w-28 h-2 bg-gray-300"></div>
                          </section>
                      </div>
                  </div>
              </div>
              <div className="bg-white w-72 md:w-52 lg:w-56 xl:w-64 rounded-lg shadow-md">
                  <div className='rounded-tl-lg w-64 h-64 mt-2 rounded-tr-lg bg-gray-300'></div>
                  <div className="p-4 space-y-2">
                      <div className="w-40 h-4 bg-gray-300"></div>
                      <div className="flex justify-between items-center">
                          <section className="gap-y-2">
                              <div className="w-28 h-4 bg-gray-300"></div>
                              <div className="w-28 h-2 bg-gray-300"></div>
                          </section>
                      </div>
                  </div>
              </div>
          </div>
      </div>
    )
}
function SkeletonSubscription() {
    return (
        <section className='bg-white p-4 rounded-xl shadow-md w-full md:w-1/2 animate-pulse'>
            <div className="flex flex-col space-y-4 mb-6">
              <div className='w-20 h-4 bg-gray-500'></div>
              <div className="w-40 h-6 bg-gray-500"></div>
            </div>
            <div className="flex items-center justify-between mb-3">
                <div className='w-20 h-4 bg-gray-500'></div>
              <div className="w-40 h-6 bg-gray-500"></div>
            </div>
            <div className='flex gap-x-2'>
                <div className='w-24 h-4 bg-gray-500'></div>
                <div className="w-24 h-4 bg-gray-500"></div>
            </div>
            </section>
    )
}

function SkeletonBlogDetail(){
return(
    <div className='w-full mx-auto lg:max-w-7xl px-8 lg:px-16 animate-pulse'>
        <div className="h-[28px] w-[220px] mt-28 mb-10 rounded-lg bg-gray-300"></div>
        <section className="flex flex-col lg:flex-row container">
            <div className="sm:w-[40rem] md:w-[60rem] lg:w-full">
                <div className="h-[30px] w-[300px] bg-gray-300 Nunito rounded-lg mb-2"></div>
                <div className="mb-4 bg-gray-300 rounded-lg h-[20px] w-[120px]"></div>
                <div className="md:w-[40rem] h-96 w-[80vw] object-top object-cover rounded-lg bg-gray-300"/>
                <section className="mt-8 w-[80vw] h-[600px] md:w-2/3 lg:w-[40rem] rounded-lg bg-gray-300"></section>
            </div>
            <div className="w-full lg:w-96 ml-[200px] hidden lg:-translate-x-10 lg:block">
          <div className="h-[28px] w-[130px] bg-gray-300 rounded-lg"></div>
          {/* Recommendation card start */}
          <section className="space-y-4 mt-4 animate-pulse">
            <div className="flex items-center mb-1 gap-x-4 flex-row">
              <div className="w-[100px] h-[100px] bg-gray-300 rounded-lg"/>
                <div className="flex flex-col gap-y-2">
                    <div className="h-[28px] w-56 Nunito bg-gray-300 rounded-lg"></div>
                    <div className="bg-gray-300 gap-x-2 h-[20px] w-[100px] rounded-lg"></div>
                </div>
            </div>
            <div className="flex items-center mb-1 gap-x-4 flex-row">
              <div className="w-[100px] h-[100px] bg-gray-300 rounded-lg"/>
                <div className="flex flex-col gap-y-2">
                    <div className="h-[28px] w-56 Nunito bg-gray-300 rounded-lg"></div>
                    <div className="bg-gray-300 gap-x-2 h-[20px] w-[100px] rounded-lg"></div>
                </div>
            </div>
            <div className="flex items-center mb-1 gap-x-4 flex-row">
              <div className="w-[100px] h-[100px] bg-gray-300 rounded-lg"/>
                <div className="flex flex-col gap-y-2">
                    <div className="h-[28px] w-56 Nunito bg-gray-300 rounded-lg"></div>
                    <div className="bg-gray-300 gap-x-2 h-[20px] w-[100px] rounded-lg"></div>
                </div>
            </div>
            <div className="flex items-center mb-1 gap-x-4 flex-row">
              <div className="w-[100px] h-[100px] bg-gray-300 rounded-lg"/>
                <div className="flex flex-col gap-y-2">
                    <div className="h-[28px] w-56 Nunito bg-gray-300 rounded-lg"></div>
                    <div className="bg-gray-300 gap-x-2 h-[20px] w-[100px] rounded-lg"></div>
                </div>
            </div>
          </section>
        </div>
    </section>
    </div>
)
}

function SkeletonBlog () {
return(
    <section className='w-full p-8 py-20 animate-pulse'>
    <div className="h-[16px] w-[100px] px-0 lg:px-10 my-6 ml-8 rounded-lg bg-gray-300 font-semibold"></div>

  <div className="relative grid grid-cols-1 sm:grid-cols-2 xl:grid-cols-3 gap-x-20 gap-y-5">
    <div className="w-72 md:w-52 mx-auto lg:w-[360px]">
        <div className='rounded-tl-xl w-70 h-56 object-top object-cover bg-gray-300 rounded-tr-xl'/> 
        <section className='flex flex-col mt-4 gap-y-1 mb-2'>
            <div className="h-[20px] w-70 rounded-lg bg-gray-300"></div>
            <div className="h-[20px] mt-2 w-[150px] rounded-lg bg-gray-300"></div>
        </section>
        <div className="flex items-center gap-x-2 text-sm"></div>
    </div>
    <div className="w-72 md:w-52 mx-auto lg:w-[360px]">
        <div className='rounded-tl-xl w-70 h-56 object-top object-cover bg-gray-300 rounded-tr-xl'/> 
        <section className='flex flex-col mt-4 gap-y-1 mb-2'>
            <div className="h-[20px] w-70 rounded-lg bg-gray-300"></div>
            <div className="h-[20px] mt-2 w-[150px] rounded-lg bg-gray-300"></div>
        </section>
        <div className="flex items-center gap-x-2 text-sm"></div>
    </div>
    <div className="w-72 md:w-52 mx-auto lg:w-[360px]">
        <div className='rounded-tl-xl w-70 h-56 object-top object-cover bg-gray-300 rounded-tr-xl'/> 
        <section className='flex flex-col mt-4 gap-y-1 mb-2'>
            <div className="h-[20px] w-70 rounded-lg bg-gray-300"></div>
            <div className="h-[20px] mt-2 w-[150px] rounded-lg bg-gray-300"></div>
        </section>
        <div className="flex items-center gap-x-2 text-sm"></div>
    </div>
    <div className="w-72 md:w-52 mx-auto lg:w-[360px]">
        <div className='rounded-tl-xl w-70 h-56 object-top object-cover bg-gray-300 rounded-tr-xl'/> 
        <section className='flex flex-col mt-4 gap-y-1 mb-2'>
            <div className="h-[20px] w-70 rounded-lg bg-gray-300"></div>
            <div className="h-[20px] mt-2 w-[150px] rounded-lg bg-gray-300"></div>
        </section>
        <div className="flex items-center gap-x-2 text-sm"></div>
    </div>
    <div className="w-72 md:w-52 mx-auto lg:w-[360px]">
        <div className='rounded-tl-xl w-70 h-56 object-top object-cover bg-gray-300 rounded-tr-xl'/> 
        <section className='flex flex-col mt-4 gap-y-1 mb-2'>
            <div className="h-[20px] w-70 rounded-lg bg-gray-300"></div>
            <div className="h-[20px] mt-2 w-[150px] rounded-lg bg-gray-300"></div>
        </section>
        <div className="flex items-center gap-x-2 text-sm"></div>
    </div>
    <div className="w-72 md:w-52 mx-auto lg:w-[360px]">
        <div className='rounded-tl-xl w-70 h-56 object-top object-cover bg-gray-300 rounded-tr-xl'/> 
        <section className='flex flex-col mt-4 gap-y-1 mb-2'>
            <div className="h-[20px] w-70 rounded-lg bg-gray-300"></div>
            <div className="h-[20px] mt-2 w-[150px] rounded-lg bg-gray-300"></div>
        </section>
        <div className="flex items-center gap-x-2 text-sm"></div>
    </div>
    <div className="w-72 md:w-52 mx-auto lg:w-[360px]">
        <div className='rounded-tl-xl w-70 h-56 object-top object-cover bg-gray-300 rounded-tr-xl'/> 
        <section className='flex flex-col mt-4 gap-y-1 mb-2'>
            <div className="h-[20px] w-70 rounded-lg bg-gray-300"></div>
            <div className="h-[20px] mt-2 w-[150px] rounded-lg bg-gray-300"></div>
        </section>
        <div className="flex items-center gap-x-2 text-sm"></div>
    </div>
    <div className="w-72 md:w-52 mx-auto lg:w-[360px]">
        <div className='rounded-tl-xl w-70 h-56 object-top object-cover bg-gray-300 rounded-tr-xl'/> 
        <section className='flex flex-col mt-4 gap-y-1 mb-2'>
            <div className="h-[20px] w-70 rounded-lg bg-gray-300"></div>
            <div className="h-[20px] mt-2 w-[150px] rounded-lg bg-gray-300"></div>
        </section>
        <div className="flex items-center gap-x-2 text-sm"></div>
    </div>
    <div className="w-72 md:w-52 mx-auto lg:w-[360px]">
        <div className='rounded-tl-xl w-70 h-56 object-top object-cover bg-gray-300 rounded-tr-xl'/> 
        <section className='flex flex-col mt-4 gap-y-1 mb-2'>
            <div className="h-[20px] w-70 rounded-lg bg-gray-300"></div>
            <div className="h-[20px] mt-2 w-[150px] rounded-lg bg-gray-300"></div>
        </section>
        <div className="flex items-center gap-x-2 text-sm"></div>
    </div>
    <div className="w-72 md:w-52 mx-auto lg:w-[360px]">
        <div className='rounded-tl-xl w-70 h-56 object-top object-cover bg-gray-300 rounded-tr-xl'/> 
        <section className='flex flex-col mt-4 gap-y-1 mb-2'>
            <div className="h-[20px] w-70 rounded-lg bg-gray-300"></div>
            <div className="h-[20px] mt-2 w-[150px] rounded-lg bg-gray-300"></div>
        </section>
        <div className="flex items-center gap-x-2 text-sm"></div>
    </div>
    <div className="w-72 md:w-52 mx-auto lg:w-[360px]">
        <div className='rounded-tl-xl w-70 h-56 object-top object-cover bg-gray-300 rounded-tr-xl'/> 
        <section className='flex flex-col mt-4 gap-y-1 mb-2'>
            <div className="h-[20px] w-70 rounded-lg bg-gray-300"></div>
            <div className="h-[20px] mt-2 w-[150px] rounded-lg bg-gray-300"></div>
        </section>
        <div className="flex items-center gap-x-2 text-sm"></div>
    </div>
    <div className="w-72 md:w-52 mx-auto lg:w-[360px]">
        <div className='rounded-tl-xl w-70 h-56 object-top object-cover bg-gray-300 rounded-tr-xl'/> 
        <section className='flex flex-col mt-4 gap-y-1 mb-2'>
            <div className="h-[20px] w-70 rounded-lg bg-gray-300"></div>
            <div className="h-[20px] mt-2 w-[150px] rounded-lg bg-gray-300"></div>
        </section>
        <div className="flex items-center gap-x-2 text-sm"></div>
    </div>

  </div>
  
  </section>

)
}


function SkeletonDrawer(){
    return(
        <div className='space-y-4 hidden md:block'>
            <div className='bg-gray-300 w-52 h-[20px] rounded-lg animate-pulse'></div>
            <div className='bg-gray-300 w-52 h-[20px] rounded-lg animate-pulse'></div>
            <div className='bg-gray-300 w-52 h-[20px] rounded-lg animate-pulse'></div>
            <div className='bg-gray-300 w-52 h-[20px] rounded-lg animate-pulse'></div>
            <div className='bg-gray-300 w-52 h-[20px] rounded-lg animate-pulse'></div>
            <div className='bg-gray-300 w-52 h-[20px] rounded-lg animate-pulse'></div>
            <div className='bg-gray-300 w-52 h-[20px] rounded-lg animate-pulse'></div>
            <div className='bg-gray-300 w-52 h-[20px] rounded-lg animate-pulse'></div>
            <div className='bg-gray-300 w-52 h-[20px] rounded-lg animate-pulse'></div>
            <div className='bg-gray-300 w-52 h-[20px] rounded-lg animate-pulse'></div>
        </div>
    )
}


function SkeletonMySub(){
    return(
        <section className='w-full md:w-3/4'>
        <div className="flex gap-x-6 gap-y-4 md:gap-y-0 flex-col md:flex-row justify-between items-start">
          {/* spacer */}
          <section className=' bg-white p-4 mx-auto rounded-xl shadow-md w-full md:w-1/2'>
            <div className="flex flex-col space-y-4 mb-6">
                <div className='rounded-lg h-[16px] w-[60px] bg-gray-300 animate-pulse'></div>
                <div className='h-[32px] w-[120px] rounded-lg mt-6 mb-6 bg-gray-300 animate-pulse'></div>
            </div>
                <div className="flex items-center justify-between mb-6">
                  <div className="animate-pulse bg-gray-300 h-[22px] w-[80px] rounded-lg"></div>
                  <div className="animate-pulse bg-gray-300 h-[22px] w-[100px] rounded-lg"></div>
                </div>
                <div className='flex gap-x-2'>
                    <div className="bg-gray-300 px-6 rounded-full py-2 text-white text-sm h-[36px] w-1/3"></div>
                    <div className="bg-gray-300 px-6 rounded-full py-2 text-white text-sm h-[36px] w-1/3"></div>
                </div>
          </section>
          {/* spacer */}
          <section className='bg-white p-4 rounded-xl shadow-md w-full md:w-1/2'>
            <div className="flex items-center justify-between mb-6">
              <div className='animate-pulse bg-gray-300 h-[22px] w-[120px] rounded-lg'></div>
            </div>
            <div className="flex items-center justify-between mb-3">
              <div className="animate-pulse bg-gray-300 h-[20px] w-[90px] rounded-lg"></div>
              <div className="animate-pulse bg-gray-300 h-[20px] w-[90px] rounded-lg"></div>
            </div>
            <div className="animate-pulse bg-gray-300 h-[20px] w-[90px] rounded-lg mb-2"></div>
                <div className="border-2 flex mb-2 items-center justify-between border-[#e1eef8] p-4 rounded-xl">
                  <div className="space-y-1 flex flex-col">
                    <div className="animate-pulse bg-gray-300 h-[16px] w-[90px] rounded-lg"></div>
                    <div className="animate-pulse bg-gray-300 h-[16px] w-[120px] rounded-lg"></div>
                  </div>
                  <div  className="animate-pulse rounded-full bg-gray-300 h-[32px] w-[120px]"></div>
                  {/* <button className="px-6 py-2 rounded-full bg-[#005a98] text-white text-sm">View All</button> */}
                </div>
          </section>
        </div>
        </section>
    )
}



export  {Skeleton, SkeletonRecommendation, SkeletonLibrary, SkeletonLibraryListSingle, SkeletonDetail, SkeletonBookmark, SkeletonSubscription, SkeletonBlogDetail, SkeletonBlog, SkeletonDrawer, SkeletonMySub}