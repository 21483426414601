import React, { useEffect, useState } from 'react'
import { Tab } from '@headlessui/react'
import Api from '../../api';
import moment from 'moment';
import Cookies from 'js-cookie';

function classNames(...classes) {
  return classes.filter(Boolean).join(' ')
}


export default function CardLeaderBoard() {
  const token = Cookies.get("token");
  const [leaderBoard, setLeaderBoard] = useState([]);
  const [time, setTime] = useState(``)
  const [topFive, setTopFive] = useState({})
  const [isUserLeaderBoard, setIsUserLeaderBoard] = useState([])
  const [currentUser, setCurrentUser] = useState([])
  const [rank_user, setRankUser] = useState([])

  const getLeaderBoard = async (e) => {
    const times = e ? e : time;

    await Api.get(`/leaderboard?time=${times}&limit=4`, {
      headers: {
        Authorization: `Bearer ${token}`,
      }
    }).then((response) => {
      setLeaderBoard(response.data.data.leaderboard);
      setTopFive(response.data.topfive);
      setIsUserLeaderBoard(response.data.data);
      setCurrentUser(response.data.data.userLeaderboard[0]);
      setRankUser(response.data.rank_user);
    })
  }

  const clickTab = (e) => {
    setTime(time)
    getLeaderBoard(e);
  }
  useEffect(() => {
    getLeaderBoard(time);
    // eslint-disable-next-line
  }, [])
  return (
    <>
      <div className="mx-auto w-[100vw] sm:px-0 lg:max-w-4xl">
        <Tab.Group>
          <Tab.List className="flex justify-center mb-11">
            <Tab onClick={() => { clickTab(`week`) }} className={({ selected }) => classNames('w-28 py-2 leading-5 focus:outline-none font-extrabold text-base md:text-lg text-[#033050]', selected ? 'border-b-2 border-yellow-400' : 'text-[#B0D4ED]')}>
              <span>This Week</span>
            </Tab>
            <Tab onClick={() => { clickTab(`month`) }} className={({ selected }) => classNames('w-28 py-2 leading-5 focus:outline-none font-extrabold text-base md:text-lg text-[#033050]', selected ? 'border-b-2 border-yellow-400' : 'text-[#B0D4ED]')}>
              <span>This Month</span>
            </Tab>
            <Tab onClick={() => { clickTab(`year`) }} className={({ selected }) => classNames('w-28 py-2 leading-5 focus:outline-none font-extrabold text-base md:text-lg text-[#033050]', selected ? 'border-b-2 border-yellow-400' : 'text-[#B0D4ED]')}>
              <span>This Year</span>
            </Tab>
          </Tab.List>
          { token ?
          (<Tab.Panels className="mt-2">
            {/* week */}
            <Tab.Panel className="w-[90vw] lg:w-[720px] space-y-4 mx-auto">
              {
              topFive ? 
              
              leaderBoard.map((post, idx) => (
                <div key={idx + 1} className={`rounded-xl px-4 ${currentUser.user_id === post.user_id ? `bg-[#033050] text-white` : `bg-white text-[#033050]`} md:px-6 py-4 shadow-md`}>
                  <section className="flex justify-evenly w-full">
                    <div className="flex items-center w-1/4">
                      <p className="text-sm md:text-base font-extrabold">#{idx + 1}</p>
                      <h5 className="text-sm md:text-base font-extrabold truncate">{post.child_name.length > 10 ? `${post.child_name.substring(0, 10)}...` : post.child_name}</h5>
                    </div>
                    <div className='w-1/2 justify-between flex'>
                      <h5 className="text-sm md:text-base items-center font-normal text-center">{moment().diff(`${post.child_dob}`, 'years')} yo</h5>
                      <h5 className="text-sm md:text-base font-normal">{post.name}</h5>
                    </div>  
                      <h5 className="text-sm md:text-base w-1/4 font-semibold text-yellow-400">{post.total} pts.</h5>
                  </section>
                </div>
              )) : 
              
              leaderBoard.map((post, idx) => (
                <div key={idx + 1} className="flex justify-between rounded-xl px-4 md:px-6 py-4 bg-white text-[#033050] shadow-md">
                  <section className="flex justify-evenly w-full">
                    <div className="flex items-center space-x-2 w-1/4">
                      <p className="text-sm md:text-base font-extrabold">#{idx + 1}</p>
                      <h5 className="text-sm md:text-base font-extrabold truncate">{post.child_name.length > 10 ? `${post.child_name.substring(0, 10)}...` : post.child_name}</h5>
                    </div>
                    <div className='w-1/2 justify-evenly flex'>
                      <h5 className="text-sm md:text-base items-center font-normal text-center">{moment().diff(`${post.child_dob}`, 'years')} yo</h5>
                      <h5 className="text-sm md:text-base font-normal">{post.name}</h5>
                    </div>  
                      <h5 className="text-sm md:text-base w-1/4 font-semibold text-end text-yellow-400">{post.total} pts.</h5>
                  </section>
                </div>
              ))
              }
              {!topFive && currentUser ?
            (<div className="flex justify-between rounded-xl px-4 md:px-6 py-4 bg-[#033050] shadow-md text-white">
              <section className="flex justify-evenly w-full">
                <div className="flex items-center space-x-2 w-1/4">
                  <p className="text-sm md:text-base font-extrabold">#{rank_user}</p>
                  <h5 className="text-sm md:text-base font-extrabold truncate">{currentUser.child_name > 10 ? `${currentUser.child_name.substring(0, 10)}...` : currentUser.child_name}</h5>
                </div>
                <div className='w-1/2 justify-evenly flex'>
                  <h5 className="text-sm md:text-base font-normal">{moment().diff(`${currentUser.child_dob}`, 'years')} yo</h5>
                  <h5 className="text-sm md:text-base font-normal">{currentUser.name}</h5>
                </div>  
                <h5 className="text-sm md:text-base font-semibold text-yellow-400 text-end w-1/4">{currentUser.total} pts.</h5>
              </section>
            </div>) :  !currentUser ? (<div></div>) : null}
            </Tab.Panel>
            {/* month */}
            <Tab.Panel className="w-[90vw] lg:w-[720px] space-y-4 mx-auto">
              {
              topFive ? 
              
              leaderBoard.map((post, idx) => (
                <div key={idx + 1} className={`flex justify-between rounded-xl px-4 ${currentUser.user_id === post.user_id ? `bg-[#033050] text-white` : `bg-white text-[#033050]`} md:px-6 py-4 shadow-md`}>
                  <section className="flex justify-evenly w-full">
                    <div className="flex items-center space-x-2 w-1/4">
                      <p className="text-sm md:text-base font-extrabold">#{idx + 1}</p>
                      <h5 className="text-sm md:text-base font-extrabold truncate">{post.child_name.length > 10 ? `${post.child_name.substring(0, 10)}...` : post.child_name}</h5>
                    </div>
                    <div className='w-1/2 justify-evenly flex'>
                      <h5 className="text-sm md:text-base items-center font-normal text-center">{moment().diff(`${post.child_dob}`, 'years')} yo</h5>
                      <h5 className="text-sm md:text-base font-normal">{post.name}</h5>
                    </div>  
                      <h5 className="text-sm md:text-base w-1/4 font-semibold text-end text-yellow-400">{post.total} pts.</h5>
                  </section>
                </div>
              )) : 
              
              leaderBoard.map((post, idx) => (
                <div key={idx + 1} className="flex justify-between rounded-xl px-4 md:px-6 py-4 bg-white text-[#033050] shadow-md">
                  <section className="flex justify-evenly w-full">
                    <div className="flex items-center space-x-2 w-1/4">
                      <p className="text-sm md:text-base font-extrabold">#{idx + 1}</p>
                      <h5 className="text-sm md:text-base font-extrabold truncate">{post.child_name.length > 10 ? `${post.child_name.substring(0, 10)}...` : post.child_name}</h5>
                    </div>
                    <div className='w-1/2 justify-evenly flex'>
                      <h5 className="text-sm md:text-base items-center font-normal text-center">{moment().diff(`${post.child_dob}`, 'years')} yo</h5>
                      <h5 className="text-sm md:text-base font-normal">{post.name}</h5>
                    </div>  
                      <h5 className="text-sm md:text-base w-1/4 font-semibold text-end text-yellow-400">{post.total} pts.</h5>
                  </section>
                </div>
              ))}
              { !topFive && currentUser ?
            (<div className="flex justify-between rounded-xl px-4 md:px-6 py-4 bg-[#033050] shadow-md text-white">
              <section className="flex justify-evenly w-full">
                <div className="flex items-center space-x-2 w-1/4">
                  <p className="text-sm md:text-base font-extrabold">#{rank_user}</p>
                  <h5 className="text-sm md:text-base font-extrabold truncate">{currentUser.child_name > 10 ? `${currentUser.child_name.substring(0, 10)}...` : currentUser.child_name}</h5>
                </div>
                <div className='w-1/2 justify-evenly flex'>
                  <h5 className="text-sm md:text-base font-normal">{moment().diff(`${currentUser.child_dob}`, 'years')} yo</h5>
                  <h5 className="text-sm md:text-base font-normal">{currentUser.name}</h5>
                </div>  
                <h5 className="text-sm md:text-base font-semibold text-yellow-400 text-end w-1/4">{currentUser.total} pts.</h5>
              </section>
            </div>) : !currentUser ? (<div></div>) : null}
            </Tab.Panel>
            {/* year */}
            <Tab.Panel className="w-[90vw] lg:w-[720px] space-y-4 mx-auto">
              {
              topFive ? 
              
              leaderBoard.map((post, idx) => (
                <div key={idx + 1} className={`flex justify-between rounded-xl px-4 ${currentUser.user_id === post.user_id ? `bg-[#033050] text-white` : `bg-white text-[#033050]`} md:px-6 py-4 shadow-md`}>
                  <section className="flex justify-evenly w-full">
                    <div className="flex items-center space-x-2 w-1/4">
                      <p className="text-sm md:text-base font-extrabold">#{idx + 1}</p>
                      <h5 className="text-sm md:text-base font-extrabold truncate">{post.child_name.length > 10 ? `${post.child_name.substring(0, 10)}...` : post.child_name}</h5>
                    </div>
                    <div className='w-1/2 justify-evenly flex'>
                      <h5 className="text-sm md:text-base items-center font-normal text-center">{moment().diff(`${post.child_dob}`, 'years')} yo</h5>
                      <h5 className="text-sm md:text-base font-normal">{post.name}</h5>
                    </div>  
                      <h5 className="text-sm md:text-base w-1/4 font-semibold text-end text-yellow-400">{post.total} pts.</h5>
                  </section>
                </div>
              )) : 
              
              leaderBoard.map((post, idx) => (
                <div key={idx + 1} className="flex justify-between rounded-xl px-4 md:px-6 py-4 bg-white text-[#033050] shadow-md">
                  <section className="flex justify-evenly w-full">
                    <div className="flex items-center space-x-2 w-1/4">
                      <p className="text-sm md:text-base font-extrabold">#{idx + 1}</p>
                      <h5 className="text-sm md:text-base font-extrabold truncate">{post.child_name.length > 10 ? `${post.child_name.substring(0, 10)}...` : post.child_name}</h5>
                    </div>
                    <div className='w-1/2 justify-evenly flex'>
                      <h5 className="text-sm md:text-base items-center font-normal text-center">{moment().diff(`${post.child_dob}`, 'years')} yo</h5>
                      <h5 className="text-sm md:text-base font-normal">{post.name}</h5>
                    </div>  
                      <h5 className="text-sm md:text-base w-1/4 font-semibold text-end text-yellow-400">{post.total} pts.</h5>
                  </section>
                </div>
              ))}
              { !topFive && currentUser ?
            (<div className="flex justify-between rounded-xl px-4 md:px-6 py-4 bg-[#033050] shadow-md text-white">
              <section className="flex justify-evenly w-full">
                <div className="flex items-center space-x-2 w-1/4">
                  <p className="text-sm md:text-base font-extrabold">#{rank_user}</p>
                  <h5 className="text-sm md:text-base font-extrabold truncate">{currentUser.child_name > 10 ? `${currentUser.child_name.substring(0, 10)}...` : currentUser.child_name}</h5>
                </div>
                <div className='w-1/2 justify-evenly flex'>
                  <h5 className="text-sm md:text-base font-normal">{moment().diff(`${currentUser.child_dob}`, 'years')} yo</h5>
                  <h5 className="text-sm md:text-base font-normal">{currentUser.name}</h5>
                </div>  
                <h5 className="text-sm md:text-base font-semibold text-yellow-400 text-end w-1/4">{currentUser.total} pts.</h5>
              </section>
            </div>) : !currentUser ? (<div></div>) : null}
            </Tab.Panel>
          </Tab.Panels>) :
          // no token 
          (<Tab.Panels className="mt-2">
            <Tab.Panel className="w-[90vw] lg:w-[720px] space-y-4 mx-auto">
              {leaderBoard.map((post, idx) => (
                <div key={idx + 1} className="flex justify-between rounded-xl px-4 md:px-6 py-4 bg-white bg text-[#033050] shadow-md">
                  <section className="flex justify-evenly w-full">
                    <div className="flex space-x-2 items-center w-1/4">
                      <p className="text-sm md:text-base font-extrabold">#{idx + 1}</p>
                      <h5 className="text-sm md:text-base font-extrabold truncate">{post.child_name.length > 10 ? `${post.child_name.substring(0, 10)}...` : post.child_name}</h5>
                    </div>
                    <div className='w-1/2 justify-evenly flex'>
                      <h5 className="text-sm md:text-base items-center font-normal text-center">{moment().diff(`${post.child_dob}`, 'years')} yo</h5>
                      <h5 className="text-sm md:text-base font-normal">{post.name}</h5>
                    </div>  
                      <h5 className="text-end text-sm md:text-base w-1/4 font-semibold text-yellow-400">{post.total} pts.</h5>
                  </section>
                </div>
              ))}
            </Tab.Panel>
            <Tab.Panel className="w-[90vw] lg:w-[720px] space-y-4 mx-auto">
              {leaderBoard.map((post, idx) => (
                <div key={idx + 1} className="flex justify-between rounded-xl px-4 md:px-6 py-4 bg-white text-[#033050] shadow-md">
                  <section className="flex justify-evenly w-full">
                    <div className="flex space-x-2 items-center w-1/4">
                      <p className="text-sm md:text-base font-extrabold">#{idx + 1}</p>
                      <h5 className="text-sm md:text-base font-extrabold truncate">{post.child_name.length > 10 ? `${post.child_name.substring(0, 10)}...` : post.child_name}</h5>
                    </div>
                    <div className='w-1/2 justify-evenly flex'>
                      <h5 className="text-sm md:text-base items-center font-normal text-center">{moment().diff(`${post.child_dob}`, 'years')} yo</h5>
                      <h5 className="text-sm md:text-base font-normal">{post.name}</h5>
                    </div>  
                      <h5 className="text-end text-sm md:text-base w-1/4 font-semibold text-yellow-400">{post.total} pts.</h5>
                  </section>
                </div>
              ))}
            </Tab.Panel>
            <Tab.Panel className="w-[90vw] lg:w-[720px] space-y-4 mx-auto">
              {leaderBoard.map((post, idx) => (
                <div key={idx + 1} className="flex justify-between rounded-xl px-4 md:px-6 py-4 bg-white text-[#033050] shadow-md">
                  <section className="flex justify-evenly w-full">
                    <div className="flex space-x-2 items-center w-1/4">
                      <p className="text-sm md:text-base font-extrabold">#{idx + 1}</p>
                      <h5 className="text-sm md:text-base font-extrabold truncate">{post.child_name.length > 10 ? `${post.child_name.substring(0, 10)}...` : post.child_name}</h5>
                    </div>
                    <div className='w-1/2 justify-evenly flex'>
                      <h5 className="text-sm md:text-base items-center font-normal text-center">{moment().diff(`${post.child_dob}`, 'years')} yo</h5>
                      <h5 className="text-sm md:text-base font-normal">{post.name}</h5>
                    </div>  
                      <h5 className="text-end text-sm md:text-base w-1/4 font-semibold text-yellow-400">{post.total} pts.</h5>
                  </section>
                </div>
              ))}
            </Tab.Panel>
          </Tab.Panels>)}
          <button hidden className="mx-auto px-10 py-2 bg-[#005A98] font-extrabold tranform duration-100 mt-11 text-white text-base rounded-full">View All</button>
        </Tab.Group>
      </div>
    </>
  )
}



