import React,{useState} from 'react'
import toast from "react-hot-toast";
import { useHistory } from "react-router-dom";
import Cookies from "js-cookie";
import LayoutWeb from '../../layouts/Web'
import Api from "../../api";
import {BiLoaderAlt} from 'react-icons/bi'

export default function VerifyEmail() {
    const [isLoading, setLoading] = useState(false);
    const [isLoadingOtp, setLoadingOpt] = useState(false);
    const [validation, setValidation] = useState({});
    const [validation_otp, setValidationOtp] = useState({});
    const [otp, setOtp] = useState('');
    const token = Cookies.get("token");
    const history = useHistory();
    window.scrollTo({
        top: 0,
        behavior: "smooth"
      });
    const verifHandler = async(e) => {
        e.preventDefault();
        setLoading(true);
        const formData = new FormData();
        formData.append('otp', otp);

        await Api.post("/verify_otp",formData, {
            headers: {
                Authorization: `Bearer ${token}`,
            }
        })
          .then((response) => {
            setLoading(false);
    
            toast.success("Email Verification Successfully.", {
                duration: 4000,
                position: "top-right",
                style: {
                    borderRadius: '10px',
                    background: '#333',
                    color: '#fff',
                },
            });
            Cookies.set("name", response.data.data.name)
            Cookies.set("token", response.data.data.token)
            Cookies.set("status", response.data.status)
            history.push('/');
    
          })
          .catch((error) => {
            setLoading(false);  
            setValidation(error.response.data);
          });
    }
    const resendOtp = async(e) => {
        setLoadingOpt(true);
        await Api.post("/request_otp",null, {
            headers: {
                Authorization: `Bearer ${token}`,
            }
        }).then((response) => {
          setLoadingOpt(false);
            toast.success("Resend otp code successful.", {
                duration: 4000,
                position: "top-right",
                style: {
                    borderRadius: '10px',
                    background: '#333',
                    color: '#fff',
                },
            });
        })
        .catch((error) => {
          setLoadingOpt(false);  
          setValidationOtp(error.response.data);
        });
    }

  return (
    <>
      <LayoutWeb>
        <div className="pt-48 pb-40">
            <div className="mx-auto w-96 bg-white rounded-xl p-6">
            <h5 className="text-4xl font-bold text-center Nunito text-[#033050]">Verify Email</h5>
            <p className="text-sm font-normal text-center mt-[14px] text-gray-500">An email with verification code <br /> was just sent. Please check your email.</p>
            <form onSubmit={verifHandler}>
                {validation.message && (
                    <div className="alert alert-danger text-red-500">
                        {validation.message}
                    </div>
                )}
                <input type="text" style={{ paddingLeft:'1rem' }} maxLength='6' className="bg-none mt-4 w-full px-2 border-2 text-center border-[#B0D4ED] py-1 rounded-md focus:outline-none" value={otp} onChange={(e) => setOtp(e.target.value)}/>
                <button onClick={()=> resendOtp()} className="text-base mb-3 text-center font-normal mt-2 w-full text-gray-500" disabled={isLoadingOtp}>{isLoadingOtp ? "Loading..." : <div className='text-sm'>Didn't receive code? <span className='font-extrabold'>Resend code</span></div>} </button>
                {validation_otp.message && (
                    <div className="alert alert-danger text-blue-500">
                        {validation_otp.message}
                    </div>
                )}
                {validation.otp && (
                    <div className="alert alert-danger text-red-500">
                        {validation.otp[0]}
                    </div>
                )}
                <div className="flex gap-x-2 justify-between items-center mt-1">

                    <button className="mt-2 bg-[#005a98] hover:bg-orange-600 py-2 w-full text-center text-white rounded-full" type="submit" disabled={isLoading}> {isLoading ? "Loading..." : <span className='text-extrabold'>Verify</span> } </button>     
                </div>
            </form>
            </div>
        </div>
      </LayoutWeb>
    </>
  )
}

