//import react router dom
import {
    BrowserRouter as Router,
    Switch,
    Route
  } from "react-router-dom";

import PrivateRoute from "./PrivateRoutes";
import VerifRoutes from "./VerifRoutes";
import VerifyEmail from '../pages/auth/VerifyEmail'
import Home from '../pages/web/home/Index';
import Subscription from '../pages/web/subscription/Index';
import library from '../pages/web/library/Index';
import MyAccount from '../pages/web/dashboard/Account'
import MyBookmark from '../pages/web/dashboard/Bookmark'
import MySubscription from '../pages/web/dashboard/Subscription'
import DetailLibrary from '../pages/web/library/Show';
import DetailBlog from '../pages/web/blog/Show';
import ConfirmPassword from '../pages/auth/ConfrimPassword';
import HowItWork from '../pages/web/how-it-work/Index';
import Blog from "../pages/web/blog/Index"
import About from "../pages/web/about-us/About";
import Faq from "../pages/web/faq";
import ContactUs from "../pages/web/contact-us";
import NotFound from "../pages/errors/NotFound";
import Payment from "../pages/web/subscription/Detail";
import TermsAndConditions from "../pages/web/terms-and-conditions/Index";
import PrivacyAndPolicy from "../pages/web/privacy-and-policy/Index";
import MyDownloads from "../pages/web/dashboard/Downloads";




function Routing() {
    return (
    <Router>
        <Switch>
                <Route exact path="/" component={Home}/>
                <VerifRoutes exact path="/verify" component={VerifyEmail}/>
                <Route exact path="/library" component={library}/>
                <Route exact path="/library/:slug"  component={DetailLibrary} />
                <Route exact path="/subscription" component={Subscription}/>
                <Route exact path="/reset-password"  component={ConfirmPassword} />
                <Route exact path="/blogs" component={Blog}/>
                <Route exact path="/blog/:slug" component={DetailBlog}/>
                <Route exact path="/how-it-work" component={HowItWork}/>
                <Route exact path="/about" component={About}/>
                <Route exact path="/faq" component={Faq}/>
                <Route exact path="/contact-us" component={ContactUs}/>
                <Route exact path="/terms-and-conditions" component={TermsAndConditions}/>
                <Route exact path="/privacy-policy" component={PrivacyAndPolicy}/>
                <PrivateRoute  exact path="/my-account" component={MyAccount}/>
                <PrivateRoute  exact path="/my-bookmark" component={MyBookmark}/>
                <PrivateRoute  exact path="/my-Subscription" component={MySubscription}/>
                <PrivateRoute exact path="/my-downloads" component={MyDownloads}/>
                <PrivateRoute exact path="/payment/:id" component={Payment}/>
                <Route exact component={NotFound}/>
        </Switch>
    </Router>
    )
}

export default Routing
