import React, { useState, useEffect } from "react";
import {AiOutlineClose} from "react-icons/ai"
import { FiDownload,FiLock } from "react-icons/fi";
import { BsBookmark, BsShare,BsFillBookmarkFill, BsArrowRight } from "react-icons/bs";
import {  useParams } from "react-router-dom";
import LayoutWeb from "../../../layouts/Web";
import Cookies from "js-cookie";
import Api from "../../../api";
import {
  SkeletonRecommendation,
  SkeletonDetail,
  SkeletonBlogDetail,
} from "../../../components/utilities/Skeleton";
import toast from "react-hot-toast";
import axios from 'axios'
import "./Show.css"
import {Link} from 'react-router-dom'

function DetailBlog() {
  const [data, setData] = useState({})
  const [popularPosts, setPopularPosts] = useState([])
  const [loading, setLoading] = useState(true)


  const token = Cookies.get("token");
  const { slug } = useParams();
  const getDetailBlog = async () => {
  await Api.get(`/blog/${slug}`,{
    headers: {
      Authorization: `Bearer ${token}`,
    },
  }).then((response)=>{
    setData(response.data.data);
    setPopularPosts(response.data.popular_post);
  }).finally(()=>{
    setLoading(false)
  })
}

useEffect(()=>{
  getDetailBlog()
},[])


const date = new Date(data.created_at)

  return (
    <LayoutWeb>
    {loading ? (<SkeletonBlogDetail/>) : 
    (<div className="w-full mx-auto lg:max-w-7xl px-8 lg:px-16">
      <p className="text-sm mt-28 mb-10 text-[#033050] font-semibold"><Link style={{color: 'black'}} to="/">Home</Link> / <Link style={{color: 'black'}} to="/blogs">Blogs</Link> / {data.title}</p>
      <section className="flex flex-col lg:flex-row container">
        <div className="w-full sm:w-[40rem] md:w-[60rem] lg:w-full">
        <h5 className="text-2xl font-bold Nunito text-[#033050]">
          {data.title}
        </h5>
        <span className="mb-8 text-gray-500">{date.toLocaleDateString("ID-id")}</span>
          <img className="mt-2 w-[40rem] h-96 object-top object-cover" src={ process.env.REACT_APP_IMG + "/storage/images/" + data.images}alt={data.title} />
          <section className="mt-8 w-fit md:w-2/3 lg:w-[40rem] space-y-2">
            <div className="blog-content text-base font-normal text-gray-600 text-justify" dangerouslySetInnerHTML={{ __html: data["body"] }}></div>
          </section>
        </div>

        <div className="w-full lg:w-96 ml-auto hidden lg:-translate-x-10 lg:block">
          <h5 className="text-lg font-bold text-[#033050]">Popular posts</h5>
          {/* Recommendation card start */}
          <section className="space-y-4 mt-4">
          { popularPosts.map((item)=>(
            <div className="flex items-center mb-1 gap-x-4 flex-row">
              <img className="w-20 rounded-lg h-20 object-cover" src={ process.env.REACT_APP_IMG + "/storage/images/" + item.images}alt={item.title} />
              <div className="flex flex-col gap-y-2">
                <h5 className="text-lg truncate w-56 font-bold Nunito text-[#033050]">{data.title}</h5>
                <a href={`/blog/${item.slug}`} className="flex items-center gap-x-2 text-sm">
                    <p className='mb-0 Nunito font-medium text-[#033050]'>Continue reading</p>
                    <BsArrowRight className='w-3 h-3 mb-0 mt-[.15rem] text-[#033050]'/>
                </a>
              </div>
            </div>))}
          </section>
          {/* Recommendation card end */}
        </div>
      </section>
    </div>)}
    </LayoutWeb>
  );
}

export default DetailBlog;
