import React from "react";
import LayoutWeb from "../../../layouts/Web";

export default function PrivacyAndPolicy() {
    return (
        <LayoutWeb>
            <section className="my-32 mx-auto lg:w-[50%] w-[80%]">
                <h1 className="text-center text-4xl font-bold">Privacy Policy</h1>
                <p className="mt-8">Effective Date: August 10 2022</p>
                <div className="mt-4">
                    <h1 className="font-bold text-3xl">Overview</h1>
                    <p className="">This is our policy regarding how we collect, use, and disclose the information we, Niftyeducate.com. (<b>“Niftyeducate.com”, “we”, “us”, or “our”</b>), collect on our website services at www.Niftyeducate.com (the <b>“Website”</b>); through the browser and other web extension applications, such as the Niftyeducate.com and Niftyeducate.com Kids Google Web Store App, and the services available through our; Website, Apps, and other applications (such services, along with the Website, Apps, and applications are, collectively, the <b>“Services”</b>).
                        By visiting our Website or using any of our Apps or Services, you agree that any information that we collect from or about you through our Services will be handled as described in this Privacy Policy. Your use of our Services, and any dispute over privacy, is subject to this Privacy Policy and our <a target="_blank" className="text-blue-600 underline" href="/terms-and-conditions">Terms of Use</a>, including its applicable limitations on damages and the resolution of disputes. The Niftyeducate.com, Inc. <a target="_blank" className="text-blue-600 underline" href="/terms-and-conditions">Terms of Use</a> are incorporated by reference into this Privacy Policy.</p>
                    <ul className="mt-4 ml-12 list-disc">
                        <li>Information We Collect</li>
                        <li>Cookies and Other Tracking Mechanisms</li>
                        <li>Use of the Information</li>
                        <li>Choice and Opt-out</li>
                        <li>Disclosure of Information</li>
                        <li>Security</li>
                        <li>Changes in this Privacy Policy</li>
                        <li>Your Independent Relationship With Other Companies</li>
                        <li>Contact Us</li>
                    </ul>
                    <h1 className="font-bold text-3xl mt-8">Information We Collect</h1>
                    <p>We may collect information about you directly from you, from third parties, and automatically through your use of our Services. We may combine information collected from or about you from these various sources.</p>
                    <h1 className="font-bold text-3xl mt-4">Information We Collect Directly From You</h1>
                    <p>We collect information directly from you in several places on our Services, as set forth generally below.</p>
                    <h1 className="font-bold text-3xl mt-4">Registration</h1>
                    <p>Currently, visitors to our Services can access some content without becoming a member. However, if you would like to download worksheets, engage in digital learning experiences, customize or personalize certain content, gain access to certain community features, or rate editorial content, you are required to register to become a member. To register, you must supply us with a valid email address and password; as well as some additional voluntary information about you (e.g., your "type": parent, teacher, or student; grade focus, etc.).</p>
                    <h1 className="font-bold text-3xl mt-4">Premium Services</h1>
                    <p>If you’d like access to one or more of our premium contents, you'll need to register for that offering, and you'll be asked to provide additional personal, billing, or other specified information that we'll need to charge for and activate the service or product.</p>
                    <h1 className="font-bold text-3xl mt-4">Public Forums: Blogs and Comments</h1>
                    <p>As a service to our users, we feature Blogs and Comments where users with similar interests can share information and support, send messages, provide a review of our products, and comment on editorial content. Information shared in Blogs and through Comments postings is accessible to the general public and may be viewed, disclosed to, or collected by third parties that do not adhere to our <a target="_blank" className="text-blue-600 underline" href="/terms-and-conditions">Terms of Use</a> or this Privacy Policy or that may use the information to send you unsolicited communications. In addition, we may retain this information as necessary to resolve disputes, provide customer support, and troubleshoot problems as permitted by law. You should think carefully before disclosing any personal information in any public forum.</p>
                    <h1 className="font-bold text-3xl mt-4">Surveys/Poll</h1>
                    <p>At certain times, you may be asked to participate in surveys, which are generally aimed at obtaining general information, such as your reactions to a particular article or feature set on our site. Because information obtained in these surveys is connected with individual subscribers and is used internally to improve our products or better market our services, participation is voluntary.</p>
                    <h1 className="font-bold text-3xl mt-4">Contests and Sweepstakes</h1>
                    <p>We sometimes offer contests or sweepstakes in which certain personal information is required for registration, for purposes of verifying the entrants’ identities and notifying the winners. If the contest or sweepstakes is being administered by a third party, this information will be disclosed to that third party. However, entrants may select contest names distinct from their User Names, and the information is not connected with their User Names.</p>
                    <h1 className="font-bold text-3xl mt-4">Other Information You Provide Through Our Services</h1>
                    <p>We will also collect any other information that you provide through our Services, such as your name, email address, job title, phone number, school or organization name, account type, payment type, and the contents of any message you submit through our Services.</p>
                    <h1 className="font-bold text-3xl mt-4">Information We Collect from Third Parties</h1>
                    <p>You can create an account with us, by logging in through certain third-party accounts, such as Facebook and Google. By logging in through a third-party account, you grant Niftyeducate.com permission to access certain information from that third-party account as explained on the permissions page when you log in, including your name, email, and public profile ID.</p>
                    <h1 className="font-bold text-3xl mt-4">Information We Collect Automatically</h1>
                    <p>We use various technologies to collect and store information, including cookies, pixel tags, and local storage, such as browser web storage or application data caches, databases, and server logs. This information may include, but is not limited to, account information; IP address; domain name; browser type and operating system; web pages viewed while logged in; links clicked; the length of time spent on our Services; and the referring URL, or the webpage that led a user to our Services. In addition, we will collect information about user activities within our Services, such as which games a user plays, the number of correct and incorrect answers, and download history.</p>
                    <h1 className="font-bold text-3xl mt-4">Cookies and Other Tracking Mechanisms</h1>
                    <b>If you are a user located in the United Kingdom (UK) or the European Economic Area (EEA), the only type of cookies we will set on your device is ‘strictly necessary cookies which are essential to provide you with our Website and our Services. You can block these cookies by changing your browser setting but as they are essential for the operation of our Website, this may impact the provision of our Services. For all other rest-of-world users, please see the sections below regarding the types of cookies we will set on your device.</b>
                    <h1 className="font-bold text-3xl mt-4">Our Tracking</h1>
                    <p>
                        Tracking data consists of both individual and aggregated tracking information and is automatically gathered using “cookies.” A cookie is a small data file containing information, such as a user’s member name, that is written to the user’s hard drive by a web server and used to track the pages visited.
                        We use cookies in several ways to track users’ navigation through our Services. First, the information we gather on an individual basis is used for internal purposes only, such as accessing a member profile. We also use tracking information on an aggregate basis, to track the usage of our products, analyze patterns in our Services, and provide anonymous reporting of usage for internal and external clients. In all cases, our cookies are encoded and contain a unique digital signature to prevent tampering. They do not contain user passwords. We also employ technology that is commonly referred to as “action tags” (also known as “web beacons” or “one-pixel .gif files”) to measure the effectiveness of our advertisements.
                        You can control your browser’s settings regarding cookies by selecting “Internet Options” or “Preferences” in the menu bar of your browser. This will allow you to prevent your browser from accepting new cookies, have your browser notify you when you receive a new cookie, or disable cookies altogether. However, because cookies allow you to easily navigate our Services without constantly logging in using your Username and password, we recommend you leave them turned on.
                    </p>
                    <h1 className="font-bold text-3xl mt-4">Tracking by our contractors and agents</h1>
                    <p>We use third-party online business optimization software (including Google Analytics) to assist us in capturing and analyzing customer usage information. These service providers act as limited agents to us, to provide their services. Any information obtained by such third parties from our users or us is and will remain our property, and will be subject to this privacy policy. We do not permit these service providers to review, share, distribute, print, or reference any session data of visitors to our Services, except as specified in our agreements with them or as may be required by law. To learn more about the privacy practices of such service providers, we encourage you to review the <a target='_blank' className="text-blue-600 underline" href="https://www.google.com/intl/en/policies/privacy/">Google Privacy Policy</a> and the <a target="_blank" className="text-blue-600 underline" href="https://www.marketingcloud.com/privacy-policy/">Salesforce Marketing Cloud Privacy Policy.</a></p>
                    <h1 className="font-bold text-3xl mt-4">Tracking by Our Advertisers</h1>
                    <p>
                        Our advertisers include a wide variety of companies, such as financial services companies (e.g., brokerage firms, banks, and mortgage companies), computer manufacturers, software companies, automobile makers, toy manufacturers, and retail stores. Our advertisers and advertising vendors display targeted advertisements. Although we do not provide any personal information to the advertiser or advertising vendor, by interacting with or clicking on an ad you consent to the possibility that the advertiser will assume you meet the criteria used to target the ad.
                        In addition to cookies placed by us or our agents, as described above, advertisers and advertising vendors that serve ads onto our Services or other services may use their cookies or other tracking technologies. We are not responsible for the privacy policies or practices of any such advertisers and vendors.
                    </p>
                    <h1 className="font-bold text-3xl mt-4">Do Not Track Disclosure</h1>
                    <p>Our Services do not respond to Do Not Track signals. For more information about Do Not Track signals, please click <a target="_blank" href="/#" className="text-blue-600 underline">here.</a></p>
                    <h1 className="font-bold text-3xl mt-8">Use of the Information</h1>
                    <p>We use your information, including personal information, as follows:</p>
                    <h1 className="font-bold text-2xl mt-4">Provide Our Services</h1>
                    <p>We use your information to provide and maintain our Services, including tailoring content to you; processing orders and payments; improving our Services; developing new features, products, or services; authenticating users; performing technical operations, such as updating software; and for other service and support-related purposes.</p>
                    <h1 className="font-bold text-2xl mt-4">Free Newsletters</h1>
                    <p>If you’re a registered member, we use your email address to send you free email newsletters containing selected editorial, educational content, advertisements, and links to our Website, Apps, and social media accounts. You can select which free newsletters you would like to receive or remove yourself from our free newsletter delivery list at any time by visiting your <a target='_blank' className="text-blue-600 underline" href="https://www.niftyeducate.com/my/email-preferences/">Email preferences page.</a></p>
                    <h1 className="font-bold text-2xl mt-4">Special Offers and Advertisements</h1>
                    <p>We may from time to time send registered members of Niftyeducate.com special offers and advertisements for products or services offered by us or third parties. We will not disclose any of your personal information to such third parties when we do this. If you do not wish to receive third-party offers and advertisements, please change your settings on your <a target='_blank' className="text-blue-600 underline" href="https://www.niftyeducate.com/my/email-preferences/">Email preferences page.</a></p>
                    <h1 className="font-bold text-2xl mt-4">Communications</h1>
                    <p>We use your information to communicate with you (including by email) about your use, or use by the children associated with your account, of our Services, including to send you product or service updates; respond to your inquiries; and for other informational, marketing, or promotional purposes.</p>
                    <h1 className="font-bold text-2xl mt-4">Webinars and Events</h1>
                    <p>If you register for a Niftyeducate.com webinar or event, we use your email address to send you notifications relevant to the webinar or event.</p>
                    <h1 className="font-bold text-2xl mt-4">Research and Analytics</h1>
                    <p>We use your information to analyze how users interact with our Services; to monitor and analyze usage and activity trends; and for other research, analytical, and statistical purposes.</p>
                    <h1 className="font-bold text-2xl mt-4">Protecting Rights and Interests</h1>
                    <p>We use your information to protect the safety, rights, property, or security of you, Niftyeducate.com, the Services, any third party, or the general public; to detect, prevent, or otherwise address fraud, security, or technical issues; to prevent or stop the activity that Niftyeducate.com, in its sole discretion, may consider to be, or to pose a risk of being, an illegal, unethical, or legally actionable activity; to use as evidence in litigation, and to enforce this Privacy Policy and our <a target="_blank" className="text-blue-600 underline" href="/terms-and-conditions">Terms of Use</a>.</p>
                    <h1 className="font-bold text-2xl mt-4">Legal Compliance</h1>
                    <p>We use your information to comply with applicable legal or regulatory obligations, including as part of a judicial proceeding; to respond to a subpoena, warrant, court order, or another legal process; or as part of an investigation or request, whether formal or informal, from law enforcement or a governmental authority.</p>
                    <h1 className="font-bold text-3xl mt-8">Choice and Opt-out</h1>
                    <h1 className="font-bold text-2xl mt-4">Opting Out of Marketing Communications</h1>
                    <p>
                        Members may opt-out of receiving updates, notifications, and/or newsletters by de-selecting the corresponding boxes on the<a target='_blank' className="text-blue-600 underline" href="https://www.niftyeducate.com/my/email-preferences/">Email preferences page</a>. Updates, newsletters, and email notifications will also include instructions for opting out of those communications.
                        You may access, modify, or delete the personal information that you have provided to us by logging into your member profile page or emailing us at <em className="text-blue-600 underline">hello@Niftyeducate.com</em>. If you request restrictions on our uses or disclosures of your personal information, we may comply with your request by terminating your membership and deleting your account information. Please note that we can only honor requests to out opt of disclosures on a going-forward basis and opt-out requests do not affect information that we have previously disclosed. We may also retain certain information about you as required by law or as permitted by law for legitimate business purposes. For example, if you request that we delete your information but we believe that you have violated our <a target="_blank" className="text-blue-600 underline" href="/terms-and-conditions">Terms of Use</a>, we may retain information about you to attempt to resolve the issue before deleting it.
                    </p>
                    <h1 className="font-bold text-3xl mt-8">Disclosures of Information</h1>
                    <p>We do not sell or disclose the personal information about you described above to nonaffiliated parties (including but not limited to companies, authorities, institutions, or individuals) except to provide you with products or services, when we have your permission, or under the circumstances listed below. We do not disclose your personal information to third parties for those third parties’ marketing purposes.</p>
                    <ul className="mt-4 ml-12 list-disc">
                        <li>With other users of our services to provide our Services, such as disclosing a child’s information with a parent, teacher, or school administrator so that the parent, teacher, or school administrator can monitor his/her child’s progress on our Services.</li>
                        <li>To nonaffiliated companies that we engage as contractors or agents to perform services for us, such as administering our website, systems, and software; sending direct mail communications, or administering sweepstakes or contests. We will disclose your information with such contractors only to the extent reasonably necessary for them to perform services on our behalf, consistent with this Privacy Policy;</li>
                        <li>In response to subpoenas, court orders, or legal processes, from law enforcement agencies or state and federal regulators, or as otherwise required by law;</li>
                        <li>To assert or defend our legal rights, including fraud prevention and enforcing this Privacy Policy and our <a target="_blank" className="text-blue-600 underline" href="/terms-and-conditions">Terms of Use</a>;</li>
                        <li>To protect the rights, property, or safety of us or other persons, including to detect, prevent, or otherwise address fraud, security, or technical issues, or anything else that we may consider to be, or pose a risk of being, an illegal, unethical, or legally actionable activity;</li>
                        <li>As we deem appropriate to attempt to prevent physical or emotional harm to other persons and/or to their property based on overt or implied threat;</li>
                        <li>In connection with an actual or proposed corporate merger, acquisition, asset purchase, or other transaction or proceeding involving all or part of the business or assets to which the information pertains; and</li>
                        <li>To other companies, such as direct marketers to perform marketing services on our behalf, or other institutions with whom we have joint marketing agreements.</li>
                    </ul>
                    <p className="mt-4">We may also disclose aggregate, anonymous, or de-identified information about users for marketing, advertising, research, compliance, or other purposes</p>
                    <h1 className="font-bold text-3xl mt-8">Security</h1>
                    <p>
                        We have implemented safeguards to protect the information we collect. Please be aware that despite our efforts, no data security measures can guarantee 100% security.
                        You should take steps to protect against unauthorized access to your password, phone, and computer by, among other things, signing off after using a shared computer, choosing a robust password that nobody else knows or can easily guess, and keeping your log-in and password private. We are not responsible for any lost, stolen, or compromised passwords or for any activity on your account via unauthorized password activity.
                    </p>
                    <h1 className="font-bold text-3xl mt-8">Changes in this Privacy Policy</h1>
                    <p>
                        If we decide to change this Privacy Policy, we will post changes to our Website.
                        We reserve the right to modify this Privacy Policy at any time, so please review it before disclosing personal information on one of our Services. If we make changes that materially affect our uses or disclosures of personal information that we have previously collected from you, we will provide a mechanism for you to opt-out of the new use or disclosure.
                    </p>
                    <h1 className="font-bold text-3xl mt-8">Your Independent Relationship with Other Companies</h1>
                    <p>
                        Our Services may contain links to third-party websites and applications. Please note that this Privacy Policy will not apply to your independent relationships with other companies that from time to time may offer you products and services or are otherwise linked to our Services. Those companies’ privacy policies will govern how they collect, use and disclose personal information that you provide to them.
                        In addition, some of our advertising partners will on occasion take polls of our visitors, in which case you would provide your information directly to the advertising partner. Such polls are for use by the advertising partner, and participation is voluntary. We do not assure the privacy of information voluntarily provided in response to a survey or poll.
                    </p>
                    <h1 className="font-bold text-3xl mt-8">Contact Us</h1>
                    <p>Niftyeducate.com, operates the Services. Questions or comments regarding this policy should be directed to <em className="text-blue-600 underline">hello@Niftyeducate.com</em>, or by writing to Jalan Gotong Royong No. 101, Kelurahan Ngesrep, Kecamatan Banyumanik, Kota Semarang, Jasa Tengah-Indonesia, 50262.</p>
                </div>

            </section>
        </LayoutWeb>
    )
}