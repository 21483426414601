import React, {useEffect} from 'react'
import LayoutWeb from '../../../layouts/Web'
import { BsCheck } from 'react-icons/bs'
import {Link} from 'react-router-dom'
export default function HowItWork() {
  useEffect(() => {
    window.scrollTo(0, 0);

  }, []);
    
  return (
    <div>
          <LayoutWeb>
          <section className="mt-28 w-full mx-auto lg:max-w-7xl">
            <p className="text-sm px-0 lg:px-10 my-6 ml-6 text-[#033050] font-semibold mb-11"><Link to="/" className="text-[#033050]" >Home</Link> / How it works</p>
            <div className="flex flex-col Nunito mb-14">
              <h5 className="text-center text-4xl font-semibold text-[#033050]">How it Works</h5>
              <div className="flex flex-col gap-y-14 mt-8">
                <section className="flex flex-col lg:flex-row justify-evenly gap-y-4 md:gap-y-0">
                  <div className="flex flex-col text-center gap-y-4">
                    <section>
                      {/* <p className="text-sm px-3.5 py-2 rounded-full w-9 lg:translate-y-8 sm:translate-y-10 sm:translate-x-60 translate-x-48 translate-y-10 md:translate-y-10 md:translate-x-80 lg:translate-x-8 bg-[#033050] text-white">1</p> */}
                      <img className='mx-auto w-40' src="/img/how-1.png" alt="" />
                    </section>
                    <p className="text-sm">Go to niftyeducate.com and subscribe<br />to access 10,000+ Education Materials<br />for Kids</p>
                  </div>
                  <div className="flex flex-col text-center gap-y-4">
                    <section>
                      {/* <p className="text-sm px-3.5 py-2 rounded-full w-9 lg:translate-y-8 sm:translate-y-10 sm:translate-x-60 translate-x-48 translate-y-10 md:translate-y-10 md:translate-x-80 lg:translate-x-8 bg-[#033050] text-white">2</p> */}
                      <img className='mx-auto' src="/img/how-2.png" alt="" />
                    </section>
                    <p className="text-sm">Learn and Play with your kids, they<br />deserve to be loved</p>
                  </div>
                  <div className="flex flex-col text-center gap-y-4">
                    <section>
                      {/* <p className="text-sm px-3.5 py-2 rounded-full w-9 lg:translate-y-8 sm:translate-y-10 sm:translate-x-60 translate-x-48 translate-y-10 md:translate-y-10 md:translate-x-80 lg:translate-x-8 bg-[#033050] text-white">3</p> */}
                      <img className='mx-auto' src="/img/how-3.png" alt="" />
                    </section>
                    <p className="text-sm">Get Reward Points and continue what<br />you have done. Consistency is the key</p>
                  </div>
                </section>
                <section className="flex flex-col lg:flex-row justify-center gap-x-20 gap-y-4 md:gap-y-0">
                  <div className="flex flex-col text-center gap-y-4">
                    <section>
                      {/* <p className="text-sm px-3.5 py-2 rounded-full w-9 lg:translate-y-8 sm:translate-y-10 sm:translate-x-60 translate-x-48 translate-y-10 md:translate-y-10 md:translate-x-80 lg:translate-x-8 bg-[#033050] text-white">4</p> */}
                      <img className='mx-auto' src="/img/how-4.png" alt="" />
                    </section>
                    <p className="text-sm">You can exchange your reward points<br />with Collectible, NFT, and Token</p>
                  </div>
                  <div className="flex flex-col text-center gap-y-4">
                    <section>
                      {/* <p className="text-sm px-3.5 py-2 rounded-full w-9 lg:translate-y-8 sm:translate-y-10 sm:translate-x-60 translate-x-48 translate-y-10 md:translate-y-10 md:translate-x-80 lg:translate-x-8 bg-[#033050] text-white">5</p> */}
                      <img className='mx-auto' src="/img/how-5.png" alt="" />
                    </section>
                    <p className="text-sm">Buy NFT to boost your reward points</p>
                  </div>
                </section>
              </div>
            </div>
            <section className="mx-auto w-full">
                <div className='hidden lg:block h-96 w-full relative md:overflow-hidden '>
                  <img src="/img/Untitled_Artwork 13@2x.png" alt="Avatar" className="bg-cover object-center bg-center h-[40rem] w-full lg:h-full"/>
                  <section className="absolute container px-11 xl:w-full bottom-7 md:bottom-11">
                    {/* website version */}
                    <ul class="hidden md:flex w-full text-[#005a98]">
                      {/* 1 */}
                      <li class="relative mb-6 sm:mb-0">
                          <div class="flex items-center">
                              <div class="w-52 rounded-full py-2 bg-[#005A98]">
                                <h5 className='text-sm text-white font-extrabold text-center'>Jan - Jul 2022</h5>
                              </div>
                              <div class="hidden sm:flex w-full bg-[#005A98] h-0.5"></div>
                          </div>
                          <div className="bg-white mt-2 shadow-md w-fit px-4 py-2 rounded-xl space-y-2">
                            <div className="flex items-start gap-x-1">
                              <span>•</span>
                              <p className="text-[#005a98] text-sm">Website and education<br /> material development.</p>
                            </div>
                            <div className="flex items-start gap-x-1">
                              <span>•</span>
                              <p className="text-[#005a98] text-sm">Users able to subscribe,<br />download, and learn<br />education material</p>
                            </div>
                          </div>
                      </li>
                      {/* 2 */}
                      <li class="relative flex flex-col-reverse lg:-translate-y-[8.25rem]">
                          <div class="flex items-center">
                              <div class="w-52 rounded-full py-2 bg-[#005A98]">
                                <h5 className='text-sm text-white font-extrabold text-center'>Aug 2022</h5>
                              </div>
                              <div class="hidden sm:flex w-full bg-[#005A98] h-0.5"></div>
                          </div>
                          <div className="bg-white mb-2 shadow-md w-fit px-4 py-2 rounded-xl space-y-2">
                            <p className="text-sm text-[#005a98]">Alpha version of gamification<br />concept nifty vision and<br />economic model released</p>
                          </div>
                      </li>
                      {/* 3 */}
                      <li class="relative mb-6 sm:mb-0">
                          <div class="flex items-center">
                              <div class="w-52 rounded-full py-2 bg-[#005A98]">
                                <h5 className='text-sm text-white font-extrabold text-center'>Sept 2022</h5>
                              </div>
                              <div class="hidden sm:flex w-full bg-[#005A98] h-0.5"></div>
                          </div>
                          <div className="bg-white mt-2 shadow-md w-fit px-4 py-2 rounded-xl space-y-2">
                            <div className="flex items-start gap-x-1">
                              <span>•</span>
                              <p className="text-[#005a98] text-sm">Closed beta version release<br />(learn to earn concept)</p>
                            </div>
                            <div className="flex items-start gap-x-1">
                              <span>•</span>
                              <p className="text-[#005a98] text-sm">Whitepaper release</p>
                            </div>
                          </div>
                      </li>
                      {/* 4 */}
                      <li class="relative flex flex-col-reverse -translate-y-[8.25rem]">
                          <div class="flex items-center">
                              <div class="w-52 rounded-full py-2 bg-[#005A98]">
                                <h5 className='text-sm text-white font-extrabold text-center'>Oct 2022</h5>
                              </div>
                              <div class="hidden sm:flex w-full bg-[#005A98] h-0.5"></div>
                          </div>
                          <div className="bg-white mb-2 shadow-md w-fit px-4 py-2 rounded-xl space-y-2">
                            <div className="flex items-start gap-x-1">
                              <span>•</span>
                              <p className="text-[#005a98] text-sm">NFT sales release.</p>
                            </div>
                            <div className="flex items-start gap-x-1">
                              <span>•</span>
                              <p className="text-[#005a98] text-sm">Running learn to<br />earn mechanism</p>
                            </div>
                          </div>
                      </li>
                      {/* 5 */}
                      <li class="relative mb-6 sm:mb-0">
                          <div class="flex items-center">
                              <div class="w-52 rounded-full py-2 bg-[#005A98]">
                                <h5 className='text-sm text-white font-extrabold text-center'>Dec 2022</h5>
                              </div>
                              <div class="hidden sm:flex w-full bg-[#005A98] h-0.5"></div>
                          </div>
                          <div className="bg-white mt-2 shadow-md w-48 px-4 py-2 rounded-xl space-y-2">
                            <p className="text-[#005a98] text-sm">Scholarships concept released.</p>
                          </div>
                      </li>
                      {/* 6 */}
                      <li class="relative flex flex-col-reverse lg:-translate-y-[8.25rem]">
                          <div class="flex items-center">
                              <div class="w-[6.5rem] rounded-full py-2 bg-[#005A98]">
                                <h5 className='text-sm text-white font-extrabold text-center'>2023 Q1</h5>
                              </div>
                          </div>
                          <div className="bg-white mb-2 shadow-md w-fit px-4 py-2 rounded-xl space-y-2">
                            <p className="text-[#005a98] text-sm">NFT breeding.</p>
                          </div>
                      </li>
                    </ul>
                  </section>
                </div>
                <div className="w-full h-[90rem] md:h-auto block lg:hidden p-4 object-cover bg-no-repeat" style={{ backgroundImage: "url('/img/Untitled_Artwork 13@2x.png')" }}>
                  <h5 className="text-3xl font-extrabold mt-8 text-center text-white">Timeline</h5>
                  <section className="space-y-8">
                    {/* 1 */}
                    <div className="flex flex-col mt-8">
                      <div className="h-auto bg-[#005a98] z-10 text-white w-36 mx-auto p-2 rounded-full">
                        <h5 className="text-base font-extrabold text-center">Jan - Jul 2022</h5>
                      </div>
                      <div className="h-auto bg-white drop-shadow-md z-0 relative -mt-4 w-80 mx-auto pb-4 px-4 pt-8 rounded-xl">
                        <h5 className="text-base font-normal text-[#005a98]">• Website and education material <span className='ml-3'>development</span></h5>
                        <h5 className="text-base font-normal text-[#005a98]">• Users able to subscribe, download, <span className='ml-3'>and learn education material</span></h5>
                      </div>
                    </div>
                    {/* 2 */}
                    <div className="flex flex-col">
                      <div className="h-auto bg-[#005a98] z-10 text-white w-36 mx-auto p-2 rounded-full">
                        <h5 className="text-base font-extrabold text-center">Aug 2022</h5>
                      </div>
                      <div className="h-auto bg-white drop-shadow-md z-0 relative -mt-4 w-80 mx-auto pb-4 px-4 pt-8 rounded-xl">
                        <h5 className="text-base font-normal text-[#005a98]">Alpha version of gamification concept nifty vision and economic model released</h5>
                      </div>
                    </div>
                    {/* 3 */}
                    <div className="flex flex-col">
                      <div className="h-auto bg-[#005a98] z-10 text-white w-36 mx-auto p-2 rounded-full">
                        <h5 className="text-base font-extrabold text-center">Sept 2022</h5>
                      </div>
                      <div className="h-auto bg-white drop-shadow-md z-0 relative -mt-4 w-80 mx-auto pb-4 px-4 pt-8 rounded-xl">
                        <h5 className="text-base font-normal text-[#005a98]">• Close beta version release (learn to <span className='ml-3'>earn concept)</span></h5>
                        <h5 className="text-base font-normal text-[#005a98]">• Whitepaper release</h5>
                      </div>
                    </div>
                    {/* 4 */}
                    <div className="flex flex-col">
                      <div className="h-auto bg-[#005a98] z-10 text-white w-36 mx-auto p-2 rounded-full">
                        <h5 className="text-base font-extrabold text-center">Oct 2022</h5>
                      </div>
                      <div className="h-auto bg-white drop-shadow-md z-0 relative -mt-4 w-80 mx-auto pb-4 px-4 pt-8 rounded-xl">
                        <h5 className="text-base font-normal text-[#005a98]">• NFT sales release</h5>
                        <h5 className="text-base font-normal text-[#005a98]">• Running learn to earn mechanism</h5>
                      </div>
                    </div>
                    {/* 5 */}
                    <div className="flex flex-col">
                      <div className="h-auto bg-[#005a98] z-10 text-white w-36 mx-auto p-2 rounded-full">
                        <h5 className="text-base font-extrabold text-center">Dec 2022</h5>
                      </div>
                      <div className="h-auto bg-white drop-shadow-md z-0 relative -mt-4 w-80 mx-auto pb-4 px-4 pt-8 rounded-xl">
                        <h5 className="text-base font-normal text-[#005a98]">Scholarship concept release</h5>
                      </div>
                    </div>
                    {/* 6 */}
                    <div className="flex flex-col">
                      <div className="h-auto bg-[#005a98] z-10 text-white w-36 mx-auto p-2 rounded-full">
                        <h5 className="text-base font-extrabold text-center">2023 Q1</h5>
                      </div>
                      <div className="h-auto bg-white drop-shadow-md z-0 relative -mt-4 w-80 mx-auto pb-4 px-4 pt-8 rounded-xl">
                        <h5 className="text-base font-normal text-[#005a98]">NFT breeding</h5>
                      </div>
                    </div>
                  </section>
                </div>
            </section>
            {/* table */}
            <section className="hidden md:block mx-auto md:mt-24">
              <h5 className="text-4xl font-extrabold Nunito mb-11 text-[#033050] text-center">3 Ways to Benefit from Nifty Educate</h5>
              <table class="table-auto text-[#033050] mx-auto text-left ring-1 rounded-xl w-7/12" style={{ borderRadius: "1rem !important" }}>
                <thead>
                  <tr>
                    <th className='p-4 rounded-tl-xl bg-blue-100'>Benefit</th>
                    <th className='p-4 bg-blue-100'>Download <br /> & Learn</th>
                    <th className='p-4 bg-blue-100'>Receive <br /> Scholarship</th>
                    <th className='p-4 rounded-tr-xl bg-blue-100'>Buy NFT <br /> Character</th>
                  </tr>
                </thead>
                <tbody className=' divide-y divide-blue-200 font-extrabold'>
                  <tr>
                    <td className='p-4'>Earn while you learn with your kids</td>
                    <td className='p-4'><BsCheck/></td>
                    <td className='p-4'><BsCheck/></td>
                    <td className='p-4'><BsCheck/></td>
                  </tr>
                  <tr>
                    <td className='p-4'>Point Rewards</td>
                    <td className='p-4'><BsCheck/></td>
                    <td className='p-4'><BsCheck/></td>
                    <td className='p-4'><BsCheck/></td>
                  </tr>
                  <tr>
                    <td className='p-4'>NFT Breeding</td>
                    <td className='p-4'></td>
                    <td className='p-4'></td>
                    <td className='p-4'><BsCheck/></td>
                  </tr>
                  <tr>
                    <td className='p-4'>Trade Characters on Marketplace</td>
                    <td className='p-4'></td>
                    <td className='p-4'></td>
                    <td className='p-4'><BsCheck/></td>
                  </tr>
                </tbody>
              </table>
            </section>
            <section className="block md:hidden sm:overflow-x-hidden -mt-72 px-11 mb-10 md:mb-0">
              <h5 className="text-4xl leading-snug font-extrabold Nunito mb-6 text-[#033050] text-center">3 Ways to Benefit from Nifty Educate</h5>
              <div className="w-full overflow-x-auto block md:hidden">
                <table class="table-auto text-[#033050] mx-auto text-left ring-1 rounded-xl" style={{ borderRadius: "1rem !important" }}>
                  <thead>
                    <tr>
                      <th className='rounded-tl-xl p-4 bg-blue-100'>Benefit</th>
                      <th className='p-4 bg-blue-100'>Download <br /> & Learn</th>
                      <th className='p-4 bg-blue-100'>Receive <br /> Scholarship</th>
                      <th className='rounded-tr-xl p-4 bg-blue-100'>Buy NFT <br /> Character</th>
                    </tr>
                  </thead>
                  <tbody className=' divide-y divide-blue-200 font-extrabold'>
                    <tr>
                      <td className='p-4'>Earn while you learn with your kids</td>
                      <td className='p-4'><BsCheck/></td>
                      <td className='p-4'><BsCheck/></td>
                      <td className='p-4'><BsCheck/></td>
                    </tr>
                    <tr>
                      <td className='p-4'>Point Rewards</td>
                      <td className='p-4'><BsCheck/></td>
                      <td className='p-4'><BsCheck/></td>
                      <td className='p-4'><BsCheck/></td>
                    </tr>
                    <tr>
                      <td className='p-4'>NFT Breeding</td>
                      <td className='p-4'></td>
                      <td className='p-4'></td>
                      <td className='p-4'><BsCheck/></td>
                    </tr>
                    <tr>
                      <td className='p-4'>Trade Characters on Marketplace</td>
                      <td className='p-4'></td>
                      <td className='p-4'></td>
                      <td className='p-4'><BsCheck/></td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </section>
          </section>
          </LayoutWeb>
    </div>
  )
}
