import React, { useState, useEffect, lazy, Suspense } from 'react'
import LayoutWeb from '../../../layouts/Web'
import SideBar from '../../../components/particles/SideBar'
import { MdModeEditOutline } from 'react-icons/md'
import Api from '../../../api'
import Cookies from "js-cookie";
import toast from "react-hot-toast";
import Subscribe from '../../../components/utilities/CardSubscribe'
import { BiLoaderAlt, BiRightArrowAlt } from 'react-icons/bi';
import { SkeletonMySub } from '../../../components/utilities/Skeleton'
// import { SkeletonSubscription } from '../../../components/utilities/Skeleton';
import { AiOutlineClose } from 'react-icons/ai'
import { Link } from 'react-router-dom'


const MySubscriptionCard = lazy(() => import('../../../components/utilities/MySubscriptionCard'))

export default function Subscription() {

  const [subscriptions, setSubscriptions] = useState({});
  const [couponList, setCouponList] = useState([])
  const [hideNotif, setHideNotif] = useState(true)
  const [receipt, setReceipt] = useState({});
  const [history, setHistory] = useState([]);
  const [expired_date, setExpiredDate] = useState("");
  const [loading, setLoading] = useState(false)
  const [loadingSub, setLoadingSub] = useState(true)
  const token = Cookies.get("token");
  const [userProfile, setUserProfile] = useState({});
  const [payment_id, setPaymentId] = useState({})
  const [couponRec, setCouponRec] = useState(false);
  const [confirmCancel, setConfirmCancel] = useState(false);
  const [stopSub, setStopSub] = useState('')

  const getCancelPaymentInfo = async (e) => {
    await Api.get("/cancelsubscription", {
      headers: {
        //header Bearer + Token
        Authorization: `Bearer ${token}`,
      }
    })
      .then((response) => {
        setCouponList(response.data.data.coupon_recommendation);
      })
  }

  const postUnsubConfirm = async (e) => {
    e.preventDefault();
    setLoading(true)
    const formData = new FormData();
    formData.append('id', payment_id);
    formData.append('stop_code', stopSub);
    await Api.post("cancelpayment", formData, {
      headers: {
        Authorization: `Bearer ${token}`,
      }
    }).then((response) => {

      toast.success("Unsubscribed", {
        duration: 4000,
        position: "top-right",
        style: {
          borderRadius: '10px',
          background: '#333',
          color: '#fff',
        },
      });
    }).finally(() => {
      setLoading(false);
      resetAct();
      getSubscription();
    })
  }



  const getSubscription = async () => {
    await Api.get("/mysubscription", {
      headers: {
        //header Bearer + Token
        Authorization: `Bearer ${token}`,
      }
    })
      .then((response) => {
      
        setSubscriptions(response.data.data.subscription)
        setReceipt(response.data.data.receipt)
        setExpiredDate(response.data.data.expired_date)
        setHistory(response.data.data.history)
        if (response.data.data.subscription) {
          setPaymentId(response.data.data.subscription.payment_id)
        }
      }).finally(setLoadingSub(false))
  }
  const getProfile = async () => {
    await Api.get("/account", {
      headers: {
        //header Bearer + Token
        Authorization: `Bearer ${token}`,
      }
    })
      .then((response) => {
        setUserProfile(response.data.data)
      });
  }
  const confirmCancelAct = async () => {
    setConfirmCancel(true);
    setCouponRec(false);
  }

  const resetAct = async () => {
    setConfirmCancel(false);
    setCouponRec(false);
    setHideNotif(true);
  }

  const showCoupon = async () => {
    setCouponRec(true)
    getCancelPaymentInfo()
  }

  useEffect(() => {
    setLoadingSub(true);
    getSubscription()
    getProfile()
    setLoadingSub(false);
    window.scrollTo(0, 0);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    
    <>
      <LayoutWeb>
        <div hidden={hideNotif} className="fixed h-screen bg-black/40 inset-x-0 top-0 z-50 mx-auto overflow-y-scroll">
          {couponRec ?
            (<div className="flex flex-col bg-white drop-shadow-lg lg:w-[32rem] mx-auto w-72 mt-28 p-6 rounded-xl shadow-md">
              <button onClick={resetAct} className="rounded-lg ml-auto mb-6 text-[#005A98] text-center w-4 h-4"><AiOutlineClose /></button>
              <section>
                <h5 className="text-4xl text-[#033050] text-center font-semibold">My Coupons</h5>
                <section className='md:my-6 my-2'>
                  {couponList ? (
                    couponList.map((item) =>
                      <div className=' mx-auto border border-gray-300 rounded-lg p-4 h-52 md:h-64 w-[90%]'>
                        <div className='text-black'>
                          <p className='font-extrabold text-[36px]'>{item.description} OFF</p>
                          <p className='text-[28px]'>{item.name}</p>
                          <div className="my-4 flex items-center justify-between">
                            <p className='font-bold text-[#163ED4] text-[14px] md:text-[28px]'>CODE: {item.code}</p>
                            <Link to={`/payment/${item.id}`} className='flex flex-row items-center'><BiRightArrowAlt className='h-6 w-6' /><p className='font-bold'>Apply</p></Link>
                          </div>
                          <ul className='mb-2 text-gray-500 text-[14px] hidden md:block list-disc ml-2'>
                            <li>You may copy the code and apply it on the coupon section</li>
                          </ul>
                        </div>
                      </div>))
                    : (
                      <div>Server Error, List is Empty</div>
                    )
                  }
                </section>
                <div className='mt-0 flex md:justify-between md:items-center md:flex-row flex-col'>
                  <button onClick={confirmCancelAct} className='mt-3 rounded-full bg-gray-400 transform transition text-white text-center px-3 py-2 md:w-[45%] w-full text-sm font-bold'>Cancel Subscription</button>
                  <button onClick={resetAct} className="mt-3 rounded-full bg-[#005A98] transform transition hover:shadow-[#005A98] hover:shadow-sm text-white text-center px-3 py-2 w-full md:w-[45%] text-sm font-bold">Keep Plan</button>
                </div>
              </section>
            </div>
            ) : confirmCancel ?
              (
                <div className="flex flex-col bg-white drop-shadow-lg lg:w-[32rem] mx-auto w-72 mt-28 p-6 rounded-xl shadow-md">
                  <button onClick={resetAct} className="rounded-lg ml-auto mb-6 text-[#005A98] text-center w-4 h-4"><AiOutlineClose /></button>
                  <section>
                    <h5 className="text-3xl text-[#005A98] text-center Nunito font-bold">Are you absolutely sure?</h5>
                    <p className="mt-4 text-sm text-center text-gray-500">This will stop your current plan and remove all privileges of your subscribed account</p>
                    <p className="mt-4 text-sm text-center text-gray-500">please type <b className='font-bold text-black'>STOPSUBSCRIPTION</b> to continue</p>
                    <form onSubmit={postUnsubConfirm} className='mt-2'>
                      <input type="text" style={{ paddingLeft: '1rem' }} className="bg-none mt-2 w-full px-2 border-2 text-center border-[#B0D4ED] py-1 rounded-full focus:outline-none" value={stopSub} onChange={(e) => setStopSub(e.target.value)} />
                      <button disabled={loading} type='submit' className='mt-2 lg:mt-5 rounded-full bg-gray-400 transform transition text-white text-center px-3 py-2 w-full text-sm font-bold'>{loading ? <div className='w-full px-4 mx-[33%] flex flex-auto'><BiLoaderAlt className='w-4 h-4 animate-spin' /><span>Loading...</span></div> : 'Cancel Subscription'}</button>
                    </form>
                  </section>
                </div>) :
              (
                <div className="flex flex-col bg-white drop-shadow-lg lg:w-[32rem] mx-auto w-72 mt-28 p-6 rounded-xl shadow-md">
                  <button onClick={resetAct} className="rounded-lg ml-auto mb-6 text-[#005A98] text-center w-4 h-4"><AiOutlineClose /></button>
                  <section>
                    <h5 className="text-4xl text-[#005A98] text-center Nunito font-bold">Are you sure?</h5>
                    <p className="mt-4 text-sm">If you cancel your subscription now, you can still access it until it expires</p>
                    <div className='mt-4 flex flex-col lg:flex-row justify-between items-center lg:gap-x-4'>
                      <button onClick={showCoupon} className='mt-2 lg:mt-5 rounded-full bg-gray-400 transform transition text-white text-center px-3 py-2 w-full text-sm font-bold'>Cancel Subscription</button>
                      <button onClick={() => setHideNotif(true)} className="mt-5 rounded-full bg-[#005A98] transform transition hover:shadow-[#005A98] hover:shadow-sm text-white text-center px-3 py-2 w-full text-sm font-bold">Keep Plan</button>
                    </div>
                  </section>
                </div>
              )}
        </div>
        <section className="max-w-7xl mx-auto">
          <p className="text-sm mt-28 mb-8 ml-12 md:ml-12 font-semibold text-[#033050]"><Link to="/" className='text-[#033050]'>Home</Link> / My Subscription</p>
          <div className="px-10 lg:px-12 flex mx-auto flex-col md:flex-row md:justify-between">
            <SideBar />
            <Suspense fallback={<SkeletonMySub />}>
              <MySubscriptionCard subscriptions={subscriptions} expired_date={expired_date} loading={loading} history={history} setHideNotif={setHideNotif} />
            </Suspense>
          </div>
        </section>
      </LayoutWeb>
    </>
  )
}
