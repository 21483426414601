import React,{useState,useEffect} from 'react'
import { MdModeEditOutline } from 'react-icons/md'
import { Transition } from '@headlessui/react'
import { AiOutlineClose } from 'react-icons/ai'
import SideBar from '../../../components/particles/SideBar'
import LayoutWeb from '../../../layouts/Web'
import Api from '../../../api'
import Cookies from "js-cookie";
import toast from "react-hot-toast";
import { BiLoaderAlt } from 'react-icons/bi'
import {Link} from 'react-router-dom'

export default function Account() {

  const [grade, setGrade] = useState('')
  const [show, setShow] = useState(false);
  const [isShow, setIsShow] = useState(false);
  
  const [child_name, setChildName] = useState('');
  const [grade_id, setGradeId] = useState('');
  const [child_dob, setChildDob] = useState('');
  const [phone, setPhone] = useState('');
  const [address, setAddress] = useState('');
  const [userProfile, setUserProfile] = useState({});
  const [userGrades, setUserGrades] = useState({});
  const [userPoint, setUserPoint] = useState('')

  const [current_password, setCurrPassword] = useState('');
  const [password, setPassword] = useState('');
  const [confirm_password, setConfirmPassword] = useState('');

  const [isLoadingPassword, setLoadingPassword] = useState(false);
  const [isLoadingInformation, setLoadingInformation] = useState(false);
  const [validationPassword, setValidationPassword] = useState({});
  const [validationInformation, setValidationInformation] = useState({});

    // modal change password
    const openPop = () => setShow(true);
    const closePop = () => setShow(false);
  
    // modal Edit Information
    const openUp = () => setIsShow(true);
    const closedUp = () => setIsShow(false);
    const token = Cookies.get("token");
    const getProfile = async () => {
    
        await Api.get("/account", {
            headers: {
                //header Bearer + Token
                Authorization: `Bearer ${token}`,
            }
        })
          .then((response) => {
            setPhone(response.data.data.data.phone)
            setAddress(response.data.data.data.address)
            setUserProfile(response.data.data.data)
            setChildName(response.data.data.data.child_name)
            setGradeId(response.data.data.data.grade_id)
            setChildDob(response.data.data.data.child_dob)
            setUserGrades(response.data.data.data.grades)
            setUserPoint(response.data.data.user_points)
          });
      }

      const changePassword = async(e) => {
        e.preventDefault()
          const formData = new FormData();
          formData.append('current_password', current_password);
          formData.append('password', password);
          formData.append('confirm_password', confirm_password);
          setLoadingPassword(true);
            //loading
          await Api.post("/changepwd",formData,{
            headers: {
                //header Bearer + Token
                Authorization: `Bearer ${token}`,
            }
        })
          .then((response) => {
            setLoadingPassword(false);
              setShow(false);
              toast.success("Change password Successfully.", {
                    duration: 1000,
                    position: "top-right",
                    style: {
                        borderRadius: '10px',
                        background: '#333',
                        color: '#fff',
                    },
                });

              
          }).catch((error) => {
              setLoadingPassword(false);
              setValidationPassword(error.response.data);
            
          });
        }
    
    
    
      const editProfileInformation = async(e) => {
        e.preventDefault()
        setLoadingInformation(true);
        const formData = new FormData();
          formData.append('child_name', child_name);
          formData.append('grade_id', grade_id);
          formData.append('child_dob', child_dob);
          formData.append('address', address);
          formData.append('phone', phone);
    
          await Api.post("/update-account",formData,{
            headers: {
                //header Bearer + Token
                Authorization: `Bearer ${token}`,
            }
        }).then((response) => {
            
              setLoadingInformation(false);
              setIsShow(false);
              toast.success("update Personal Information Successfully.", {
                duration: 1000,
                position: "top-right",
                style: {
                    borderRadius: '10px',
                    background: '#333',
                    color: '#fff',
                },
            });
            Cookies.set("name", child_name)
              getProfile()
          }).catch((error) => {
                // notif
              setLoadingInformation(false);
              setValidationInformation(error.response.data);
          });
      }
      const getGrades = async () => {
        try {
            let res = await Api.get("/grades")
            if (res) {
                setGrade(res.data.data) 
            }
        }
        catch (err) {
        }
      }

      useEffect(() => {
        window.scrollTo(0, 0);
        getProfile()
        getGrades()
      },[])
    




  return (
    <>
      <LayoutWeb>
        <section className="max-w-7xl mx-auto">
        <p className="ml-12 md:ml-12 lg:ml-12 text-sm mt-28 mb-8 font-semibold text-[#033050]"><Link to="/" className='text-[#033050]'>Home</Link> / My Account</p>
          <div className="px-10 container mx-auto lg:max-w-7xl lg:px-12 flex flex-col md:flex-row md:justify-between">
          <SideBar/>
              <section className='w-full md:w-3/4 float-right'>
              <div className="flex mb-6 justify-between bg-[#005a98] p-4 rounded-xl shadow-md">
                  <h5 className='text-lg font-semibold text-white'>Hi, {userProfile.child_name}</h5>
                  <h5 className='text-lg font-semibold text-yellow-400'>{userPoint} Pts</h5>
              </div>
              <div className="flex gap-x-6 gap-y-4 md:gap-y-0 flex-col md:flex-row justify-between items-start">
                  {/* spacer */}
                  <section className='bg-white p-4 rounded-xl shadow-md w-full md:w-1/2'>
                  <div className="flex items-center justify-between mb-6">
                      <h5 className='text-sm font-extrabold text-[#033050]'>Change password</h5>
                      <button className="cursor-pointer" onClick={openPop}>
                      <MdModeEditOutline className='w-4 h-4 text-[#005a98]'/>
                      </button>
                  </div>
                  {/* Account Information start */}
                  <Transition className="fixed h-screen bg-black/40 inset-x-0 top-0 z-50 mx-auto bg-scroll" show={show} enter="transition-opacity duration-75" enterFrom="opacity-0" enterTo="opacity-100" leave="transition-opacity duration-150" leaveFrom="opacity-100" leaveTo="opacity-0">
                      <div className="mx-auto md:w-96 w-[90vw] mt-32 bg-white p-6 rounded-xl shadow-md">
                          <button className="cursor-pointer float-right" onClick={closePop}>
                          <AiOutlineClose className='w-4 h-4 text-[#005a98]'/>
                          </button>
                          <h5 className="text-xl mt-3 font-medium">Change Password</h5>
                          
                          <form onSubmit={changePassword}>
                              <div className="mt-6 space-y-3">
                              <section className="gap-y-1 flex flex-col">
                                  <label htmlFor="opass" className="text-sm font-medium text-[#033050]">Old Password</label>
                                  <input value={current_password} onChange={(e) => setCurrPassword(e.target.value)} type="password" style={{ paddingLeft:'1rem' }} className="bg-none border-2 border-[#B0D4ED] py-1 focus:outline-none rounded-full" placeholder="Old Password" />
                              </section>
                              {validationPassword.current_password && (
                                      <div className="alert alert-danger text-red-500">
                                          {validationPassword.current_password[0]}
                                      </div>
                                  )}
                                  {validationPassword.message && (
                                      <div className="alert alert-danger text-red-500">
                                          {validationPassword.message}
                                      </div>
                                  )}
                              <section className="gap-y-1 flex flex-col">
                                  <label htmlFor="npass" className="text-sm font-medium text-[#033050]">New Password</label>
                                  <input value={password} onChange={(e) => setPassword(e.target.value)} type="password" style={{ paddingLeft:'1rem' }} className="bg-none border-2 border-[#B0D4ED] py-1 focus:outline-none rounded-full" placeholder="New Password" />
                              </section>
                              {validationPassword.password && (
                                      <div className="alert alert-danger text-red-500">
                                          {validationPassword.password[0]}
                                      </div>
                                  )}
                              <section className="gap-y-1 flex flex-col">
                                  <label htmlFor="cpass" className="text-sm font-medium text-[#033050]">Confirm Password</label>
                                  <input value={confirm_password} onChange={(e) => setConfirmPassword(e.target.value)} type="password" style={{ paddingLeft:'1rem' }} className="bg-none border-2 border-[#B0D4ED] py-1 focus:outline-none rounded-full" placeholder="Confirm Password" />
                              </section>
                              {validationPassword.confirm_password && (
                                      <div className="alert alert-danger text-red-500">
                                          {validationPassword.confirm_password[0]}
                                      </div>
                                  )}
                              <button type='submit' className="mt-6 bg-[#005a98] hover:bg-orange-600 py-2 w-full text-center text-white rounded-full" disabled={isLoadingPassword}> {isLoadingPassword ? <div className='flex flex-row ml-[130px]'><BiLoaderAlt className='w-4 h-4 animate-spin mt-[3px] mx-[2px]'/><span>Loading...</span></div> : "Change Password"}</button>
                              </div>
                          </form>
                      </div>
                  </Transition>
                  {/* Account Information end */}
                  <div className="flex items-center justify-between mb-3">
                      <p className="text-sm font-normal">Email</p>
                      <p className="text-sm font-normal">{userProfile.email}</p>
                  </div>
                  <div className="flex items-center justify-between">
                      <p className="text-sm font-normal">Password</p>
                      <p className="text-sm font-normal">********</p>
                  </div>
                  </section>
                  {/* spacer */}
                  <section className='bg-white p-4 rounded-xl shadow-md w-full md:w-1/2'>
                  <div className="flex items-center justify-between mb-6">
                      <h5 className='text-sm font-extrabold text-[#033050]'>Personal Information</h5>
                      <button className="cursor-pointer" onClick={openUp}>
                      <MdModeEditOutline className='w-4 h-4 text-[#005a98]'/>
                      </button>
                  </div>
                  {/* Profile Information start */}
                  <Transition className="fixed h-screen bg-black/40 inset-x-0 top-0 z-50 mx-auto bg-scroll" show={isShow} enter="transition-opacity duration-75" enterFrom="opacity-0" enterTo="opacity-100" leave="transition-opacity duration-150" leaveFrom="opacity-100" leaveTo="opacity-0">
                      <div className="mx-auto md:w-96 w-[90vw] mt-32 bg-white p-6 rounded-xl shadow-md">
                          <button className="cursor-pointer float-right" onClick={closedUp}>
                          <AiOutlineClose className='w-4 h-4 text-[#005a98]'/>
                          </button>
                          <h5 className="text-xl mt-3 font-medium">Edit Profile Information</h5>
                          <form onSubmit={editProfileInformation}>
                          <div className="mt-6 space-y-3">
                              <section className="gap-y-1 flex flex-col">
                                <label htmlFor="grade" className="text-base text-[#033050] font-medium">Student Name</label>
                                <input type="text" onChange={(e) => setChildName(e.target.value)} value={child_name} style={{ paddingLeft:'1rem' }} className="bg-transparent border-2 border-[#B0D4ED] py-1 focus:outline-none rounded-full" placeholder="Edit student name" />
                              </section>
                              {validationInformation.child_name && (
                                  <div className="alert alert-danger text-red-500">
                                      {validationInformation.child_name[0]}
                                  </div>
                                  )}
                              <section className="gap-y-1 flex flex-col">
                              <label htmlFor="grade" className="text-base text-[#033050] font-medium">Grade</label>
                              <select value={grade_id} onChange={(e) => setGradeId(e.target.value)} className="bg-transparent border-2 py-2 px-4 border-[#B0D4ED] focus:outline-none appearance-none rounded-full" name="grade" id="grade">
                                  {grade.length > 0 && grade.map((item, index) => 
                                      <option key={index} value={item.id}>{item.name}</option>
                                  )}
                              </select>
                              </section>
                              {validationInformation.grade_id && (
                                      <div className="alert alert-danger text-red-500">
                                          {validationInformation.grade_id[0]}
                                      </div>
                                  )}
                              <section className="gap-y-1 flex flex-col">
                              <label htmlFor="date" className="text-base text-[#033050] font-medium">Date of Birth</label>
                              <input value={child_dob} onChange={(e) => setChildDob(e.target.value)} className="bg-transparent mb-2 border-2 py-2 px-4 border-[#B0D4ED] focus:outline-none rounded-full" type="date" name="date" id="date"/>
                              </section>
                              {validationInformation.grade_id && (
                                      <div className="alert alert-danger text-red-500">
                                          {validationInformation.grade_id[0]}
                                      </div>
                                  )}

                                  <section className="gap-y-1 flex flex-col">
                                    <label htmlFor="phone" className="text-base text-[#033050] font-medium">Phone Number</label>
                                    <input type="text" onChange={(e) => setPhone(e.target.value)} value={phone} style={{ paddingLeft:'1rem' }} className="bg-transparent border-2 border-[#B0D4ED] py-1 focus:outline-none rounded-full" placeholder="Edit Phone Number" />
                                </section>
                                {validationInformation.phone && (
                                    <div className="alert alert-danger text-red-500">
                                        {validationInformation.phone[0]}
                                    </div>
                                )}

                                <section className="gap-y-1 flex flex-col">
                                    <label htmlFor="address" className="text-base text-[#033050] font-medium">Address</label>
                                    <input type="text" onChange={(e) => setAddress(e.target.value)} value={address} style={{ paddingLeft:'1rem' }} className="bg-transparent border-2 border-[#B0D4ED] py-1 focus:outline-none rounded-full" placeholder="Edit Address" />
                                </section>
                                {validationInformation.address && (
                                    <div className="alert alert-danger text-red-500">
                                        {validationInformation.address[0]}
                                    </div>
                                )}
                              <button type='submit' className="bg-[#005a98] hover:bg-orange-600 py-2 w-full text-center text-white rounded-full" disabled={isLoadingInformation}> {isLoadingInformation ? <div className='flex flex-row ml-[130px]'><BiLoaderAlt className='w-4 h-4 animate-spin mt-[3px] mx-[2px]'/><span>Loading...</span></div> : "Change Information"}</button>
                          </div>
                          </form>
                      </div>
                  </Transition>
                  {/* Profile Information end */}
                  <div className="flex items-center justify-between mb-3">
                      <p className="text-sm font-normal">Student Name</p>
                      <p className="text-sm font-normal">{userProfile.child_name}</p>
                  </div>
                  <div className="flex items-center justify-between mb-3">
                      <p className="text-sm font-normal">Grade</p>
                      <p className="text-sm font-normal">{userGrades.name}</p>
                  </div>
                  <div className="flex items-center justify-between mb-3">
                      <p className="text-sm font-normal">Date of Birth</p>
                      <p className="text-sm font-normal">{userProfile.child_dob}</p>
                  </div>
                  <div className="flex items-center justify-between mb-3">
                      <p className="text-sm font-normal">Number Phone</p>
                      {
                        userProfile.phone ?(<p className="text-sm font-normal">{userProfile.phone}</p>): (<p className="text-sm font-normal">empty</p>)
                      }
                  </div>
                  <div className="flex items-center justify-between">
                      <p className="text-sm font-normal">Address</p>
                      {
                        userProfile.address ?(<p className="text-sm font-normal">{userProfile.address}</p>): (<p className="text-sm font-normal">empty</p>)
                      }
                      
                  </div>
                  </section>
              </div>
              </section>
          </div>
        </section>
      </LayoutWeb>
    </>
  )
}
