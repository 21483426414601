//import pagination
import Pagination from "react-js-pagination";
import { GrFormPrevious } from "react-icons/gr";
function PaginationComponent(props) {

    return (
        props.total > 0 && (
            <Pagination
                innerClass={`pagination justify-content-${props.position} mt-8 items-center flex flex-row list-style-none text-black gap-x-4 justify-center`}
                activePage={props.currentPage}
                activeClass="page-item active"
                activeLinkClass="page text-lg py-1 px-3 relative bg-[#005a98] rounded-md text-white"
                itemsCountPerPage={props.perPage}
                totalItemsCount={props.total}
                linkClassFirst="hidden"
                itemClass="text-black"
                prevPageText=   {<div className="p-[.1rem] border-2 border-[#085f9b] rounded-lg text-[#085f9b]"><GrFormPrevious/></div>}
                nextPageText= {<img className="w-5 h-5" src="/img/Arrow.png" alt="" />}
                linkClassLast={"hidden"}
                onChange={props.onChange}
            />
        )
    )
}

export default PaginationComponent;