//import cookie
import Cookies from "js-cookie";

//import react router dom
import { Route, Redirect } from "react-router-dom";

function PrivateRoute({ children, ...rest }) {
    
    
    const token = Cookies.get('token');
    const status = Cookies.get('status');
    return (
        <Route {...rest}>
            {
                 token && status === "1" ? children 
                : token && status === "2"  ? <Redirect to="/verify" />  
                : <Redirect to="/"/>
            }
        </Route>
    )
    
  }

export default PrivateRoute;