import React, {useEffect, useState} from 'react'
import CardSubscribe from '../../../components/utilities/CardSubscribe'
import LayoutWeb from '../../../layouts/Web'
import {Link} from 'react-router-dom'
import ModalAuth from '../../../components/utilities/ModalAuth';
function Subscription() {
  const [validation, setValidation] = useState({});
  const [code_auth, setCodeAuth] = useState("1");
  const [show_auth, setShowAuth] = useState(false);

  const functionModalAuth = async(status) =>{
    setShowAuth(status)
}
const functionCodeAuth = async(status) =>{
    setCodeAuth(status)

}

  useEffect(() => {
    window.scrollTo(0, 0);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const onClick = (e) => {
    functionModalAuth(true)
    setCodeAuth("1")
  }

  return (

    <LayoutWeb>
    <ModalAuth functionModalAuth={functionModalAuth} functionCodeAuth={functionCodeAuth} show_auth={show_auth} code_auth={code_auth} />
        <p className="ml-9 container md:ml-0 text-sm mt-28 md:px-[6.5rem] mb-8 w-[50vw] font-semibold text-[#033050]"><Link to="/" className="text-[#033050]" >Home</Link> / Subscription</p>
        <section>
            <CardSubscribe onClick={onClick}/>
        </section>
    </LayoutWeb>
  )
}

export default Subscription
