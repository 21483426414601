import React from 'react';
import { Toaster } from 'react-hot-toast';
import Routes from './routes/routes';

function App() {
  return (
    <>
     <React.Fragment>
       <Toaster/>
        <Routes />
     </React.Fragment>
    {/* <div className='top-[45%] left-[30%] absolute w-[500px]'>
      <img src='/img/Nifty Logo.png' className='mx-auto' height={100} width={100} alt=''/>
      <p className='text-[24px] mt-4 text-center text-[#033050] font-bold'>we are sorry, the platform is under maintenance.</p>
    </div> */}
    </>
  );
}

export default App;