import React, {useEffect} from 'react'
import LayoutWeb from '../../../layouts/Web'
import {Link} from 'react-router-dom'
function About() {
  useEffect(() => {
    window.scrollTo(0, 0);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  return (
    <LayoutWeb>
        <section className='w-full p-8 py-20 bg-[#f9f5f4]'>
            <p className="text-sm px-0 lg:px-10 my-6 text-[#033050] font-semibold">
                <Link to="/" className='text-[#033050]'>Home</Link> / About us
            </p>
            <div className="grid grid-cols-1 md:grid-cols-2 items-center px-0 md:gap-x-6 lg:gap-x-0 sm:px-10 lg:px-20">
                <img className='w-96 lg:mr-auto transform transition duration-500 hover:scale-110' src="/img/AboutUs1.png" alt="" />
                <section className='sm:w-full lg:w-9/12 lg:ml-auto'>
                    <h5 className="text-4xl font-extrabold text-[#033050]">About us</h5>
                    <p className='text-sm font-normal mt-3'>Team of 10+ people from Creative, Education, and Business Development backgrounds who dedicated their time and energy to children’s education. Nifty Educate is an education platform that contains ready-to-use learning materials for preschool, kindergarten, and early elementary children. Stimulation to train various types of children’s intelligence and is designed using a practical approach (learning by doing). <br /> <br /> The pandemic is shaping the future of education. Nifty Educate is trying to reinvent education by combining blockchain technology, specifically NFT as a process of gamification.</p>
                </section>
            </div>
        </section>
        <section className='my-20'>
            <p className='text-center Nunito font-extrabold text-4xl text-[#033050]'>Learn About Our Vision</p>
            <div className="lg:grid grid-cols-1 md:grid-cols-2 items-center mt-20 px-20 flex flex-col-reverse">
                <section>
                    <h5 className='Nunito font-extrabold text-lg text-[#033050]'>Becoming First Choice Learning<br/>Content Partner for Your Kids</h5>
                    <div className='w-10/12 mt-3 space-y-2 text-[#033050]'>
                        <p className='text-sm font-normal'>One of the worst problems that parents face today is kids’ screen time is increasing. Parents choose gadgets as the first tools to accompany their children in daily life. Imagine parents have the time and tools to accompany their kids learning various activities to stimulate a lot of intelligence within their kids. The idea is simply creating bonding within parents and kids. To do that, we have to stimulate both parents and kids to learn together by activating gamification mechanism in the process.</p>
                        <p className='text-sm font-normal'>This idea is validated and becoming reality with the latest technologies and business models. In recent months, we heard Play-to-Earn model using blockchain technology. Combining gaming process to earn learn money through cryptocurrency. Let’s take a look on Mobox, Axie, Infinity, and any other GameFi model. In 2021 alone, Axie daily users grew from 30k to 2.5M and fully diluted token market capitalization increased from $100M to $30bn, with token trading volume rising from $5M to $500M daily.</p>
                    </div>
                </section>
                <img className='ml-auto transform transition duration-500 hover:scale-110' src="/img/Untitled_Artwork 22.png" alt="" />
            </div>
            <div className="lg:grid grid-cols-1 md:grid-cols-2 items-center mt-20 px-20 flex flex-col w-full">
                <img className='mr-auto transform transition duration-500 hover:scale-110' src="/img/Untitled_Artwork.png" alt="" />
                <section>
                    <h5 className='Nunito lg:ml-[5.75rem] font-extrabold text-lg text-[#033050]'>Learn to Earn</h5>
                    <div className='w-10/12 lg:ml-auto mt-3 space-y-2 text-[#033050]'>
                        <p className='text-sm font-normal'>Play-to-earn concept has become the hottest topic in the cryptocurrency sector. There are several concepts introduced into the topics such as the Learn-to-Earn concept. One of the early adopters is Let Me Speak, which created a language learning metaverse in which the users were allowed to learn while creating the journey on the platform and earn tokens to be exchanged into real money. Welcome to Nifty Educate. The first learn-to-earn platform for Kids and Parents in the world.</p>
                    </div>
                </section>
            </div>
        </section>
    </LayoutWeb>
  )
}

export default About
