import React,{useState,useEffect} from 'react';
import LayoutWeb from '../../layouts/Web'
import { useLocation,useHistory } from "react-router-dom";
import Api from "../../api";
import toast from "react-hot-toast";
import Cookies from "js-cookie";

function ConfrimPassword() {
  const [email, setEmail] = useState('')
  const [token, setToken] = useState('')
  const query = new URLSearchParams(useLocation().search);

  const [isLoading, setLoading] = useState(false);
  const [password, setPassword] = useState('');
  const [password_confirmation, setCPassword] = useState('');
  const [validation, setValidation] = useState({});
  const history = useHistory();
  const confrim= async (e) =>{
    e.preventDefault();
    
    const formData = new FormData();

        formData.append('email', email);
        formData.append('token', token);
        formData.append('password', password);
        formData.append('password_confirmation', password_confirmation);
        await Api.post("/reset-password",formData)
        .then((response) => {
          setLoading(false);

          toast.success("Confirmation Password Successfully.", {
                duration: 1000,
                position: "top-right",
                style: {
                    borderRadius: '10px',
                    background: '#333',
                    color: '#fff',
                },
            });
          history.push('/');

        })
        .catch((error) => {
          setLoading(false);  
          setValidation(error.response.data);
        });
  }
  
  const getData = async ()=>{
    setEmail(query.get("email"))
    setToken(query.get("token"))
  }

  useEffect(() => {
    window.scrollTo(0, 0);
    getData()
  },[])


 

  return (
    <LayoutWeb>
      <div className="mx-auto w-96 mt-36 pb-6">
        <h5 className="text-4xl mt-2 font-bold text-center text-[#033050]">Reset Password</h5>
        <p className="text-sm font-normal text-center mt-3 text-[#033050]">Please create your new password</p>
          {validation.message && (
                    <div className="alert alert-danger text-red-500">
                        {validation.message}
                    </div>
                )}
        <form onSubmit={confrim}>
       
        <section className="space-y-2 my-4">
            <label className='text-sm text-[#033050]' htmlFor="email">Email</label>
            <input className="bg-none w-full px-4 border-2 border-[#B0D4ED] py-1 rounded-full focus:outline-none" type="email" name="email" id="email" value={email} disabled placeholder='admin@gmail.com'/>
        </section>
        <section className="space-y-2 mb-4">
            <label className='text-sm text-[#033050]' htmlFor="newpass">New password</label>
            <input className="bg-none w-full px-4 border-2 border-[#B0D4ED] py-1 rounded-full focus:outline-none" type="password" name="newpass" id="text" value={password} onChange={(e) => setPassword(e.target.value)}  placeholder='Enter your new password' />
        </section>
        {validation.password && (
                <div className="alert alert-danger text-red-500 ">
                    {validation.password[0]}
                </div>
            )}
        <section className="space-y-2">
            <label className='text-sm text-[#033050]' htmlFor="repass">Confirm password</label>
            <input className="bg-none w-full px-4 border-2 border-[#B0D4ED] py-1 rounded-full focus:outline-none" type="password" name="repass" id="text" value={password_confirmation} onChange={(e) => setCPassword(e.target.value)} placeholder='re enter your password' />
        </section>
        {validation.password_confirmation && (
              <div className="alert alert-danger text-red-500 ">
                  {validation.password_confirmation[0]}
              </div>
          )}
        <button className="bg-[#005a98] hover:bg-orange-600 py-2 mt-6 w-full text-center font-extrabold text-sm text-white rounded-full" >Submit</button>
        </form>
      </div>
    </LayoutWeb>
  )
}

export default ConfrimPassword