import React, { useState, useEffect } from "react";
import { Swiper, SwiperSlide } from "swiper/react";
import '../../index.css'
import "swiper/css";
import "swiper/css/pagination";
import { Autoplay, Pagination } from "swiper";
import Api from "../../api";

export default function Slider({onClick}) {

    const [sliders, setSliders] = useState([]);
    const [slidersMobile, setSlidersMobile] = useState([]);
    const fetchDataSliders = async () => {
      await Api.get('/sliders')
      .then((response) => {
        setSliders(response.data.data.filter((item) =>	item.is_mobile=== 0));
        setSlidersMobile(response.data.data.filter((item) =>	item.is_mobile=== 1))
      });
      
    }
   useEffect(() => {
        fetchDataSliders();

    }, []);


  return (
    <>
      {/* web */}
      <div className="md:block hidden">
        <Swiper centeredSlides={true} autoplay={{delay: 2500,disableOnInteraction: false,}}
          pagination={{
            clickable: true,
          }}
          navigation={false}
          modules={[Autoplay, Pagination]}
          className="mySwiper"
        >
        {/* web */}
          {sliders.map((item)=>
            <div key={item.id}>
              <SwiperSlide>
                <div className='md:h-full w-full relative overflow-hidden md:mb-16 lg:mb-0'>
                  <button onClick={onClick} className="font-semibold md:right-[42%] md:top-[70%] lg:right-[45%] lg:top-[320px] absolute lg:px-8 lg:py-2 md:px-6 md:py-1 rounded-full bg-[#005A98] text-lg text-white">Join Now!</button>
                  <img className="bg-cover w-full md:h-full lg:h-auto" src={process.env.REACT_APP_IMG+'/storage/sliders/'+item.image} alt="" />
                </div>
              </SwiperSlide>
            </div>
          )}
      
        </Swiper>
      </div>

      {/* mobile */}
      <div className="md:hidden block">
      <Swiper centeredSlides={true} autoplay={{delay: 2500,disableOnInteraction: false,}}
        pagination={{
          clickable: true,
        }}
        navigation={false}
        modules={[Autoplay, Pagination]}
        className="mySwiper"
      >
      {/* web */}
        {slidersMobile.map((item)=>
          <div key={item.id}>
            <SwiperSlide>
              <div className='h-auto relative w-screen overflow-hidden'>
              <button onClick={onClick} className="font-semibold right-[32%] top-[70%] absolute px-10 py-2 rounded-full bg-[#005A98] text-lg text-white">Join Now!</button>
                <img className="bg-gradient-to-t w-full from-white bg-cover" src={process.env.REACT_APP_IMG+'/storage/sliders/'+item.image} alt="" />
              </div>
            </SwiperSlide>
          </div>
        )}
  
      </Swiper>
      </div>

    </>
  );
}