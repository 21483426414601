import React from "react";
import Footer from "../components/particles/Footer";
import Header from "../components/particles/Header";

const LayoutWeb = ({ children }) => {
  return (
  <div className="w-full">
        <Header />
          {children}
        <section className="mt-20">
          <Footer />
        </section>
    </div>
  );
};

export default LayoutWeb;
