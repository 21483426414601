import React, {useState, useEffect} from 'react'
import Api from "../../../api";
import toast from "react-hot-toast";
import ModalAuth from '../../../components/utilities/ModalAuth';
import Slider from '../../../components/utilities/CardSlider';
import Subscribe from '../../../components/utilities/CardSubscribe';
import LeaderBoard from '../../../components/utilities/CardLeaderBoard';
import LayoutWeb from '../../../layouts/Web';
import {Link} from 'react-router-dom'
import ModalVerify from '../../../components/utilities/ModalVerify';
import Cookies from 'js-cookie';
export default function Index() {

  document.title = "Nifty Educate"
  const userStatus = Cookies.get("status")
  const [emailSubscribe,setEmailSubscribe] = useState('');
  const [isLoading, setLoading] = useState(false);
  const [validation, setValidation] = useState({});
  const [code_auth, setCodeAuth] = useState("1");
  const [show_auth, setShowAuth] = useState(false);
  const [hideNotif, setHideNotif] = useState(true);
  const [code_verif, setCodeVerif] = useState(userStatus);
  const [show_verif, setShowVerif] = useState(false);

  const subscribeHandler = async (e) =>{
    e.preventDefault();
    const formData = new FormData();
    formData.append('email', emailSubscribe);
    await Api.post("/newsletter",formData)
      .then((response) => {
        setLoading(false);
        setEmailSubscribe("");
        toast.success("Newsletter added Successfully.", {
              duration: 1000,
              position: "top-right",
              style: {
                  borderRadius: '10px',
                  background: '#333',
                  color: '#fff',
              },
        });

      })
      .catch((error) => {
        setLoading(false);
        toast.error(error.response.data.email, {
          duration: 1000,
          position: "top-right",
          style: {
              borderRadius: '10px',
              background: '#333',
              color: '#fff',
          },
        });
        setValidation(error.response.data);
      });

  } 


const functionModalAuth = async(status) =>{
    setShowAuth(status)
}
const functionCodeAuth = async(status) =>{
    setCodeAuth(status)

}

const functionModalVerif = async(status) =>{
  setShowVerif(status)
}
const functionCodeVerif = async(status) =>{
  setCodeVerif(status)
}


  
  useEffect(() => {
    window.scrollTo(0, 0);
  },[])

  const onClick = (e) => {
    functionModalAuth(true)
    setCodeAuth("1")
  }

  const onClickVerif = (e) => {
    functionModalVerif(true);
  }

  return (
    <>
    <LayoutWeb>
    <ModalAuth functionModalAuth={functionModalAuth} functionCodeAuth={functionCodeAuth} show_auth={show_auth} code_auth={code_auth} />
    <ModalVerify functionModalVerif={functionModalVerif} functionCodeVerif={functionCodeVerif} show_verif={show_verif} code_verif={code_verif}/>
        <section className='mt-20'>                                                                             
          <Slider onClick={onClick}/>
        </section>
        <section className="mx-auto pt-4 md:pt-0 md:my-16 md:mt-12 lg:mt-24">
          <div className="text-center md:-mt-[50px] lg:mt-2                                                                                                                                                                                                    0">
            <h1 className='text-[#033050] mt-10 Nunito font-extrabold text-4xl mb-8'>Our Library</h1>
            <div className='space-y-6 mx-auto'>
                {/* Card 1-2 */}
                <div className="grid md:ml-0 mx-auto grid-cols-1 md:grid-cols-2 gap-y-4 md:gap-y-0 gap-x-0 md:gap-x-4 md:px-24">
                  <div>
                    <div className="p-1 w-72 px-4 md:px-4 mx-auto md:mr-auto md:w-full rounded-xl bg-[#ec452d] flex flex-col sm:w-64 md:flex-col lg:flex-row items-center justify-around">
                        <img src="/img/Worksheets.png" alt="" className="transform transition duration-500 hover:scale-125" />
                        <section className='lg:-translate-x-12 text-center lg:text-left xl:translate-x-0'>
                            <h5 className="text-xl font-bold text-white">Worksheet</h5>
                            <p className="text-xs font-normal w-44 text-white mt-2 leading-snug lg:mr-6 lg:w-40 xl:w-44 lg:break-words">Our worksheet printables are a fun and easy way to learn about multiplication, sight words, animal life cycles, and more!</p>
                            <button className="bg-[#005a98] w-full md:w-auto cursor-pointer mb-4 lg:mb-0 px-6 py-2 rounded-full text-white mt-6 font-semibold md:font-normal text-sm" ><Link style={{color: "white"}} to='/library'>View Lesson </Link></button>
                        </section>
                    </div>
                  </div>
                  <div>
                    <div className="p-1 w-72 px-4 md:px-4 mx-auto md:mr-auto md:w-full rounded-xl bg-[#2e8e57] flex flex-col sm:w-64 md:flex-col lg:flex-row items-center justify-around">
                        <img src="/img/Image-7.png" alt="" className="transform transition duration-500 hover:scale-125" />
                        <section className='lg:-translate-x-12 text-center lg:text-left xl:translate-x-0'>
                            <h5 className="text-xl font-bold text-white">Stories</h5>
                            <p className="text-xs font-normal w-44 text-white mt-2 leading-snug lg:mr-6 lg:w-40 xl:w-44 lg:break-words">Stories are the most impactful tools to promote values. Find hundreds of stories you can read aloud for and with your children.</p>
                            <button className="bg-[#FFBA16] w-full md:w-auto cursor-pointer mb-4 lg:mb-0 px-6 py-2 rounded-full text-white mt-6 font-semibold md:font-normal text-sm"><Link style={{color: "white"}} to='/library'>View Lesson </Link></button>
                        </section>
                    </div>
                  </div>
                </div>
                {/* Card 3-4 */}
                <div className="grid md:ml-0 mx-auto grid-cols-1 md:grid-cols-2 gap-y-4 md:gap-y-0 gap-x-0 md:gap-x-4 md:px-24">
                  <div>
                    <div className="p-1 w-72 px-4 md:px-4 mx-auto md:mr-auto md:w-full rounded-xl bg-[#005A98] flex flex-col sm:w-64 lg:flex-row items-center justify-around">
                        <img src="/img/Image-9.png" alt="" className="transform transition duration-500 hover:scale-125" />
                        <section className='lg:-translate-x-12 text-center lg:text-left xl:translate-x-0'>
                            <h5 className="text-xl font-bold text-white">Activities</h5>
                            <p className="text-xs font-normal w-44 text-white mt-2 leading-snug lg:mr-6 lg:w-40 xl:w-44 lg:break-words">Get hands-on with learning! Our expert-designed activities bring hundreds of topics to life through science experiments, creative art projects, and more.</p>
                            <button className="bg-[#EC452D] w-full md:w-auto cursor-pointer mb-4 lg:mb-0 px-6 py-2 rounded-full text-white mt-6 font-semibold md:font-normal text-sm"><Link style={{color: "white"}} to='/library'>View Lesson </Link></button>
                        </section>
                    </div>
                  </div>
                  <div>
                    <div className="p-1 w-72 px-4 md:h-[425px] lg:h-auto sm:h-auto md:px-4 mx-auto md:mr-auto md:w-full rounded-xl bg-[#FFBA16] flex flex-col sm:w-64 lg:flex-row items-center justify-around">
                        <img src="/img/Image-8.png" alt="" className="transform transition duration-500 hover:scale-125" />
                        <section className='lg:-translate-x-12 text-center lg:text-left xl:translate-x-0'>
                            <h5 className="text-xl font-bold text-white">Lapbook</h5>
                            <p className="text-xs font-normal w-44 text-white mt-2 leading-snug lg:mr-6 lg:w-40 xl:w-44 lg:break-words">Stories are the most impactful tools to promote values. Find hundreds of stories you can read aloud for and with your children.</p>
                            <button className="bg-[#2E8E57] w-full md:w-auto cursor-pointer mb-4 lg:mb-0 px-6 py-2 rounded-full text-white mt-6 font-semibold md:font-normal text-sm"><Link style={{color: "white"}} to='/library'>View Lesson </Link></button>
                        </section>
                    </div>
                  </div>
                </div>
            </div>
          </div>
        </section>
        <section className="md:translate-y-0 container lg:mx-auto mx-0 -translate-y-6 my-28">
          <div className="text-center">
            <h1 className='text-[#033050] mt-10 font-extrabold text-4xl mb-8'>Why Nifty Educate?</h1>
            <section className=" px-20 md:px-0 lg:px-20 items-center space-y-6 lg:space-y-0 grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3">
              <div className="col-start-1 md:col-start-1 md:mt-6 mt-0 lg:mt-0">
                <img className='rounded-full w-40 mx-auto' src="/img/why-1.png" alt="" />
                <section className='text-center mt-4 mx-auto'>
                  <h5 className="text-lg font-extrabold text-[#033050]">Fun Learning</h5>
                  <p className="text-xs mt-2 w-52 mx-auto">Learning materials contain various themes for preschool, kindergarten, and early elementary school children.</p>
                </section>
              </div>
              <div className="col-start-1 md:col-start-2">
                <img className='rounded-full w-40 mx-auto' src="/img/why-2.png" alt="" />
                <section className='text-center mt-4 mx-auto'>
                  <h5 className="text-lg font-extrabold text-[#033050]">Ready to Use</h5>
                  <p className="text-xs mt-2 w-52 mx-auto">Download, print, use. Practical, just use it for the learning process and children's activities.</p>
                </section>
              </div>
              <div className="col-start-1 md:col-start-3">
                <img className='rounded-full w-40 mx-auto' src="/img/why-3.png" alt="" />
                <section className='text-center mt-4 mx-auto'>
                  <h5 className="text-lg font-extrabold text-[#033050]">Learn to Earn</h5>
                  <p className="text-xs mt-2 lg:w-64 mx-auto w-52">Gamification process makes learning more interesting and parents have opportunities to earn real money through cryptocurrency.</p>
                </section>
              </div>
            </section>
            <section className="px-20 md:px-0 lg:px-20 mt-14 items-start space-y-6 lg:space-y-0 grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3">
              <div className="col-start-1 md:col-start-1 md:mt-6 mt-0 lg:mt-0">
                <img className='rounded-full w-40 mx-auto' src="/img/why-6.png" alt="" />
                <section className='text-center mt-4 mx-auto'>
                  <h5 className="text-lg font-extrabold text-[#033050]">Modular</h5>
                  <p className="text-xs mt-2 w-52 mx-auto">The learning process can be done separately, independently, according to the speed of each child.</p>
                </section>
              </div>
              <div className="col-start-1 md:col-start-2">
                <img className='rounded-full w-40 mx-auto' src="/img/why-4.png" alt="" />
                <section className='text-center mt-4 mx-auto'>
                  <h5 className="text-lg font-extrabold text-[#033050]">Cost Efficiency</h5>
                  <p className="text-xs mt-2 w-60 mx-auto">One license is used by one family, the material can be used by brothers and sisters.</p>
                </section>
              </div>
              <div className="col-start-1 md:col-start-3">
                <img className='rounded-full w-40 mx-auto' src="/img/why-5.png" alt="" />
                <section className='text-center mt-4 mx-auto'>
                  <h5 className="text-lg font-extrabold text-[#033050]">Lots of Choices</h5>
                  <p className="text-xs mt-2 w-52 mx-auto">Many choices of lapbooks and learning activities can be chosen according to children's needs.</p>
                </section>
              </div>
            </section>
          </div>
        </section>
        <section className="md:translate-y-0 container mx-auto -translate-y-6 my-28">
          <div className="text-center">
            <h1 className='text-[#033050] mt-10 font-extrabold text-4xl mb-8'>Choose a plan</h1>
            <Subscribe onClick={onClick} onClickVerif={onClickVerif}/>
          </div>
        </section>
        <section className="md:translate-y-0 -translate-y-6 py-16 bg-[#d5f3ff]">
          <div className="text-center">
            <h1 className='text-[#033050] font-extrabold text-4xl mb-8'>Our leaderboard</h1>
            <LeaderBoard/>
          </div>
        </section>
    </LayoutWeb>
</>
  )
}
