import React,{useState, Suspense} from 'react';
import {BsBookmark,BsFillBookmarkFill } from 'react-icons/bs';
import {GiCutDiamond} from 'react-icons/gi';
import ModalAuth from "./ModalAuth";
import { AiOutlineClose } from 'react-icons/ai';
import ModalVerify from './ModalVerify';
import { LazyLoadImage } from 'react-lazy-load-image-component';
import 'react-lazy-load-image-component/src/effects/blur.css';

export default function CardLibrary({id,slug,thumbnail,title,category,grades,mark,token,userStatus,createBookmark,removeBookmark,free_lesson, description}) {
    const [code_auth, setCodeAuth] = useState("1");
    const [show_auth, setShowAuth] = useState(false);
    const [code_verif, setCodeVerif] = useState(userStatus);
    const [show_verif, setShowVerif] = useState(false);

    const functionModalAuth = async(status) =>{
        setShowAuth(status)
    }
    const functionCodeAuth = async(status) =>{
        setCodeAuth(status)
    }

    const functionModalVerif = async(status) =>{
        setShowVerif(status)
    }
    const functionCodeVerif = async(status) =>{
        setCodeVerif(status)
    }

const listCategory = ()=>{
    var txt = "";
    for (var x in category) {
      if (category[x] != null) {
        txt += category[x].category.name + ", ";
      }
    }
    var nCategory = txt.lastIndexOf(", ");
    return txt.substring(0, nCategory);
}

const listGrade = ()=>{

    var txt = "";
    for (var x in grades) {
      if (grades[x] != null) {
        txt += grades[x].grade.name + ", ";
      }
    }
    var nGrades = txt.lastIndexOf(", ");
    return txt.substring(0, nGrades);
}

const onClick = (e) => {
    functionModalAuth(true);
    setCodeAuth("1");
  }

const onClickVerif = (e) => {
    functionModalVerif(true);
}


return (
    <div>
    <ModalAuth functionModalAuth={functionModalAuth} functionCodeAuth={functionCodeAuth} show_auth={show_auth} code_auth={code_auth} />         
    <ModalVerify functionModalVerif={functionModalVerif} functionCodeVerif={functionCodeVerif} show_verif={show_verif} code_verif={code_verif}/>
            <div className="relative show-library bg-white w-72 md:w-auto lg:w-60 xl:w-64 mx-auto md:mr-auto rounded-lg transform duration-200 ease-in hover:drop-shadow-lg shadow-md">
                <a key={id} href={`/library/${slug}`} className="">
                    <div>
                        {thumbnail === null ? 
                            <LazyLoadImage effect='blur' className='rounded-tl-lg w-80 object-cover bg-center lg:h-64 rounded-tr-lg' src='/img/coklat.png' alt='' />
                         : 
                            <LazyLoadImage effect='blur' className='rounded-tl-lg w-80 object-cover bg-center lg:h-64 rounded-tr-lg' src={process.env.REACT_APP_IMG+'/storage/thumbnail/'+thumbnail} alt={title} />
                        }
                        <h5 className="text-lg mt-2 cursor-pointer px-4 font-bold text-[#033050] truncate">{title}</h5>
                    </div>
                </a>
                <div className="p-4">
                    <div className="flex justify-between items-center">      
                        <section className="gap-y-2">
                            <p className="text-sm w-36 font-normal truncate text-black">
                            {listCategory()}
                            </p>
                            <p className="text-sm w-40 font-normal truncate text-black">
                            {listGrade()}
                            </p>
                        </section>
                        {
                            free_lesson === 0?
                            <div className='bg-[#005a90] p-1 rounded-full -mt-1.5 translate-x-4 md:translate-x-0 md:-ml-12 lg:ml-0'> 
                                <GiCutDiamond  className='text-white w-5 h-5'/>
                            </div>
                            : <></>
                        }

                        {token && mark === 0 && code_verif == 1 ? 
                        <div> 
                            <button onClick={()=> createBookmark(id)}>
                                <BsBookmark className='text-[#005a90]' size={24} />
                            </button>
                        </div>
                        : token && mark === 1 && code_verif == 1 ? 
                        <div> 
                            <button onClick={()=> removeBookmark(id)}>
                                <BsFillBookmarkFill className='text-[#005a90]' size={24} />
                            </button>
                        </div> 
                        : !token ? 
                        <div> 
                            <button onClick={onClick}>
                                <BsBookmark className='text-[#005a90]' size={24} />
                            </button>
                        </div>
                        
                        : token && mark === 0 && code_verif == 2 ?
                        <div> 
                        <button onClick={onClickVerif}>
                            <BsBookmark className='text-[#005a90]' size={24} />
                        </button>
                        </div>
                        :
                        <div> 
                        <button onClick={onClickVerif}>
                            <BsBookmark className='text-[#005a90]' size={24} />
                        </button>
                        </div> 
                        }
                    </div>
                </div>
                <div className='hide-library -mt-[7rem] h-40 w-full z-0 bg-white absolute p-4'>
                    <a  href={`/library/${slug}`} className=""><h1 className='text-lg cursor-pointer font-bold text-[#033050] truncate'>{title}</h1></a>
                    <a href={`/library/${slug}`} className="text-ellipsis overflow-hidden text-black" dangerouslySetInnerHTML={{ __html: description.substring(0, 100) }}>
                    </a>
                </div>
               
            </div>
    </div>
  )
}
