import React , { useState,useEffect }from 'react'
import { useHistory } from 'react-router-dom'
import { Transition } from "@headlessui/react";
import { AiOutlineClose } from "react-icons/ai";
import Cookies from "js-cookie";
import Api from "../../api";
import { BiLoaderAlt } from 'react-icons/bi';
import {BsFillEyeSlashFill, BsFillEyeFill} from 'react-icons/bs'
import { Link } from 'react-router-dom';

export default function ModalAuth({ functionModalAuth,functionCodeAuth,show_auth,code_auth}) {

    const history = useHistory();
    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const [isLoading, setLoading] = useState(false);
    const [validation, setValidation] = useState({});

    //register
    const [isLoading_register, setLoadingRegister] = useState(false);
    const [validation_register, setValidationRegister] = useState({});
    const [email_register, setEmailRegister] = useState('');
    const [password_register, setPasswordRegister] = useState('');
    const [password_confirmation_register, setCPasswordRegister] = useState('');
    const [child_name_register, setChildNameRegister] = useState('');
    const [grade_id_register, setGradeIdRegister] = useState('');
    const [child_dob_register, setChildDobRegister] = useState('');
    const [grade_register, setGradeRegister] = useState([]);

    //register
    const [isLoading_forgot, setLoadingForgot] = useState(false);
    const [validation_forgot, setValidationForgot] = useState({});
    const [email_forgot, setEmailForgot] = useState('');

    //password show/hide
    const [passwordType, setPasswordType] = useState("password");
    const togglePassword = (e) => {
		e.preventDefault();
		if (passwordType === "password") {
			setPasswordType("text");
		} else {
			setPasswordType("password");
		}
	};

    const [registerPasswordType, setRegisterPasswordType] = useState("password");
    const togglePasswordRegis = (e) => {
        e.preventDefault();
        if (registerPasswordType === "password"){
            setRegisterPasswordType("text");
        } else {
            setRegisterPasswordType("password");
        }
    }

    const [confirmPasswordType, setConfirmPasswordType] = useState("password");
    const toggleConfirmPassword = (e) => {
        e.preventDefault();
        if (confirmPasswordType === "password"){
            setConfirmPasswordType("text");
        } else {
            setConfirmPasswordType("password");
        }
    }


    const loginHandler = async (e) => {
        e.preventDefault();
        setLoading(true);
        const formData = new FormData();
        formData.append('email', email);
        formData.append('password', password);
        await Api.post("/login",formData)
        .then((response) => {
          setLoading(false);
          Cookies.set("name", response.data.data.name)
          Cookies.set("token", response.data.data.token)
          Cookies.set("status", response.data.status)
          window.location.reload()
        })
        .catch((error) => {
          setLoading(false);  
          setValidation(error.response.data);
        });
      };

      const registerHandler = async (e) => {
        e.preventDefault();
        setLoadingRegister(true);
        const formData = new FormData();
        formData.append('email', email_register);
        formData.append('password', password_register);
        formData.append('password_confirmation', password_confirmation_register);
        formData.append('child_name', child_name_register);
        formData.append('grade_id', grade_id_register);
        formData.append('child_dob', child_dob_register);
    
        await Api.post("/register",formData)
        .then((response) => {
            setLoadingRegister(false);
          Cookies.set("name", response.data.data.name)
          Cookies.set("token", response.data.data.token)
          Cookies.set("status", response.data.status)
          history.push('/verify');
        })
        .catch((error) => {
            setLoadingRegister(false);
            setValidationRegister(error.response.data);
        });
      };

      const forgotHandler = async (e) => {
        e.preventDefault(); 
        setLoadingForgot(true);
        const formData = new FormData();
        formData.append('email', email_forgot);
        await Api.post("/forgot-password",formData)
        .then((response) => {
          functionModalAuth(false)
            setLoadingForgot(false);
            setValidationForgot([]);
        })
        .catch((error) => {
            setLoadingForgot(false);
            setValidationForgot(error.response.data);
        });

      }

      ///kode 1 : login , 2 Register, 3: forgot password
  return (
    <div> 
        <Transition className="fixed bg-black/40 inset-x-0 top-0 z-50 mx-auto bg-scroll" show={show_auth} enter="transition-opacity duration-75" enterFrom="opacity-0" enterTo="opacity-100" leave="transition-opacity duration-150" leaveFrom="opacity-100" leaveTo="opacity-0">
            <div className="scrollbar modalauth py-8 lg:py-24">
                <div className="mx-7 md:mx-auto w-80 md:w-96 bg-white p-6 rounded-xl shadow-md">
                    <button className="cursor-pointer float-right" onClick={() =>functionModalAuth(false) }>
                        <AiOutlineClose className='w-4 h-4 text-[#005a98]'/>
                    </button>
                    {
                        code_auth === "1" ? 
                        (  
                        <>
                            <h5 className="text-4xl mt-6 font-bold text-center text-[#033050]">Login</h5>
                          
                            <form onSubmit={loginHandler}>
                                <div className="space-y-2 mt-8">
                                    <section className="flex flex-col gap-y-2">
                                        <label htmlFor="email" className="text-base font-medium">Email</label>
                                        <input type="text" style={{ paddingLeft:'1rem' }} className="bg-none border-2 border-[#B0D4ED] py-2 focus:outline-none rounded-full" value={email} onChange={(e) => setEmail(e.target.value)} placeholder="Email Address" />
                                        {validation.email && (
                                                    <div className="alert alert-danger text-red-500">
                                                        {validation.email[0]}
                                                    </div>
                                                )}
                                        {validation.message && (
                                            <div className="alert alert-danger text-red-500">
                                                {validation.message}
                                            </div>
                                        )}
                                    </section>
                                    <section className="flex flex-col gap-y-2">
                                        <label htmlFor="password" className="text-base font-medium">Password</label>
                                        <div className='bg-none border-2 border-[#B0D4ED] focus:outline-none rounded-full flex justify-between items-center'>
                                        <input type={passwordType} className="focus:outline-none ml-3"  value={password} onChange={(e) => setPassword(e.target.value)} placeholder="Password" />
                                        <Link
										className="btn rounded-md ml-1 p-3 text-[#005a98]"
										onClick={togglePassword}
                                        >
                                            {passwordType === "password" ? (
                                                <BsFillEyeSlashFill />
                                            ) : (
                                                <BsFillEyeFill />
                                            )}
                                        </Link>
                                        </div>
                                        {validation.password && (
                                            <div className="alert alert-danger text-red-500 ">
                                                {validation.password[0]}
                                            </div>
                                        )}
                                    </section>
                                </div>
                                <div className="flex items-center justify-between mt-4">
                                    <section className="flex items-center gap-2">
                                        <input className="border border-gray-200 mb-0" type="checkbox" name="remember" id="remember" />
                                        <label className="mb-0" htmlFor="remember">Remember me</label>
                                    </section>
                                    <section className="flex items-center gap-2">
                                    <button type="button" className="text-sm font-normal text-[#005A98]"  onClick={() =>functionCodeAuth("3") && setValidation([])}>
                                        Forgot Password
                                    </button>
                                    </section>
                                </div>
                                <button className="mt-6 bg-[#005a98] hover:bg-orange-600 py-2 w-full text-center text-white rounded-full" type="submit" disabled={isLoading}> {isLoading ? <div className='flex flex-row w-full ml-[120px]'><BiLoaderAlt className='w-4 h-4 mt-[2px] mx-[2px] animate-spin'/><span>Loading...</span></div> : "Login"} </button>
                            </form>
                            <div className="flex items-center mt-6 justify-center gap-x-2">
                                    Not registered yet?  <button className="text-sm font-normal text-[#005A98]" onClick={() =>functionCodeAuth("2") &&  setValidation([])}>Create an Account</button>
                            </div>
                        </>
                        ) : code_auth === "2" ? (
                            <>
                            <h5 className="text-4xl mt-6 font-bold text-center text-[#033050]">Register</h5>
                                <form onSubmit={registerHandler}>
                                <section className="space-y-4 mt-3">
                                    <h5 className="text-lg font-extrabold text-[#033050] text-center">Account Information</h5>
                                    <div className="space-y-2 mt-8">
                                        <section className="flex flex-col gap-y-2">
                                            <label htmlFor="email" className="text-base text-[#033050] font-medium">Email</label>
                                            <input type="text" style={{ paddingLeft:'1rem' }} className="bg-none border-2 border-[#B0D4ED] focus:outline-none py-1 rounded-full" value={email_register} onChange={(e) => setEmailRegister(e.target.value)} placeholder="Email Address" />
                                            {validation_register.email && (
                                                <div className="alert alert-danger text-red-500">
                                                    {validation_register.email[0]}
                                                </div>
                                            )}
                                        </section>
                                        <section className="flex flex-col gap-y-2">
                                            <label htmlFor="password" className="text-base text-[#033050] font-medium">Password</label>
                                            <div className='bg-none border-2 border-[#B0D4ED] focus:outline-none rounded-full flex justify-between items-center'>
                                            <input type={registerPasswordType} className="focus:outline-none ml-3"  value={password_register} onChange={(e) => setPasswordRegister(e.target.value)} placeholder="Password" />
                                            <Link
                                            className="btn rounded-md ml-1 p-3 text-[#005a98]"
                                            onClick={togglePasswordRegis}
                                            >
                                                {registerPasswordType === "password" ? (
                                                    <BsFillEyeSlashFill />
                                                ) : (
                                                    <BsFillEyeFill />
                                                )}
                                            </Link>
                                            </div>                                            
                                            {validation_register.password && (
                                                <div className="alert alert-danger text-red-500 ">
                                                    {validation_register.password[0]}
                                                </div>
                                            )}
                                        </section>
                                        <section className="flex flex-col gap-y-2">
                                            <label htmlFor="c_password" className="text-base text-[#033050] font-medium">Confirm password</label>
                                            <div className='bg-none border-2 border-[#B0D4ED] focus:outline-none rounded-full flex justify-between items-center'>
                                            <input type={confirmPasswordType} className="focus:outline-none ml-3"  value={password_confirmation_register} onChange={(e) => setCPasswordRegister(e.target.value)} placeholder="Confirm password" />
                                            <Link
                                            className="btn rounded-md ml-1 p-3 text-[#005a98]"
                                            onClick={toggleConfirmPassword}
                                            >
                                                {confirmPasswordType === "password" ? (
                                                    <BsFillEyeSlashFill />
                                                ) : (
                                                    <BsFillEyeFill />
                                                )}
                                            </Link>
                                            </div>
                                            {validation_register.password_confirmation && (
                                                <div className="alert alert-danger text-red-500 ">
                                                    {validation_register.password_confirmation[0]}
                                                </div>
                                            )}
                                        </section>
                                    </div>
                                </section>
                                <section className="space-y-4 mt-6">
                                        <h5 className="text-lg font-extrabold text-[#033050] text-center">Personal Information</h5>
                                            {validation_register.message && (
                                                <div className="alert alert-danger text-red-500">
                                                    {validation_register.message}
                                                </div>
                                            )}
                                        <div className="space-y-2 mt-8">
                                            <section className="flex flex-col gap-y-2">
                                                <label htmlFor="child_name" className="text-base text-[#033050] font-medium">Student Name</label>
                                                <input type="text" style={{ paddingLeft:'1rem' }} id="child_name" className="bg-none border-2 border-[#B0D4ED] focus:outline-none py-1 rounded-full" value={child_name_register} onChange={(e) => setChildNameRegister(e.target.value)} placeholder="Student Name" />
                                                {validation_register.child_name && (
                                                    <div className="alert alert-danger text-red-500">
                                                        {validation_register.child_name[0]}
                                                    </div>
                                                )}
                                            </section>
                                            <section className="flex flex-col gap-y-2">
                                                <label htmlFor="grade" className="text-base text-[#033050] font-medium">Grade</label>
                                                <select className="bg-transparent border-2 py-2 px-4 border-[#B0D4ED] focus:outline-none text-gray-400 appearance-none rounded-full" value={grade_id_register} name="grade" id="grade" onChange={(e) => setGradeIdRegister(e.target.value)}>
                                                <option selected hidden> select Grade</option>
                                                    <option value="21">3rd Grade</option>
                                                    <option value="20">2nd Grade</option> 
                                                    <option value="19">1st Grade</option>
                                                    <option value="18">Kindergarten</option>
                                                    <option value="17">Preschool</option>
                                                    <option value="16">Parents</option>
                                                </select>
                                                {validation_register.grade_id && (
                                                    <div className="alert alert-danger text-red-500">
                                                        {validation_register.grade_id[0]}
                                                    </div>
                                                )}
                                            </section>
                                            <section className="flex flex-col gap-y-2">
                                                <label htmlFor="date" className="text-base text-[#033050] font-medium">Date of Birth</label>
                                                <input className="bg-transparent border-2 py-1 px-4 border-[#B0D4ED] text-gray-400 focus:outline-none rounded-full" type="date" name="date" id="date" value={child_dob_register} onChange={(e) => setChildDobRegister(e.target.value)}  />
                                                {validation_register.child_dob && (
                                                    <div className="alert alert-danger text-red-500">
                                                        {validation_register.child_dob[0]}
                                                    </div>
                                                )}
                                            </section>
                                        </div>
                                </section>
                                <section className="flex items-start gap-2 my-3">
                                        <input className="border border-[#B0D4ED] mb-0 mt-1" type="checkbox" name="remember" id="remember" />
                                        <label className="mb-0 text-[#033050]" htmlFor="remember">I agree to the <a href='/privacy-policy' target='_blank' className='text-blue-600 decoration-solid underline'>Privacy Policy</a> and <a href='/terms-and-conditions' target='_blank' className='text-blue-600 decoration-solid underline'>Terms of Use</a></label>
                                </section>
                                <button className="mt-4 bg-[#005A98] hover:bg-[#EC452D] transition ease-out duration-100 font-extrabold py-2 w-full text-center text-white rounded-full" type="submit" disabled={isLoading_register}> {isLoading_register ? <div className='flex flex-row w-full ml-[120px]'><BiLoaderAlt className='w-4 h-4 mt-[2px] mx-[2px] animate-spin'/><span>Loading...</span></div> : "Register"} </button>
                                </form>
                            </>
                        ) : (
                            <>
                            <h5 className="text-4xl mt-6 font-bold text-center text-[#033050]">Forgot Password</h5>
                                <form onSubmit={forgotHandler}>
                                    <div className="space-y-4 mt-3">
                                    <h5 className="text-sm font-normal text-[#033050] text-center">Enter your email, we'll send you a link <br/> to create new password</h5>
            
                                        <section className="flex flex-col gap-y-2">
                                            <label htmlFor="email" className="text-base font-medium">Email</label>
                                            <input type="text" style={{ paddingLeft:'1rem' }} className="bg-none border-2 border-[#B0D4ED] py-1 focus:outline-none rounded-full" value={email_forgot} onChange={(e) => setEmailForgot(e.target.value)} placeholder="Email" />
                                            {validation_forgot.email && (
                                                <div className="alert alert-danger text-red-500">
                                                    {validation_forgot.email}
                                                </div>
                                            )}
                                        </section>
                                        
                                    </div>
                                    <button className="mt-6 bg-[#005a98] hover:bg-orange-600 py-2 w-full text-center text-white rounded-full" type="submit" disabled={isLoading_forgot}> {isLoading_forgot ? <div className='flex flex-row w-full ml-[120px]'><BiLoaderAlt className='w-4 h-4 mt-[2px] mx-[2px] animate-spin'/><span>Loading...</span></div> : "Reset Password"} </button>
                                </form>
                            </>
                        )
                    }
                </div>
            </div>
        </Transition>
    </div>
  )
}
