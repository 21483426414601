import React, { useEffect } from "react";
import LayoutWeb from "../../../layouts/Web";
import SideBar from '../../../components/particles/SideBar'
import { Link } from "react-router-dom";
import Api from "../../../api";
import Cookies from "js-cookie";
export default function MyDownloads(){
    const token = Cookies.get('token');
    const getMyDownloads = async () => {
        await Api.get('/history-download', {
            headers: {
                Authorization: `Bearer ${token}`,
              }
        }).then((response)=> {
            // console.log(response.data)
        })
    }
    useEffect(()=> {
        getMyDownloads()
        // eslint-disable-next-line react-hooks/exhaustive-deps
    })
    return(
        <LayoutWeb>
            <section className="max-w-7xl mx-auto">
            <p className="ml-12 md:ml-12 lg:ml-12 text-sm mt-28 mb-8 font-semibold text-[#033050]"><Link to="/" className='text-[#033050]'>Home</Link> / My Downloads</p>
            <div className="px-10 container mx-auto lg:max-w-7xl lg:px-12 flex flex-col md:flex-row md:justify-between">
            <SideBar/>
            </div>
            </section>
        </LayoutWeb>
    )
}