import React, {Suspense} from "react";
import LayoutWeb from "../../../layouts/Web";
import {  useParams,Link } from "react-router-dom";
import Cookies from "js-cookie"
import { useState } from "react";
import { useEffect } from "react";
import Api from "../../../api";
import { toast } from "react-hot-toast";
import { BiLoaderAlt } from "react-icons/bi";

export default function Payment(){
    const [paymentDetail, setPaymentDetail] = useState({})
    const [validation, setValidation] = useState({});
    const [validationPayment, setValidationPayment] = useState({});
    const [code, setCode] = useState("")
    const [total, setTotal] = useState("")
    const [discounted, setDiscounted] = useState(false)
    const [codePayment, setCodePayment] = useState("")
    const [processingPayment, setProcessingPayment] = useState(false)
    const [calculatingDiscount, setCalculatingDiscount] = useState(false)
    const [loadingData, setLoadingData] = useState(true)

    const {id} = useParams()
    const token = Cookies.get("token");
    const numberFormat = (value) =>
    new Intl.NumberFormat("id-ID", {
        style: "decimal",
    }).format(value);

    const getPaymentConfirm = async () => {
        await Api.get(`/coupont/${id}`, {
            headers: {
                //header Bearer + Token
                Authorization: `Bearer ${token}`,
            }
        }).then((response)=>{
            setPaymentDetail(response.data.data.plan)
            setTotal(response.data.data.plan.amount)
        }).finally(()=>{
            setLoadingData(false)
        })
    }
    
    const postPaymentConfirm = async (e) => {
        e.preventDefault();
      
        const formData = new FormData();
        formData.append('code', code)
        formData.append('plan_id', id)
        await Api.post("/coupont", formData,
            {
                headers: {
                  //header Bearer + Token
                  Authorization: `Bearer ${token}`,
                },
              }
   
              
    ).then((response) => {
        setTotal(response.data.data.total)
        setCodePayment(code)
        setCode("")
        setCalculatingDiscount(true);
        setDiscounted(true);
        toast.success("Klaim Code Berhasil", {
            duration: 4000,
            position: "top-right",
            style: {
                borderRadius: '10px',
                background: '#333',
                color: '#fff',
            },
        });

        setValidation('');
        setValidationPayment('');

    }).catch((error) => {  
        setValidation(error.response.data);
        setDiscounted(false)
    });
}




const postFinalPayment = async (e) => {
    e.preventDefault();
    setProcessingPayment(true);
    const formData = new FormData();
    formData.append('code', codePayment)
    formData.append('plan_id', id)
    formData.append('amount', total)
    await Api.post("/payment", formData,
    {
        headers: {
            Authorization: `Bearer ${token}`,
        },
    }
    
).then((response) => {
    window.open(response.data.url);
    setProcessingPayment(false);
 
}).catch((error) => {
    if (error.response  === 500) {
        alert("error 500")
        }  
    setValidationPayment(error.response.data);
    setProcessingPayment(false);
  }).finally(
    setProcessingPayment(false)
  )
}

const proceedToPayment = (e) => {
    postFinalPayment(e);
    setProcessingPayment(true);
}

const submitCoupon = (e)=> {
    postPaymentConfirm(e);
}

    useEffect(()=>{
        getPaymentConfirm()
        window.scrollTo(0, 0);
    }, [])

    return(
    <LayoutWeb>
        <div className="my-28 md:w-[50vw] w-[86vw] mx-auto">
        <img className='w-20 mx-auto' src="/img/Nifty Logo.png" alt="" />
        <div className="flex items-center justify-between my-6">
            <h2 className="text-[#1b3544] font-extrabold" >Selected Plan</h2>
            <Link to="/subscription" className="px-6 py-2 rounded-full bg-[#005a98] text-white font-bold text-sm">Change Plan</Link>
        </div>
        {!loadingData ?
            (
                <div className="p-4 w-full rounded-lg flex items-center bg-white shadow-xl my-3 justify-between">
            <p className="text-[#1b3544] font-extrabold">{paymentDetail.description} Subscription</p>
            <p className="text-[#1b3544] font-bold">IDR {numberFormat(paymentDetail.amount)}</p>
            </div>
            ): 
            (
        <div className="p-4 w-full rounded-lg flex items-center bg-white shadow-xl my-3 justify-between">
            <div className="animate-pulse h-[32px] w-32 bg-gray-300 rounded-lg"></div>
            <div className="animate-pulse h-[32px] w-32 bg-gray-300 rounded-lg"></div>
        </div>
            )
            }
        <div className="flex flex-col my-6">
            <h1 className="text-[#1b3544] font-extrabold mb-6">Coupon</h1>
            <form onSubmit={submitCoupon}>
                <div className="flex flex-row gap-x-4">
                    <input value={code} onChange={(e)=> setCode(e.target.value)} className="focus:outline-none rounded-full h-[34px] w-[300px] p-2 border border-[#005a98]" placeholder='enter your coupon code' type="text"/>
                    <button className="px-6 py-2 rounded-full bg-[#005a98] text-white font-bold text-sm">Submit</button>
                </div>
                    {validation.code && (
                        <p className="alert alert-danger text-red-500"> {validation.code[0]}</p>
                    )} 
                    {validation.message && (
                        <div className="alert alert-danger text-red-500">
                            {validation.message}
                        </div>
                    )}
            </form>
        </div>
        <hr className="my-12 h-[2px] bg-blue-300"/>
        <div>
        {validationPayment.message && (
            <div className="alert alert-danger float-right text-red-500">
                {validationPayment.message}
            </div>
        )}
        <div className="p-4 w-full flex items-center my-3 justify-between">
            <p className="text-[#1b3544] font-extrabold">Total Payment</p>
            {discounted ? 
            (                
            <div hidden={calculatingDiscount} className="flex lg:flex-row gap-2 flex-col mt-[34px] h-32 md:mt-[10px] md:h-8">
                <p className="text-[#EC452D] text-xl font-extrabold">IDR {numberFormat(total)}</p>
                <p className="text-[#1b3544] line-through font-bold">IDR {numberFormat(paymentDetail.amount)}</p>
            </div>
            ): calculatingDiscount ? 
            (
                <div className="animate-pulse h-[32px] w-20 bg-gray-300 rounded-lg"></div>
            ) : total === paymentDetail.amount ?                   
            (
            <div className="flex lg:flex-row gap-2 flex-col mt-[34px] h-32 md:mt-[10px] md:h-8">
                <p style={{visibility: "hidden"}} className="text-[#EC452D] text-xl font-extrabold">IDR {numberFormat(total)}</p>    
                <p className="text-[#1b3544] font-bold">IDR {numberFormat(paymentDetail.amount)}</p>
            </div>
            ) : total === null || calculatingDiscount ?
            ( 
            <div className="animate-pulse h-[32px] w-32 bg-gray-300 rounded-lg"></div>
            ) :
            (<div className="animate-pulse h-[32px] w-32 bg-gray-300 rounded-lg"></div>)
        }
        </div>
            { processingPayment ? (<div className="px-6 py-2 rounded-full w-full bg-[#EC452D] text-white font-bold text-sm flex flex-row items-center"><BiLoaderAlt className="animate-spin ml-[42%] mr-1" />Processing</div>) :
                (<button onClick={proceedToPayment} className="px-6 py-2 rounded-full w-full bg-[#005a98] text-white font-bold text-sm">Process to Payment</button>
            )}
        </div>
        </div>
    </LayoutWeb>
    )
}

