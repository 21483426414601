import  React, {useState,useEffect} from 'react'
import { useLocation,useHistory,Link} from 'react-router-dom';
import { BsPerson, BsSearch } from 'react-icons/bs'
import { Popover } from '@headlessui/react'
import ModalAuth from '../utilities/ModalAuth'
import Cookies from "js-cookie";
import {  Menu, Transition } from '@headlessui/react'
import { IoMdArrowDropdown } from 'react-icons/io'
import { BsBookmark } from 'react-icons/bs'
import Api from '../../api';
import toast from "react-hot-toast";
import { AiOutlineClose } from 'react-icons/ai';
import { BiLogIn, BiMenu, BiSearch } from 'react-icons/bi';

function Header() {
  const location = useLocation();
  const { pathname } = location;
  const splitLocation = pathname.split("/");
  const [show_login, setShowLogin] = useState(false);
  const [show_register, setShowRegister] = useState(false);
  const [show_forgot, setShowForgot] = useState(false);
  const [show_auth, setShowAuth] = useState(false);
  const [code_auth, setCodeAuth] = useState("1");
  const [isOpen, setIsOpen] = useState(false);
  const [hideNotif, setHideNotif] = useState(true);
  const token = Cookies.get("token");
  const history = useHistory();
  // const [query, setQuery] = useState("")
  const [librarySearch, setLibrarySearch] = useState([])
  const [librarySearchMobile, setLibrarySearchMobile] = useState([])
  const [isError,setIsError]= useState(false);
  // const [suggestionsActive, setSuggestionsActive] = useState(false);

  const [focus,setFocus] =useState(false);
  const [focusMobile,setFocusMobile] =useState(false);

  const [countBookmark,setCountBookmark] = useState(0);

  const getCountBookmark = async () =>{
    await Api.get('/bookmarkCount',{
      headers: {
          Authorization: `Bearer ${token}`,
      }
    }).then(response => {
      setCountBookmark(response.data.data)
    })
  }

  const showModalLogin = async(status) =>{
      setShowLogin(status)
  }
  const showModalRegister = async(status) =>{
      setShowRegister(status)
  }

  const showModalForgot = async(status) =>{
      setShowForgot(status)
  }

  const functionModalAuth = async(status) =>{
    setShowAuth(status)
  }

  const functionCodeAuth = async(status) =>{
    setCodeAuth(status)
    
  }

  const logoutHandler = async (e) => {
    await Api.post('/logout', null, {
        headers: {
            Authorization: `Bearer ${token}`,
        }
    })
    .then(() => {
        Cookies.remove('token');
        Cookies.remove('status');
        Cookies.remove('name');
        toast.success("Logout Successfully.", {
            duration: 1000,
            position: "top-right",
            style: {
                borderRadius: '10px',
                background: '#333',
                color: '#fff',
            },
        });
        window.location.reload()
    });
}


//search 
function queryTitle(event){
  Api.get(`/searchbar?title=${event.target.value}`).then((response) => {
    if (event.target.value === "") {
      setLibrarySearch([]);
    } else {
      setLibrarySearch(response.data.data)
    }
    // formatResult(response.data.data);
  
    setIsError(false)
  }).catch((error) => {
    setIsError(true)
  });
}

function onBlur() {
  if (focus) {
    setFocus(false)
  }
}
function onFocus() {
  if (!focus) {
    setFocus(true)
  }
}



//search mobile
function queryTitleMobile(event){
  Api.get(`/searchbar?title=${event.target.value}`).then((response) => {
    if (event.target.value === "") {
      setLibrarySearchMobile([]);
    } else {
      setLibrarySearchMobile(response.data.data)
    }
    // formatResult(response.data.data);
  
    setIsError(false)
  }).catch((error) => {
    setIsError(true)
  });
}

function onBlurMobile() {
  if (focusMobile) {
    setFocusMobile(false)
  }
}
function onFocusMobile() {
  if (!focusMobile) {
    setFocus(true)
  }
}


useEffect(() => {
  if(token){
    getCountBookmark()
  }
},[]);





  return (
    <>
      <ModalAuth functionModalAuth={functionModalAuth} functionCodeAuth={functionCodeAuth} show_auth={show_auth} code_auth={code_auth} />
      <div hidden={hideNotif} className="fixed h-screen bg-black/40 inset-x-0 top-0 z-50 mx-auto ">
          <div className="flex flex-col bg-white drop-shadow-lg lg:w-[32rem] mx-auto w-72 mt-28 p-6 rounded-xl shadow-md">
            <button onClick={()=> setHideNotif(true)} className="rounded-lg ml-auto text-[#005A98] text-center w-4 h-4"><AiOutlineClose/></button>
            <div className="flex flex-col items-center mt-2">
              <h5 className="md:text-2xl text-lg font-bold text-[#005A98]">Do you want to logout?</h5>
              <section className='flex flex-row justify-between w-[80%] mt-6'>
                <button onClick={()=> setHideNotif(true)} className="mt-5 rounded-full bg-[#005A98] hover:shadow-sm text-white text-center px-3 py-2 w-[45%] text-sm font-bold">Cancel</button>
                <button onClick={logoutHandler} className="mt-5 rounded-full bg-orange-500 hover:shadow-sm text-white text-center px-3 py-2 w-[45%] text-sm font-bold">Logout</button>
              </section>
            </div>
        </div>

      </div>
      {/* Navbar Website */}
      <div className="hidden lg:flex items-center w-full sm:gap-x-4 fixed top-0 z-40 px-8 lg:px-16 justify-between pb-20 sm:pb-0 sm:h-20 bg-white drop-shadow-md">
        {/* Logo */}
        <Link to="/" className='block' >
          <img className='w-20' src="/img/Nifty Logo.png" alt="" />
        </Link>
        {/* Link */}
        <section className='flex items-center lg:gap-x-6 lg:justify-between'>
            <Link style={{color: splitLocation[1] === "" ?  "#EC452D": "black"}}  to='/'  className={splitLocation[1] === "" ?  "text-sm text-[#EC452D] Nunito font-extrabold" : "hover:text-[#EC452D] font-extrabold Nunito text-sm"}>Home</Link>
            <Link style={{color: splitLocation[1] === "library" ?  "#EC452D": "black"}} to='/library'  className={splitLocation[1] === "library" ?  "text-sm text-[#EC452D] Nunito font-extrabold" : "hover:text-[#EC452D] font-extrabold Nunito text-sm"} >Library</Link>
            <Link style={{color: splitLocation[1] === "subscription" ?  "#EC452D": "black"}} to='/subscription'  className={splitLocation[1] === "subscription" ?  "text-sm text-[#EC452D] Nunito font-extrabold" : "hover:text-[#EC452D] font-extrabold Nunito text-sm"} >Subscription</Link>
            <Link style={{color: splitLocation[1] === "how-it-work" ?  "#EC452D": "black"}} to='/how-it-work'  className={splitLocation[1] === "how-it-work" ?  "text-sm text-[#EC452D] Nunito font-extrabold" : "hover:text-[#EC452D] font-extrabold Nunito text-sm"} >How it works</Link>
        </section>
        {/* search bar */}
        <section>
          <div className="justify-between px-6 hidden lg:flex w-8/12 md:w-64 lg:w-96 py-2 rounded-full items-center border-[.1em] border-[#005a98]">
            <input type="text" className="bg-transparent w-full lg:w-96 placeholder:text-sm placeholder:text-black focus:border-2 focus:outline-none focus:border-none placeholder:font-medium placeholder:ml-4" placeholder="Search our lesson here"  onChange={queryTitle} 
                      onFocus={onFocus}
                      onBlur={onBlur} />
            <BsSearch className='text-[#005a98] block'/>
          </div> 

          {
            librarySearch !== null ? (
            <div>
                  <ul className={`bg-white drop-shadow-md rounded-lg translate-y-2 ${librarySearch.length == 0 ? `hidden` : ``} absolute w-96 space-y-2 p-2 divide-y`}>
                  {             
                    librarySearch.slice(0, 15).map((item,key)=>{
                      return (
                        <a style={{color: "black"}} className="dataItem" href={`/library/${item.slug}`}>
                          <p>{item.title} </p>
                        </a>
                      );
                    }) 
                    }
                  </ul>
            </div> ) :
            (<></>)
          }
        </section>
        {/* profile */}
        {
          !token ? 
          <div>
            {/* mode web */}
            <section className="gap-x-2 flex items-center">
              <button className="text-sm font-semibold bg-[#005A98] hover:bg-[#EC452D] transition ease-out duration-100 px-5 py-2 rounded-full text-white" onClick={() =>functionModalAuth(true) && setCodeAuth("1")} >
                Login
              </button>
              <button className="text-sm font-normal"  onClick={() =>functionModalAuth(true) && setCodeAuth("2")}>
                  Register
              </button>
            </section>
          </div> :
          <div className="flex px-2 lg:px-3 py-2 border-[1.25px] w-30 lg:w-44 justify-between items-center border-[#005a98] rounded-full">
            <Menu as="div" className="relative inline-block">
              <div className='flex'>
                <p className="text-sm font-extrabold  w-24 truncate text-clip">Hi, {Cookies.get('name')} </p>
                <Menu.Button>
                  <IoMdArrowDropdown className='text-[#005a98]'/>
                </Menu.Button>
              </div>
              <Transition
                enter="transition ease-out duration-100"
                enterFrom="transform opacity-0 scale-95"
                enterTo="transform opacity-100 scale-100"
                leave="transition ease-in duration-75"
                leaveFrom="transform opacity-100 scale-100"
                leaveTo="transform opacity-0 scale-95"
              >
                <Menu.Items className="absolute right-0 w-40 mt-4 origin-top-right bg-white divide-y divide-gray-100 rounded-md shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none">
                  <div className="p-4 flex flex-col gap-y-2">
                    <Menu.Item>
                      <Link style={{color: "black"}} to='/my-account'><p className="text-sm text-[#033050]">My Accounts</p></Link>
                    </Menu.Item>
                    <Menu.Item>
                      <Link style={{color: "black"}} to='/my-subscription'><p className="text-sm text-[#033050]">My Subscription</p></Link>
                    </Menu.Item>
                    <hr className="block" />
                    <Menu.Item>
                        <button onClick={() =>setHideNotif(false)} className="text-sm mr-auto text-red-600 font-bold">Logout</button>
                    </Menu.Item>
                  </div>
                </Menu.Items>
              </Transition>
            </Menu>
            <Link to="/my-bookmark" className="hidden lg:block">
                <div className="flex gap-1 items-center">
                    <BsBookmark className="text-black" />
                    <p className="text-sm text-[#033050]">{countBookmark ? countBookmark:0}</p>
                </div>
            </Link>
          </div> 
        }
      </div>
      {/* Navbar Mobile */}
      <div className="flex lg:hidden items-center w-full sm:gap-x-4 fixed top-0 z-40 px-8 justify-between h-20 bg-white drop-shadow-md">
        {/* popup menu */}
        <div className='flex gap-x-4'>
          <section className="block">
                <Menu as="div" className="relative z-50 inline-block">
                  <section>
                      <button onClick={() => setIsOpen(!isOpen)} type="button" className=' text-[#033050]'>
                        {!isOpen ? (
                          <div><BiMenu className='mb-0 w-6 h-6 text-[#033050]'/></div>
                        ) : null}
                      </button>
                  </section>
                  <Transition
                    show={isOpen}
                    enter="transition ease-linear duration-75"
                    enterFrom="transform opacity-0 scale-100"
                    enterTo="transform opacity-100 scale-100"
                    leave="transition ease-in duration-75"
                    leaveFrom="transform opacity-100 scale-100"
                    leaveTo="transform opacity-0 scale-95"
                  >
                    <Menu.Items className="absolute -translate-x-8 -translate-y-10 w-screen h-screen bg-white rounded-md">
                      <button className='relative ml-8 mt-5' onClick={() => setIsOpen(!isOpen)} type="button">
                        {isOpen ? (
                          <div><AiOutlineClose className='text-[#005A98]'/></div>
                        ) : null}
                      </button>
                        <div className="p-4 flex flex-col gap-y-10 my-14 text-center text-2xl font-extrabold">
                          <Menu.Item className="block lg:hidden text-[#033050]">
                            <Link to='/' style={{color: splitLocation[1] === "" ?  "#EC452D": "black"}}  className={splitLocation[1] === "" ?  "text-xs  Nunito font-extrabold text-[#EC452D]" : " font-extrabold Nunito text-xs"}> Home</Link>
                          </Menu.Item>
                          <Menu.Item className="block lg:hidden text-[#033050]">
                            <Link to='/library' style={{color: splitLocation[1] === "library" ?  "#EC452D": "black"}} className={splitLocation[1] === "library" ? "text-xs  Nunito font-extrabold text-[#EC452D]" : " font-extrabold Nunito text-xs"}>Library</Link>
                          </Menu.Item>
                          <Menu.Item className="block lg:hidden text-[#033050]">
                            <Link to='/subscription' style={{color: splitLocation[1] === "subscription" ?  "#EC452D": "black"}} className={splitLocation[1] === "subscription" ?  "text-xs  Nunito font-extrabold text-[#EC452D]" : " font-extrabold Nunito text-xs"}>Subscription</Link>
                          </Menu.Item>
                          <Menu.Item className="block lg:hidden text-[#033050]">
                            <Link to='/how-it-work' style={{color: splitLocation[1] === "how-it-work" ?  "#EC452D": "black"}} className={splitLocation[1] === "how-it-work" ?  "text-xs  Nunito font-extrabold text-[#EC452D]" : " font-extrabold Nunito text-xs"}>How it works</Link>
                          </Menu.Item>
                        </div>
                    </Menu.Items>

                  </Transition>
                </Menu>
          </section>
        </div>
        {/* Logo */}
        <Link to="/" className='block' >
          <img className='w-20 ml-6' src="/img/Nifty Logo.png" alt="" />
        </Link>
        {/* Right */}
        <div className="flex items-center gap-x-4">
          <Popover className="relative">
            <Popover.Button><BiSearch className='w-6 h-6 text-[#005A98] focus:outline-none focus:border-none'/></Popover.Button>
            <Popover.Panel className="fixed w-full left-0 mt-6">
              <section className="bg-white drop-shadow-md w-full p-2 px-8">
                <div className="py-2 border-2 border-[#005a98] w-full rounded-full">
                  <input type="text" onFocusMobile={onFocusMobile} onBlurMobile={onBlurMobile} onChange={queryTitleMobile}  placeholder='search here' className='ml-6 w-72 mx-auto bg-transparent placeholder:text-md placeholder:text-black focus:border-2 focus:outline-none focus:border-none placeholder:font-medium'/>
                  {
                  librarySearchMobile.length != 0 ? (
                    <div>
                          <ul className='w-5/6 h-[50vh] bg-white drop-shadow-md rounded-lg translate-y-2 fixed inset-x-0 translate-x-10 space-y-2 p-2 divide-y overflow-scroll'>
                          {             
                            librarySearchMobile.slice(0, 15).map((item,key)=>{
                              return (
                                <a style={{color: "black"}} className="dataItem" href={`/library/${item.slug}`}>
                                  <p>{item.title} </p>
                                </a>
                              );
                            }) 
                            }
                          </ul>
                    </div> ) :
                    (<div></div>)
                  }
                </div>
                
              </section>
            </Popover.Panel>
          </Popover>
          {/* endsearch */}

          {/* authentification */}
          <div>
            {
              !token ?
              <section>
                  <button onClick={() =>functionModalAuth(true) && setCodeAuth("1")}>
                      <BiLogIn className='w-6 h-6 text-[#005A98] transition ease-out duration-100 hover:text-[#EC452D]'/>
                  </button>
              </section>
              :
              <div>
                <Menu as="div">
                  <Menu.Button>
                    <BsPerson className='w-6 h-6 text-[#005A98]'/>
                  </Menu.Button>
                  <Transition
                    enter="transition ease-out duration-100"
                    enterFrom="transform opacity-0 scale-95"
                    enterTo="transform opacity-100 scale-100"
                    leave="transition ease-in duration-75"
                    leaveFrom="transform opacity-100 scale-100"
                    leaveTo="transform opacity-0 scale-95"
                  >
                        <Menu.Items className="absolute right-0 w-40 text-[#033050] mt-4 origin-top-right bg-white divide-y divide-gray-100 rounded-md shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none">
                            <div className="p-4 flex flex-col gap-y-2">
                              <Menu.Item>
                              <p className="text-sm font-extrabold  w-24 truncate text-clip">Hi, {Cookies.get('name')} </p>
                              </Menu.Item>
                              <Menu.Item>
                                <Link to='/my-account'><p className="text-sm text-[#033050]">My Accounts</p></Link>
                              </Menu.Item>
                            
                              <Menu.Item>
                                <Link to='/my-subscription'><p className="text-sm text-[#033050]">My Subscription</p></Link>
                              </Menu.Item>
              
                              <Menu.Item className="block lg:hidden">
                                <Link to="/my-bookmark" className="block lg:hidden">
                                    <div className="flex items-center justify-between">
                                        <p className="text-sm">My Bookmarks</p>
                                        <p className="text-xs font-extrabold py-1 px-2 rounded-full bg-[#005a98] text-white">{countBookmark ? countBookmark:0 }</p>
                                    </div>
                                </Link>
                              </Menu.Item>
                              <hr className="block" />
                              <Menu.Item>
                                  <button onClick={()=>setHideNotif(false)} className="text-sm text-red-600 mr-auto">Logout</button>
                              </Menu.Item>
                              </div>
                          </Menu.Items>
                  </Transition>
                </Menu>
                <Link to="/my-bookmark" className="hidden lg:block">
                    <div className="flex gap-1 items-center">
                        <BsBookmark className="text-black" />
                        <p className="text-sm">{countBookmark ? countBookmark:0}</p>
                    </div>
                </Link>
            </div> 
            }
          </div>
        </div>
      </div>
    </>
  )
}

export default Header