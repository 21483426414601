import React, { useState, useEffect } from 'react'
import Api from '../../../api'
import toast from 'react-hot-toast'
import LayoutWeb from '../../../layouts/Web'
import {BiLoaderAlt} from 'react-icons/bi'
import {Link} from 'react-router-dom'
function ContactUs() {

    const [email, setEmail] = useState("")
    const [name, setName] = useState("")
    const [question, setQuestion] = useState("")
    const [loading, setLoading] = useState(false)
    const [invalid, setInvalid] = useState(false)
    const postQuestion = async (e) =>{
        e.preventDefault()
        setLoading(true)
        const formData = new FormData();
        formData.append('email', email);
        formData.append('name', name);
        formData.append('questions', question);
        await Api.post("/contactus", formData)
        .then((response) =>{
            setEmail("")
            setName("")
            setQuestion("")
            setLoading(false)
        toast.success("Your Inquiries have been posted!", {
            duration: 3000,
            position: "top-right",
            style: {
                borderRadius: '10px',
                background: '#333',
                color: '#fff',
            },
        });
        })
        .catch((error) =>{
        setInvalid(true)
        setLoading(false)
        })
        .finally(()=>{
        setLoading(false)
        })
    }

    useEffect(() => {
        window.scrollTo(0, 0);
        // eslint-disable-next-line react-hooks/exhaustive-deps
      }, []);
    return (
    <LayoutWeb>
        <div className="my-28 w-full mx-auto lg:max-w-7xl px-10 lg:px-0">
            <p className="text-sm px-0 lg:px-10 my-6 lg:ml-2 text-[#033050] font-semibold mb-11"><Link to="/" className='text-[#033050]'>Home</Link> / Contact us</p>
            <section className="text-center mb-6">
                <h5 className="text-4xl font-extrabold text-[#033050]">Contact us</h5>
                <p className="text-lg font-extrabold mt-3 text-[#033050]">If you have Link question, you can contact us. We will answer to you shortly.</p>
            </section>
        <form onSubmit={postQuestion}>
            <section className="flex flex-col md:flex-row justify-center gap-y-4 lg:gap-y-0 md:gap-x-10 lg:gap-x-20">
                <div className="flex flex-col gap-y-2">
                    <p className="text-sm font-normal text-[#033050]">Email</p>
                    {invalid && (
                        <p className="alert alert-danger text-red-500">This Field is Required</p>
                    )}
                    <section className="border border-blue-300 py-2 rounded-full w-full lg:w-80">
                        <input value={email} onChange={(e)=> setEmail(e.target.value)} type="email" placeholder='enter your email' className='focus:outline-none ml-4 w-72 bg-transparent'/>
                    </section>
                </div>
                <div className="flex flex-col gap-y-2">
                    <p className="text-sm font-normal text-[#033050]">Name</p>
                    {invalid && (
                        <p className="alert alert-danger text-red-500">This Field is Required</p>
                    )}
                    <section className="border border-blue-300 py-2 rounded-full w-full lg:w-80">
                        <input value={name} onChange={(e)=> setName(e.target.value)} type="text" placeholder='enter your name' className='focus:outline-none ml-4 w-72 bg-transparent'/>
                    </section>
                </div>
            </section>
            <section className="flex flex-col md:flex-row justify-center mt-4">
                <div className="flex flex-col gap-y-2 md:w-9/12 lg:w-[45rem]">
                    <p className="text-sm font-normal text-[#033050]">Questions</p>
                    {invalid && (
                        <p className="alert alert-danger text-red-500">This Field is Required</p>
                    )}
                    <section className="border border-blue-300 rounded-md w-full h-20 px-2">
                        <textarea value={question} onChange={(e)=> setQuestion(e.target.value)} className='border-none w-full my-1 focus:outline-none'></textarea>
                    </section>
                </div>
            </section>
            <section className="text-center mt-6">
                { loading ?
                    <button disabled={loading} type="submit" className='w-60 bg-[#005a98] py-2 rounded-full text-white text-sm font-extrabold Nunito mx-auto'><div className='w-full ml-[70px] flex flex-auto'><BiLoaderAlt className='w-4 h-4 animate-spin'/><span>Loading...</span></div></button>
                    :
                    <button disabled={loading} type="submit" className='w-60 bg-[#005a98] py-2 rounded-full text-white text-sm font-extrabold Nunito'>Submit</button>
                }

            </section>
        </form>
        </div>
    </LayoutWeb>
  )
}

export default ContactUs
