import { textAlign } from '@mui/system'
import React,{useState} from 'react'
import {BsArrowRight} from 'react-icons/bs'

export default function CardBlog(props) {
  const date = new Date(props.created_at)
return (
     <a href={`/blog/${props.slug}`} key={props.id}>
        <div className="w-72 md:w-52 mx-auto lg:w-[360px]">
                {props.images === null ? <img effect='blur' className='rounded-tl-xl w-full h-64 object-top object-cover rounded-tr-xl' src='/img/coklat.png' alt='' /> : <img effect='blur' className='rounded-tl-xl object-center w-full h-64 object-cover rounded-tr-xl' src={process.env.REACT_APP_IMG+'/storage/images/'+props.images} alt={props.title} />}
                <section className='flex flex-col mt-4 gap-y-1 mb-2'>
                    <span className="text-sm Nunito font-normal text-gray-500">{date.toLocaleDateString("ID-id")}</span>
                    <div className="text-lg Nunito cursor-pointer font-bold text-[#033050] truncate">{props.title}</div>
                    <div className="blog-content text-base font-normal text-gray-600 text-justify" dangerouslySetInnerHTML={{ __html: `${props.body.substring(0, 90)}...` }}></div>
                </section>
                <div className="flex items-center gap-x-2 text-sm">
                    <p className='mb-0 Nunito font-medium text-[#033050]'>Continue reading</p>
                    <BsArrowRight className='w-3 h-3 mb-0 mt-[.15rem] text-[#033050]'/>
                </div>
        </div>
    </a>
  )
}
