import React, { useState, useEffect } from "react";
import fileDownload from "js-file-download";
import {AiOutlineClose} from "react-icons/ai"
import { FiDownload,FiLock } from "react-icons/fi";
import { BsBookmark, BsShare,BsFillBookmarkFill } from "react-icons/bs";
import { BiLoaderAlt } from "react-icons/bi"
import {  useParams } from "react-router-dom";
import LayoutWeb from "../../../layouts/Web";
import Api from "../../../api";
import Cookies from "js-cookie";
import {
  SkeletonRecommendation,
  SkeletonDetail,
} from "../../../components/utilities/Skeleton";
import CardLibrary from "../../../components/utilities/CardLibrary";
import toast from "react-hot-toast";
import axios from 'axios'
import NotFound from "../../../pages/errors/404";
import ModalAuth from "../../../components/utilities/ModalAuth";
import { Transition } from "@headlessui/react";
import {GiCutDiamond} from 'react-icons/gi'
import Slider from '../../../components/utilities/slider/Slider'
import {LessonImages} from '../../../assets/index'
import { Swiper, SwiperSlide } from 'swiper/react'
import { Navigation, Thumbs } from 'swiper'
import '../../../components/utilities/slider/Slider.scss'
import 'swiper/css'
import 'swiper/css/navigation'
import 'swiper/css/thumbs'
import 'swiper/css/bundle'
import ModalVerify from "../../../components/utilities/ModalVerify";
import DocumentMeta from "react-document-meta";
import {Link} from 'react-router-dom'
function Show() {

  const userStatus = Cookies.get("status");
  const token = Cookies.get("token");
  const { slug } = useParams();
  const [thumbsSwiper, setThumbSwiper] = useState();
  const [data, setData] = useState({});
  const [category, setCategory] = useState([]);
  const [grade, setGrade] = useState([]);
  const [topic, setTopic] = useState([]);
  const [subject, setSubject] = useState([]);
  const [rec, setRec] = useState([]);
  const [uuid, setUuid] = useState("");
  const [downloading, setDownloading] = useState(false)
  const [hideNotif, setHideNotif] = useState(true)
  const [code_auth, setCodeAuth] = useState("1");
  const [premium, setPremium] = useState(false);
  const [loading, setLoading] = useState(true);
  const [show_auth, setShowAuth] = useState(false);
  const [gallery, setGallery] = useState([]);
  const [thumbnail, setThumbnail] = useState({})
  const [code_verif, setCodeVerif] = useState(userStatus);
  const [show_verif, setShowVerif] = useState(false);

  const functionModalAuth = async(status) =>{
    setShowAuth(status)
  }
  const functionCodeAuth = async(status) =>{
    setCodeAuth(status)

  }

  const functionModalVerif = async(status) =>{
    setShowVerif(status)
  }
  const functionCodeVerif = async(status) =>{
    setCodeVerif(status)
  }

  const getDetailLesson = async () => {
    setLoading(true);
    const url = token ? 'detail-auth' : 'lesson';
    await Api.get(`/${url}/${slug}`, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    }).then((response) => {
      setData(response.data.data);
      setRec(response.data.recommendation);
      setCategory(response.data.data.lessoncategory);
      setTopic(response.data.data.lessontopics);
      setGrade(response.data.data.lessongrades);
      setSubject(response.data.data.lessonsubject);
      setUuid(response.data.data.uuid);
      setPremium(response.data.premium);
      setGallery(response.data.data.lessongallery);
      setThumbnail(response.data.data.thumbnail);

    }).finally(() => {
        setLoading(false);

      });
  };
  const shared = (e) => {
    let url = process.env.REACT_APP_URL + "/library/" + e;
    if (navigator.share) {
      navigator
        .share({
          title: "Share",
          text: "Share",
          url: url,
        })
    }
  };
  const download = async (e,namePdf) => {
    setDownloading(true);
    const formData = new FormData();
    formData.append("uuid", e);

    axios({
      url:process.env.REACT_APP_API+"/download",
      data: formData,
      method:"POST",
      headers: {
        Authorization: `Bearer ${token}`,
      },
      responseType:"blob"
    }).then((res)=>{
      setDownloading(false);
      fileDownload(res.data, `${namePdf}.pdf`);
    }).catch((err)=>{
      setDownloading(false);
      toast.error("Download Error" , {
        duration: 2000,
        position: 'top-right',
        style: {
          borderRadius: "10px",
          background: "#333",
          color: "#fff",
        },
      });
    }).finally(() => {
      setDownloading(false);
    })
  };

  const downloadPdf = async (e,code) => {
    setDownloading(true);
    const url = code === 1 ? 'free-download' : 'download'; 
    await Api.get(`/${url}/${e}`, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    }).then((response) => {
      window.open(response.data.data)
    }).finally(() => {
      setDownloading(false);

    });

   
  };
  const createBookmark = async (e) => {
    setLoading(true);
    await Api.post(
      "/bookmark",
      {
        lesson_id: e,
      },
      {
        headers: {
          //header Bearer + Token
          Authorization: `Bearer ${token}`,
        },
      }
    ).then((response) => {
      setLoading(false);
      toast.success("Save Bookmark Successfully.", {
        duration: 2000,
        position: "top-right",
        style: {
          borderRadius: "10px",
          background: "#333",
          color: "#fff",
        },
      });
      getDetailLesson();
    });
  };

  const removeBookmark = async (e) => {
    await Api.post(
      "/remove-bookmark",
      {
        lesson_id: e,
      },
      {
        headers: {
          //header Bearer + Token
          Authorization: `Bearer ${token}`,
        },
      }
    ).then((response) => {
      setLoading(false);
      toast.success("Remove Bookmark Successfully.", {
        duration: 2000,
        position: "top-right",
        style: {
          borderRadius: "10px",
          background: "#333",
          color: "#fff",
        },
      });
      getDetailLesson();
    });
  };

  function isEmpty(obj) {
    for (const property in obj) {
      return false;
    }
    return true;
  }

const onClick = (e) => {
  functionModalAuth(true)
  setCodeAuth("1")
}

const onClickVerif = (e) => {
  functionModalVerif(true);
}

  // rendering
  useEffect(() => {
    getDetailLesson();
  }, []);
  
  const meta = {
    title: `Nifty Educate - ${data.meta_title}`,
    description: data.meta_description,
    meta: {
      charset: 'utf-8',
      name: {
        keywords: data.meta_keywords
      }
    }
  };

  return (
    // <DocumentMeta {...meta}>
    <LayoutWeb>
      <ModalAuth functionModalAuth={functionModalAuth} functionCodeAuth={functionCodeAuth} show_auth={show_auth} code_auth={code_auth} />
      <ModalVerify functionModalVerif={functionModalVerif} functionCodeVerif={functionCodeVerif} show_verif={show_verif} code_verif={code_verif}/>
      <div hidden={hideNotif} className="fixed h-screen bg-black/40 inset-x-0 top-0 z-50 mx-auto ">
          <div className="flex flex-col bg-white drop-shadow-lg lg:w-[32rem] mx-auto w-72 mt-28 p-6 rounded-xl shadow-md">
            <button onClick={()=> setHideNotif(true)} className="rounded-lg ml-auto text-black text-center w-4 h-4"><AiOutlineClose/></button>
            <div className="flex flex-col lg:flex-row items-center">
              <img className="mx-auto w-60" src="/img/premium_asset.png" alt="" />
              <section>
                <h5 className="text-lg font-semibold">Unlock Lesson</h5>
                {
              !premium && token ?
              (
              <>
                <p className="mt-1 text-sm">You need a premium membership in order to download premium lessons.</p>
                <Link to='/subscription' className="float-right mt-5 rounded-full bg-[#005A98] hover:bg-orange-500 transform transition hover:shadow-orange-500 hover:shadow-sm text-white text-center px-3 py-2 w-full text-sm font-bold">Upgrade</Link>
              </>
              )
              : <></>}
              </section>
            </div>
        </div>

      </div>
      <div className="xl:mx-auto md:w-full">
        <div className="px-8 sm:px-10 md:px-20 my-28">
          {loading ?(<SkeletonDetail/>):
          !isEmpty(data) ?
          (
            <>
              <p className="text-sm font-semibold mb-10 text-[#033050]">
                <Link style={{color: "black"}} to="/">Home</Link> / <Link style={{color: "black"}} to="/library">Library</Link> / {data.title}
              </p>
              <div className="grid grid-cols-1 lg:grid-cols-3 gap-y-8 relative">
                <section className="w-auto">
                  { gallery.length > 0 ?
                  <>
                    <Swiper
                        loop={true}
                        spaceBetween={10}
                        navigation={true}
                        modules={[Navigation, Thumbs]}
                        grabCursor={true}
                        thumbs={{ swiper: thumbsSwiper && !thumbsSwiper.destroyed ? thumbsSwiper : null }}
                        className='product-images-slider'
                    >
              {
                  gallery.map((pic) => (
                      <SwiperSlide key={pic.id}>
                              <img src={`${process.env.REACT_APP_IMG}/storage/gallery/${pic.images_gallery}`} alt="product images" />
                      </SwiperSlide>
                  ))
              }
                    </Swiper>
          <Swiper
              onSwiper={setThumbSwiper}
              loop={true}
              spaceBetween={10}
              slidesPerView={gallery.length}
              modules={[Navigation, Thumbs]}
              className='product-images-slider-thumbs'
          >
              {
                  gallery.map((pic) => (
                      <SwiperSlide key={pic.id}>
                          <div className="product-images-slider-thumbs-wrapper">
                              <img src={`${process.env.REACT_APP_IMG}/storage/gallery/${pic.images_gallery}`} alt="product images" />
                          </div>
                      </SwiperSlide>
                  ))
              }
          </Swiper></>
        : <img src={`${process.env.REACT_APP_IMG}/storage/thumbnail/${thumbnail}`} />}
                </section>
                <section className="w-full lg:ml-8">
                  <h5 className="text-4xl text-[#033050] font-extrabold mb-3">
                    {data.title}
                  </h5>
                  <div className="text-sm font-normal" dangerouslySetInnerHTML={{ __html: data.description }}>
                  </div>

                  <div className="flex flex-col items-start space-y-4 mt-6">
                    {/* spacer */}
                    <div className="flex flex-col">
                      <p className="text-sm font-normal mb-2">Category</p>
                      <div className="flex flex-row gap-x-2">
                        {category.map((item)=>
                            <div className="px-4 py-2 bg-[#F8F4F4] rounded-full" key={item.category.id}>
                              <p className="text-sm font-extrabold text-[#033050]">{item.category.name}</p>
                            </div>
                        )}
                      </div>
                    </div>
                    {/* spacer */}
                    <div className="flex flex-col">
                      <p className="text-sm font-normal mb-2">Grade</p>
                      <div className="flex flex-row gap-x-2">
                        {grade.map((item)=>
                            <div className="px-4 py-2 bg-[#F8F4F4] rounded-full" key={item.grade.id}>
                              <p className="text-sm font-extrabold text-[#033050]">{item.grade.name}</p>
                            </div>
                        )}
                      </div>
                    </div>
                    {/* spacer */}
                    <div className="flex flex-col">
                      <p className="text-sm font-normal mb-2">Subject</p>
                      <div className="flex flex-row gap-x-2">
                        {subject.map((item)=>
                            <div className="px-4 py-2 bg-[#F8F4F4] rounded-full" key={item.subject.id}>
                              <p className="text-sm font-extrabold text-[#033050]">{item.subject.name}</p>
                            </div>
                        )}
                      </div>
                    </div>
                      {/* spacer */}
                      <div className="flex flex-col">
                      <p className="text-sm font-normal mb-2">Topic</p>
                      <div className="flex flex-row gap-x-2">
                        {topic.map((item)=>
                            <div className="px-4 py-2 bg-[#F8F4F4] rounded-full" key={item.topic.id}>
                              <p className="text-sm font-extrabold text-[#033050]">{item.topic.name}</p>
                            </div>
                        )}
                      </div>
                    </div>
                  </div>
                </section>
                <section className="md:w-full lg:w-64 lg:ml-auto">
                      <div className="p-4 rounded-xl shadow-md flex flex-col space-y-4 md:w-auto">
                 
                      {

                          token ?
                          <>
                            {
                              data.free_lesson  === 0 && premium ?  (
                                <button onClick={(e) => downloadPdf(data.files,2)} className="w-full lg:w-56 px-4 py-3 rounded-xl bg-[#005A98] hover:bg-[#EC452D] transition ease-out duration-100 text-white text-sm font-medium flex gap-x-2 text-left items-center">
                                   {
                                    downloading ?
                                      <><BiLoaderAlt className="w-4 h-4 animate-spin" /><span className="text-sm font-semibold"> Downloading...</span></>:
                                      <><FiDownload className="w-4 h-4" /><span className="text-sm font-semibold">Download Lesson</span></>
                                    }
                                  </button>
                                ) :
                              data.free_lesson === 0 && !premium ? (
                                <button
                                  onClick={() => setHideNotif(false)}
                                  className="w-full lg:w-56 px-4 py-3 rounded-xl bg-[#005A98] hover:bg-[#EC452D] transition ease-out duration-100 text-white text-sm font-medium flex gap-x-2 text-left items-center">
                                  <GiCutDiamond className="w-4 h-4" />
                                  <span className="text-sm font-semibold">
                                      Premium Lesson
                                  </span>
                                </button>
                              ):
                              code_verif == 2 ? (
                                <button
                                  onClick={onClickVerif}
                                  className="w-full lg:w-56 px-4 py-3 rounded-xl bg-[#005A98] hover:bg-[#EC452D] transition ease-out duration-100 text-white text-sm font-medium flex gap-x-2 text-left items-center">
                              {
                                data.free_lesson === 1 ?
                                <><FiDownload className="w-4 h-4" /><span className="text-sm font-semibold">Download Lesson free</span></>:
                                <><GiCutDiamond className="w-4 h-4" /><span className="text-sm font-semibold">Premium Lesson</span></>
                              }
                                </button>
                              ) :
                               (
                                  <button onClick={(e) => downloadPdf(data.files,1)}
                                    className="w-full lg:w-56 px-4 py-3 rounded-xl bg-[#005A98] hover:bg-[#EC452D] transition ease-out duration-100 text-white text-sm font-medium flex gap-x-2 text-left items-center">
                                    {
                                      downloading ?
                                      <><BiLoaderAlt className="w-4 h-4 animate-spin" /><span className="text-sm font-semibold"> Downloading...</span></>:
                                      <><FiDownload className="w-4 h-4" /><span className="text-sm font-semibold">Download Lesson Free</span></>
                                    }
                                  </button>
                                )
                            }
                          </>:
                          <>
                            <button
                              onClick={onClick}
                              className="w-full lg:w-56 px-4 py-3 rounded-xl bg-[#005A98] hover:bg-[#EC452D] transition ease-out duration-100 text-white text-sm font-medium flex gap-x-2 text-left items-center" >
                              {
                                data.free_lesson === 1 ?
                                <><FiDownload className="w-4 h-4" /><span className="text-sm font-semibold">Download Lesson</span></>:
                                <><GiCutDiamond className="w-4 h-4" /><span className="text-sm font-semibold">Premium Lesson</span></>
                              }

                            </button>

                          </>
                        }
                        {token ?
                          code_verif == 2 ?
                          <button onClick={onClickVerif} className="w-full lg:w-56 px-4 py-3 rounded-xl border-2 border-[#033050] text-black items-center text-sm font-medium flex gap-x-2 text-left">
                            <BsBookmark className="w-4 h-4" />
                            <span className="text-sm font-semibold">
                              Bookmark Lesson
                            </span>
                          </button> :
                           data.mark == 1 ?
                          <button onClick={()=> removeBookmark(data.id)} className="w-full lg:w-56 px-4 py-3 rounded-xl border-2 border-[#033050] text-black items-center text-sm font-medium flex gap-x-2 text-left">
                              <BsFillBookmarkFill className="w-4 h-4 text-[#005a90]" />
                              <span className="text-sm font-semibold">
                                Bookmark Lesson
                              </span>
                            </button> :
                            <button onClick={()=> createBookmark(data.id)} className="w-full lg:w-56 px-4 py-3 rounded-xl border-2 border-[#033050] text-black items-center text-sm font-medium flex gap-x-2 text-left">
                              <BsBookmark className="w-4 h-4" />
                              <span className="text-sm font-semibold">
                                Bookmark Lesson
                              </span>
                            </button>  
                          : 
                          (
                            <button
                              onClick={onClick}
                              className="w-full lg:w-56 px-4 py-3 rounded-xl border-2 border-[#033050] text-black items-center text-sm font-medium flex gap-x-2 text-left"
                            >
                              <BsBookmark className="w-4 h-4" />
                              <span className="text-sm font-semibold">
                                Bookmark Lesson
                              </span>
                            </button>
                          )}
                          <button
                            onClick={() => shared(data.slug)}
                            className="w-full lg:w-56 px-4 py-3 rounded-xl border-2 border-[#033050] text-black items-center text-sm font-medium flex gap-x-2 text-left"
                          >
                            <BsShare className="w-4 h-4" />
                            <span className="text-sm font-semibold">Share</span>
                          </button>
                    </div>
                </section>
              </div>
            </>
          ) : <><NotFound/></>

          }
        </div>

          {loading ? (
            <div className="bg-[#f8f4f4] rounded-xl mt-12 pb-10">
            <SkeletonRecommendation />
            </div>
          ) :
            !isEmpty(data) ?
          (
          <div className="bg-[#f8f4f4] rounded-xl mt-12 pb-10 w-full mx-auto">
            <h5 className="md:text-4xl text-2xl font-extrabold my-4 pt-11 text-[#033050] text-center">
              Recommended for you
            </h5>
            <div className="grid grid-cols-1 md:px-14 px-10 sm:gap-x-8 sm:-ml-4 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-4 gap-y-6 mt-10 lg:gap-y-0">
              {rec.map((item) => (
                <CardLibrary
                  id={item.id}
                  slug={item.slug}
                  thumbnail={item.thumbnail}
                  title={item.title}
                  category={item.lessoncategory}
                  grades={item.lessongrades}
                  mark={item.mark}
                  token={token}
                  createBookmark={createBookmark}
                  removeBookmark={removeBookmark}
                  free_lesson={item.free_lesson}
                  description={item.description}
                  userStatus={userStatus}
                />
              ))}
            </div>
          </div>
          ):<></>}
        </div>

    </LayoutWeb>
    // </DocumentMeta>
  );
}

export default Show;
