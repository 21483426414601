import React, {useEffect} from 'react'
import {Link} from 'react-router-dom'

function NotFound() {

  useEffect(() => {
    window.scrollTo(0, 0);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  return (

        <div>
            <div className="flex lg:h-80 mt-20 lg:mt-40 flex-col-reverse lg:flex-row items-center justify-around px-10 lg:px-20">
                <section className='hidden lg:block'>
                    <h5 className="text-4xl font-medium">Ooopps,<br />Something is missing.</h5>
                    <p className="text-sm font-normal mt-4 mb-6">Sorry the page you are looking for doesn't exist or<br />has been removed. We suggest you back to home.</p>
                    <Link to='/' className='px-4 py-2 text-sm w-[7.5rem] rounded-full bg-[#005A98] hover:bg-[#EC452D] text-white'>Back to home</Link>
                </section>
                <section className='block lg:hidden text-center'>
                    <h5 className="text-3xl font-medium">Ooopps,<br />Something is missing.</h5>
                    <p className="text-sm font-normal mt-2 mb-6">Sorry the page you are looking for doesn't exist or<br />has been removed. We suggest you back to home.</p>
                    <Link to='/' className='px-4 py-2 text-sm w-[7.5rem] rounded-full bg-[#005A98] hover:bg-[#EC452D] text-white'>Back to home</Link>
                </section>
                <section>
                    <img className='w-[35rem] lg:w-96' src="/img/error.png" alt="" />
                </section>
            </div>
        </div>
  )
}

export default NotFound
